import $ from "jquery"
import "slick-carousel"
import "jquery-waypoints/waypoints.js"

(function($, t) {

    t.controllers.define(
        'reviews',
        {
        },
        {
            'init:after': function () {
                this.scrollbox = this.$el.find('.reviews');
                this.scrollbox.css("margin-bottom", "-17px");
                this.full_width = this.scrollbox.innerWidth();
                this.$el.removeClass("nojs");
                this.$el.find(".reviews").slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    nextArrow: this.$el.find(".button.next"),
                    prevArrow: this.$el.find(".button.previous"),
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 850,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                }).on({
                    'setPosition': t.util.bind(this.resize, this),
                    'mousedown': function() {
                        $(this).addClass("grabbing");
                    },
                    'mouseup': function () {
                        $(this).removeClass("grabbing");
                    }
                });
                this.resize();
            },
            'resize': function () {
                this.$el.find('.slick-dots').insertAfter('.reviews-wrapper > h2');
            }
        }
    );

    t.controllers.define(
        'question',
        {
            'click .name-submit': 'onSubmitClick',
        },
        {
            'init:after': function () {
                this.two_part_cta = this.$el.find("form").hasClass("two_part_cta");
            },
            'onSubmitClick': function (e) {
                if(!this.$el.find("input").val()){
                    e.preventDefault();
                    return;
                }
                if(this.two_part_cta) {
                    e.preventDefault();
                    this.$el.find(".pets-name").hide();
                    this.$el.find(".get-started").show();
                    this.$el.find(".description").text("Now, start "+ this.$el.find("input").val() +"'s profile")
                }
            }
        }
    );

    t.controllers.define(
        'statements',
        {
        },
        {
            'init:after': function () {
                this.$el.find(".statement").waypoint({
                    handler: function (direction) {
                        $(this).find(".icon").toggleClass("enlarge", direction == "down");
                    },
                    offset: "bottom-in-view"
                });
            }
        }
    );

    t.controllers.define(
        'wet-faqs',
        {
            'click h3': 'onFaqClick'
        },
        {
            onFaqClick: function(e) {
                e.preventDefault();
                $(e.target).closest('li').toggleClass('open');
            }
        }
    );

    t.controllers.define(
        'logos',
        {
        },
        {
            'init:after': function(e) {
                this.$el.find('.logos-array').slick({
                    variableWidth: true,
                    slidesToShow: 4,
                    responsive: [
                        {
                          breakpoint: 1100,
                          settings: {
                            slidesToShow: 3
                          }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1
                          }
                        }
                    ]
                });
            }
        }
    );

})($, window.tails);
