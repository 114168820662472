import $ from "jquery"

(function ($, t) {

    t.controllers.define(
        'steps',
        {
            'click button[type=submit]': 'onSubmitClick',
            'click .js-step-back': 'onBackClick',
            'submit form': 'onSubmit'
        },
        {
            "init:after": function () {
                var that = this;
                that.progress = that.$el.find(".progress-wrapper");
                that.progress_bar = that.progress.find('.bar');
                that.progress_value = that.progress.find('.value');
                that.progress_step_image = that.$el.find(".progress-image .step-image");
                that.steps = that.$el.find(".steps");
                that.pending = false;
                $('.btn-signup').remove();
                window.onpopstate = function (event) {
                    var state = event.state;
                    if (event.state) {
                        // history changed because of pushState/replaceState
                        that.$el.find('.step.out').remove();
                        that.$el.find('.step.in').removeClass("in");
                        that.loadStep(state.type, state.url, state.data, false, false);
                    } else {
                        // history changed because of a page load
                    }
                };
            },
            updateDataLayer: function () {
                window.dataLayer.push({
                    'event': 'step-loaded',
                    'url': window.location.href
                });
            },
            loadStep: function (type, url, data, back, record) {
                var that = this;
                that.pending = true;

                if (back) {
                    this.$el.attr("data-state", "back");
                } else {
                    this.$el.attr("data-state", "submitting");
                }
                var args = {
                    type: type,
                    url: url,
                    success: function (d, status, xhr) {
                        // If this is a redirect just redirect
                        if (d.redirect) {
                            window.location.href = d.redirect;
                            return;
                        }
                        // Parse the html returned by the call, if we find <html> bail out
                        var html = $.parseHTML(d.html);
                        // TODO -> Handle this case better
                        if ($(html).find("html").length) {
                            window.location.href = "/";
                            return;
                        }

                        // Append the new step html to the .steps container
                        that.steps.append(html);

                        // If we are navigating backwards toggle that class so it animates in the right direction
                        that.steps.toggleClass("back", back);

                        // Find the old step
                        var old_step = that.$el.find('.step:first-child');
                        var old_step_name = old_step.find(".step-name").val();

                        // Find the new step
                        var new_step = that.$el.find('.step:last-child');
                        that.current_step = new_step;

                        $("body").attr("data-flow", new_step.attr("data-flow"));

                        var stepIndex = new_step.find("input[name=step]").val();
                        var step_name = new_step.find(".step-name").val() || "";

                        // Remove the JS handlers from the old step and init them on the new step
                        t.controllers.purge(old_step);
                        t.controllers.init(new_step);

                        // If we are loading the same step as before (think errors) remove the old step
                        // so we don't have two of the same form
                        if (step_name === old_step_name) {
                            old_step.remove();
                            that.pending = false;
                            that.$el.attr("data-state", "init");
                            that.scrollToError();
                            return
                        }

                        $("html, body").scrollTop(0);

                        // Add the animation classes
                        old_step.addClass("out");
                        new_step.addClass("in");

                        // Update the elements that are outside the step element, like the back and next button
                        var url = new_step.find(".step-url").val();
                        var path = new_step.find(".step-path").val();

                        // Trigger a page view event in google analytics, and fire any other events
                        window.dataLayer.push({
                            event: "ga_pageview",
                            path: path
                        });

                        if (window.consenTag) {
                            window.consenTag.trigger({
                                event: "nextstep"
                            });
                        }

                        if (d['events']) {
                            window.heap = window.heap || [];
                            window.heap.track = window.heap.track || ((...args) => {
                                window.heap.push(["track", ...args])
                            });
                            $.each(d['events'], t.util.bind(function (i, event) {
                                window.dataLayer.push({
                                    event: 'ga',
                                    eventCategory: event['category'],
                                    eventAction: event['action'],
                                    eventLabel: event['label'],
                                    nonInteraction: true
                                });
                                window.heap.track(
                                    event['action'],
                                    {
                                        category: event['category'],
                                        label: event['label']
                                    }
                                );
                            }, this));
                        }

                        that.$el.attr("data-step", step_name);

                        // Update page title
                        var title = d.page_title + " | tails.com";
                        $("head title").text(title);

                        // Add a page history event
                        if (window.history.pushState) {
                            if (record) {
                                window.history.pushState({
                                    type: "GET",
                                    title: title,
                                    url: url
                                }, title, url);
                            } else {
                                window.history.replaceState({
                                    type: "GET",
                                    title: title,
                                    url: url
                                }, title, url);
                            }
                        }

                        // There should only ever be 2 .step elements. However
                        // if you flick back and forwards quickly then things
                        // get messed up and the .step elements start to stack
                        // up.
                        //
                        // So let's remove the ones we know shouldn't be
                        // around...
                        $('.steps .step:not(:first-child, :last-child)')
                            .remove();

                        // Because step content is loaded in by Ajax and then
                        // processed, it's possible to end up with things
                        // getting a little out of sync and classes not
                        // being added correctly. Let's resolve that.
                        var the_first = $(
                            '.steps .step:first-child:not(:last-child, .out)'
                        );
                        var the_last = $(
                            '.steps .step:last-child:not(:first-child, .in)'
                        );
                        the_first.addClass('out');
                        the_last.addClass('in');

                        var mainStepCount = $('#progress-wrapper').data('main-step-count');
                        var percentage = Math.round(stepIndex / mainStepCount * 100) || 0;
                        that.updateProgress(percentage);

                        // Remove pending status
                        that.pending = false;
                        that.$el.attr("data-state", "init");

                        // DataLayer for Google Tag Manager tracking on successful LoadStep AJAX call //
                        that.updateDataLayer();
                    },
                    error: function () {
                        that.pending = false;
                        that.$el.attr("data-state", "error");
                    }
                };
                if (data) {
                    args['data'] = data;
                }
                $.ajax(args);

            },
            scrollToError: function () {
                var error_field = this.$el.find(".error");
                if (error_field.length > 0) {
                    $('html, body').animate({
                        scrollTop: error_field.offset().top - 250
                    }, 'slow');
                }
            },
            updateProgress: function (percent) {
                this.progress.toggleClass("show", percent !== 0);
                this.progress_bar.css("width", percent + "%");
                this.progress_value.text(percent + "%");
                this.progress_step_image.css("margin-left", "max(calc(" + percent + "% - 40px), 0px)");
            },
            onSubmit: function (e) {
            },
            onSubmitClick: function (e) {
                var button = $(e.currentTarget);

                if (this.$el.find(".pet-processing").length && button.hasClass("js-step-next")) {
                    if (this.$el.find(".step:not(.out) .nojax").length && button.hasClass("js-step-next")) {
                        t.message.publish('next', 'clicked');
                    }
                    this.$el.find(".pet-processing").appendTo($("body")).addClass("show");
                    return;
                }

                if (this.$el.find(".step:not(.out) .nojax").length && button.hasClass("js-step-next")) {
                    t.message.publish('next', 'clicked');
                    return;
                }

                e.preventDefault();
                e.stopPropagation();
                if (this.pending) {
                    return;
                }

                // On the dog activity step, ensure the customer clicks the HMRC
                // checkbox if their dog is a working dog
                if (this.$el.find("#working-1").length) {
                    if (this.$el.find("#working-1")[0].checked) {
                        if (!this.$el.find(".confirm-hmrc")[0].checked) {
                            this.$el.find(".working-dog-notification")
                                .removeClass("warning")
                                .addClass("error");
                            return;
                        }
                    }
                }

                this.$el.find('.step.out').remove();
                this.$el.find('.step.in').removeClass("in");
                var form = this.$el.find("form");
                var action = button.attr("formaction");
                var method = button.attr("formmethod");
                if (!action) {
                    action = form.attr("action");
                }
                if (!method) {
                    method = form.attr("method");
                }

                if (button.hasClass("js-step-back")) {
                    this.loadStep(method, action, {}, true, true);
                } else {
                    t.message.publish('next', 'clicked');
                    this.loadStep(method, action, form.serialize(), false, true);
                }
            },
            onBackClick: function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (this.pending) {
                    return;
                }

                var back_url = e.currentTarget.getAttribute('href');

                this.$el.find('.step.out').remove();
                this.$el.find('.step.in').removeClass("in");
                this.loadStep('get', back_url, {}, true, true);
            }
        }
    );

    t.controllers.define(
        'promo-entry',
        {
            'click .confirm-promo': 'onConfirmClick',
            'click a.change-promo': 'onChangeClick'
        },
        {
            'init:after': function () {
                t.message.subscribe('promo-widget', 'changed', t.util.bind(function (e) {
                    this.promoUpdated(e['data']);
                }, this));
            },
            'onConfirmClick': function (e) {
                e.preventDefault();

                var button = $(e.currentTarget);
                var code = this.$el.find("input[name=code]").val();
                this.$el.find(".promo-input-wrapper").attr("data-state", "progress");

                $.post(
                    button.data("target"),
                    {
                        'code': code,
                        'csrf_token': this.$el.find("input[name=csrf_token]").val()
                    },
                    t.util.bind(function (result) {
                        if (result['status'] === 'success') {
                            t.message.publish('promo-widget', 'updated', {
                                "status": "success",
                                "promo": result['promo']
                            });
                            this.promoUpdated(result['promo']);
                        } else if (result['error']) {
                            this.$el.find(".promo-input-wrapper").attr("data-state", "error");
                            this.$el.find(".alerts").html(
                                '<p class="alert alert-error">' + result['error'] + '</p>'
                            )
                        }
                    }, this)
                );
                return false;
            },
            'promoUpdated': function (promo) {
                if (promo) {
                    this.$el.find(".promo-description .message").text(
                        promo['landing_message'] || promo['description']
                    );
                    this.$el.find(".promo-input-wrapper").attr("data-state", "promo");
                    this.$el.find("input.change-promo").val(promo['code']);
                    this.$el.attr("data-state", "");
                } else {
                    this.$el.find(".promo-description .message").text("");
                    this.$el.find(".promo-input-wrapper").attr("data-state", "no-promo");
                    this.$el.find("input.change-promo").val("");
                }
                this.$el.find(".alerts").empty();
            },
            'onChangeClick': function (e) {
                e.preventDefault();
                this.$el.attr("data-state", "blocked");
                this.$el.find(".promo-input-wrapper").attr("data-state", "no-promo");
                return false;
            }
        }
    );

    t.controllers.define(
        'select-country',
        {
            'change #country': 'onSelectCountry'
        },
        {
            'init:after': function () {
            },
            onSelectCountry: function (e) {
                e.preventDefault();
                let selected_country = e.currentTarget.value;
                document.getElementById("flag").src = "/static/images/country-flags/" + selected_country + ".png";
                document.getElementById("nav-country-flag").src = "/static/images/country-flags/" + selected_country + "-square.png";
            }
        }
    );

    t.controllers.define(
        'pets',
        {
            "click #add-more": "onAddMoreClick",
            "click .name-submit": "onAddMoreSubmitClick",
            "click .remove-new": "onRemoveNewClick",
            "change .removed_pet": 'onRemovePetChecked',
            "keydown #new-pets-name": "onKeypress",
            "click .btn.checkout": 'onCheckout'
        },
        {
            'init:after': function () {
                this.pets = this.$el.find(".pets");
                this.show_add_pet_checkbox = this.$el.find("#add-new-dog-show");
                this.two_pets_one_profile_helper = this.$el.find("#two-pets-one-profile-helper")[0];
                this.new_pet_name = this.$el.find('#new-pets-name');
                this.csrf = this.$el.find('input[name=csrf_token]').val();
                this.add_pet_url = this.$el.find("#add-pet-url").val();
                this.delete_pet_url = this.$el.find("#delete-pet-url").val();

                $('.account').attr("data-state", "in");
                var promo_data = this.$el.find(".promo-data");
                if (promo_data.length) {
                    promo_data = JSON.parse(promo_data.html());
                    t.message.publish('promo-widget', 'updated', promo_data);
                }
            },
            'onAddMoreClick': function () {
                var that = this;
                setTimeout(function () {
                    that.new_pet_name.focus();
                }, 100);
            },
            'onKeypress': function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.submitName();
                }
                var pet_name_contains_and = this.new_pet_name.val().toLowerCase().search(' and ');
                var pet_name_contains_ampersand = this.new_pet_name.val().search(' & ');

                if (pet_name_contains_and !== -1 || pet_name_contains_ampersand !== -1) {
                    this.showTwoPetsOneProfileHelper()
                } else {
                    this.hideTwoPetsOneProfileHelper()
                }
            },
            'onAddMoreSubmitClick': function (e) {
                e.preventDefault();
                e.stopPropagation();
                this.submitName();
            },
            'submitName': function () {
                var new_pets_name = this.new_pet_name.val();
                if (!new_pets_name) {
                    this.new_pet_name.addClass("error");
                }
                this.addPet(new_pets_name);
            },
            'addPet': function (new_pets_name) {
                var that = this;
                $.post(this.add_pet_url, {
                    csrf_token: this.csrf,
                    new_pets_name: new_pets_name
                }).done(function (data) {
                    if (data['success']) {
                        that.$el.find("#pet-name-errors").addClass('hide');
                        that.$el.find("input[name=new_pets_name]").removeClass("error");
                        var html = $($.parseHTML(data['html']));
                        that.$el.find('#add-new-dog-show').prop("checked", false);
                        if (that.pets.find(".pet[data-pet_id=" + data['pet_id'] + "]").length > 0) {
                            return
                        }
                        html.insertBefore(that.pets.find(".pet.add"));
                        that.new_pet_name.val("");
                        $('#add-more').removeClass("hide");
                        that.hideTwoPetsOneProfileHelper();
                    } else {
                        if (data['errors']['new_pets_name'] !== undefined) {
                            that.$el.find("input[name=new_pets_name]").addClass("error");
                            that.$el.find("#pet-name-errors").removeClass('hide');
                            that.$el.find("#pet-name-errors").html(
                                '<p class="alert alert-error">' + data['errors']['new_pets_name'] + '</p>');
                        }
                    }
                });
            },
            'onRemovePetChecked': function (e) {
                var pet_public_id = $(e.currentTarget).val();
                this.removePet(pet_public_id);
            },
            'removePet': function (pet_public_id) {
                $.post(this.delete_pet_url, {
                    csrf_token: this.csrf,
                    removed_pet: pet_public_id
                });
            },
            'onRemoveNewClick': function () {
                this.show_add_pet_checkbox.prop("checked", false);
            },
            showTwoPetsOneProfileHelper: function () {
                this.two_pets_one_profile_helper.classList.remove("hidden");
            },
            hideTwoPetsOneProfileHelper: function () {
                this.two_pets_one_profile_helper.classList.add("hidden");
            },
            'onCheckout': function () {
                $("a.btn.checkout").addClass('link-progress');
            }
        }
    );

    t.controllers.define(
        'signup-profile',
        {
            'click .js-remove-pet': 'onDeleteClick',
            'click .js-checkout': 'onCheckoutClick',
        },
        {
            onDeleteClick(e) {
                e.preventDefault();
                const elem = $(e.currentTarget);
                const url = elem.attr('href');
                t.ui.alert.show(
                    '',
                    elem.data("alert-title"),
                    elem.data("alert-content"),
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        'Remove'
                    ],
                    t.util.bind(function (button) {
                        if (button === 'Remove') {
                            window.location = url;
                        }
                    }, this)
                );
            },

            onCheckoutClick(e) {
                e.preventDefault();
                const elem = $(e.currentTarget);
                const url = elem.attr('href');
                t.ui.alert.show(
                    '',
                    elem.data("alert-title"),
                    elem.data("alert-content"),
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        t.ui.alert.BUTTON_OK,
                    ],
                    t.util.bind(function (button) {
                        if (button === 'ok') {
                            window.location = url;
                        }
                    }, this)
                );
            }
        }
    );

    t.controllers.define(
        'multi-select',
        {
            'change input:checkbox': "onCheckboxChange"
        },
        {
            'onCheckboxChange': function (e) {
                var target = $(e.currentTarget);
                if (target.val() === "0") {
                    this.$el.find("input:checkbox").prop("checked", false);
                    target.prop("checked", true);
                } else {
                    this.$el.find('input:checkbox[value=0]').prop("checked", false);
                }
            }
        }
    );

    t.controllers.define(
        'age-input',
        {
            'change input[name=age-age_years]': 'setYearText',
            'change input[name=age-age_months]': 'setMonthText'
        },
        {
            'init:after': function () {
                this.yearSelect = this.$el.find('#age-age_years');
                this.monthSelect = this.$el.find('#age-age_months');

                this.ageWrapper = this.$el.find('.age-wrapper');
                this.ageData = this.ageWrapper.find('#age-data');

                this.setAgeText();
            },
            'setAgeText': function () {
                this.setYearText();
                this.setMonthText();
            },
            'setYearText': function () {
                const yearText = this.ageData.find('#years');

                this.yearSelect.val().toString() === '1' ?
                    yearText.text(this.ageData.data('year')) :
                    yearText.text(this.ageData.data('years'));
            },
            'setMonthText': function () {
                const monthText = this.ageWrapper.find('#months');

                this.monthSelect.val().toString() === '1' ?
                    monthText.text(this.ageData.data('month')) :
                    monthText.text(this.ageData.data('months'));
            },
        }
    );

    t.controllers.define(
        'puppy-birthday',
        {
            'change select[name=dob_month]': 'limitDays',
        },
        {
            'init:after': function () {
                this.days_in_months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                this.$month = this.$el.find('select[name=dob_month]');
                this.$day = this.$el.find('select[name=dob_day]');
                this.limitDays();
            },
            limitDays: function () {
                const month_index = parseInt(this.$month.val()) - 1;
                const days_in_month = this.days_in_months[month_index];
                var select_day = parseInt(this.$day.val());
                if (select_day > days_in_month) {
                    this.$day.val(days_in_month);
                }
                this.$day.children().prop("disabled", false).slice(days_in_month + 1).prop("disabled", true);
            }
        },
    );

    t.controllers.define(
        'exclusions-my-dog',
        {
            'click #exclusions-0': 'onExclusionsChange',
            'click #exclusions-1': 'onExclusionsChange',
        },
        {
            'init:after': function () {
                this.onExclusionsChange();
            },
            onExclusionsChange: function () {
                const selected = this.$el.find('input[name="exclusions"]:checked')[0].value;
                if (selected === 'yes') {
                    this.$el.find('fieldset.exclusions-choices').removeClass('hidden');
                    document.getElementsByClassName('exclusions-no')[0].classList.add('hide');
                    document.getElementsByClassName('exclusions-yes')[0].classList.remove('hide');
                } else {
                    this.$el.find('fieldset.exclusions-choices').addClass('hidden');
                    document.getElementsByClassName('exclusions-no')[0].classList.remove('hide');
                    document.getElementsByClassName('exclusions-yes')[0].classList.add('hide');
                }
            },
        }
    );

    t.controllers.define(
        'health',
        {
            'change .issue-pancreatitis': 'onPancreatitisChange',
        },
        {
            'init:after': function () {
                const healthIssueListItems = this.$el.find('.issue.checkbox-wrapper-alt');
                const pancreatitisInfoBox = this.$el.find('.pancreatitis.health');
                let pancreatitisEl = null;

                for (let i = 0; i < healthIssueListItems.length; i++) {
                    const pancreatitisInput = $(healthIssueListItems[i]).find('.issue-pancreatitis');
                    if (pancreatitisInput.length) {
                        pancreatitisEl = healthIssueListItems[i];
                    }
                }

                if (pancreatitisEl && pancreatitisInfoBox.length) {
                    $(pancreatitisEl).after(pancreatitisInfoBox);
                }

            },
            onPancreatitisChange: function () {
                this.$el.find('.issue-pancreatitis').prop('checked') ?
                    this.$el.find('.pancreatitis.health').removeClass('hidden') :
                    this.$el.find('.pancreatitis.health').addClass('hidden')
            },
        }
    );

    t.controllers.define(
        'pet-weight',
        {
            'click .unknown-weight a': 'onUnknownWeightClick',
            'click .change-weight': 'onChangeWeightClick',
            'keypress .pet-weight-input input': "onKeypress",
            'change .pet-weight-input input': "convertToDecimal",
            'change .weight-select-dropdown': 'onUnitChange',
            'click #weight-help-label': 'injectModalData',
            'click .js-step-next': 'closeWeightInfo'
        },
        {
            'init:after': function () {
                this.$weightWarning = this.$el.find('.weight-warning');
                this.$weightError = this.$el.find('.weight-error');
                this.unit_selector = this.$el.find("#weight-select");
                this._currentUnit = this.unit_selector.val();
                this.useEstimateButton = document.querySelector('.use-estimate');
                const that = this;
                $(this.useEstimateButton).click(function (e) {
                    that.onEstimateClick(e);
                });
                this.autoEstimate();
            },
            injectModalData: function (e) {
                const $target = $(e.target);
                const estimatedWeight = $target.data('estimated-weight');
                const petName = $target.data('pet-name');
                const elision = $target.data('elision-que');
                const possessive = $target.data('possessive');

                const modalTarget = $target.attr('for');
                const modalBody = document.querySelector(`#${modalTarget} + .modal .modal-body`);
                const $modalBody = $(modalBody);

                $modalBody.find('#pet-name').text(petName);
                $modalBody.find('#possessive').text(possessive);
                $modalBody.find('#elision-que').text(elision);
                $modalBody.find('#estimated-weight').text(estimatedWeight);
                $modalBody.find('#weight-estimate-button').attr('weight-estimate', estimatedWeight);
            },
            onKeypress: function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.$el.find(".pet-weight-input input").blur();
                }
                return this.isNumberKey(e);
            },
            isNumberKey: function (e) {
                const charCode = (e.which) ? e.which : e.keyCode;
                const txt = e.target.value;
                if (charCode == 46 || charCode == 44) {
                    //Check if the text already contains the . or , characters
                    if (txt.indexOf('.') === -1 && txt.indexOf(',') === -1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    //Check if the text is a number
                    if (charCode > 31 && (charCode < 48 || charCode > 57))
                        return false;
                }
                return true;
            },
            convertToDecimal: function (e) {
                e.target.value = (parseFloat(e.target.value.replace(',', '.') || 0));
            },
            onUnitChange: function () {
                var dropDownValue = this.unit_selector.val();
                this.switchUnit(dropDownValue);
            },
            onUnknownWeightClick: function (e) {
                $(e.target).closest('p').slideUp('fast');
                this.$el.find('.estimate-weight').removeClass('hide').hide().slideDown('fast');
                e.preventDefault();
            },
            autoEstimate: function () {
                if (this.$el.find('.notification-content').children().text()) {
                    const unit = this.$el.find('#weight-select').val();
                    const input = '.pet-weight-input'
                    let weight = '';
                    if (unit === 'st') {
                        weight = this.$el.find(`${input}-st-st`).val() || this.$el.find(`${input}-st-lb`).val();
                    } else {
                        weight = this.$el.find(`${input}-${unit}-${unit}-${unit}`).val();
                    }
                    weight == 0 && this.populateEstimate();
                }
            },
            onEstimateClick: function (e) {
                this.populateEstimate();

                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: 'button',
                    eventAction: 'click',
                    eventLabel: 'use weight estimate'
                });
                e.preventDefault();
            },
            populateEstimate: function () {
                const estimateData = this.useEstimateButton.getAttribute('weight-estimate');
                this.switchUnit('kg');
                this.$el.find('.pet-weight-input-group-kg input').val(estimateData).focus();
                const estimateWeightCheckbox = document.querySelector('#weight-help');
                estimateWeightCheckbox.checked = false;
            },
            hideAllInputs: function () {
                this.$el.find('.pet-weight-input-group').hide().find('input').attr('disabled', true);
            },
            switchUnit: function (targetUnit) {
                if (targetUnit === this._currentUnit) {
                    return;
                }
                this.hideAllInputs();

                var $targetFields = this.$el.find('.pet-weight-input-group-' + targetUnit + ' input');
                this.unit_selector.val(targetUnit);

                var conversionFactors = {
                    kg: {
                        lb: 2.2046
                    },
                    lb: {
                        kg: 0.4536
                    }
                };
                var existingValue, pounds, newValue, stone, totalPounds = null;

                // convert stone and pounds to just pounds
                if (conversionFactors[this._currentUnit] && conversionFactors[this._currentUnit][targetUnit]) {
                    existingValue = this._getValuesForUnit(this._currentUnit);
                    if (existingValue) {
                        newValue = existingValue * conversionFactors[this._currentUnit][targetUnit];
                        $targetFields.val(Math.round(newValue * 10) / 10);
                    } else {
                        $targetFields.val('');
                    }
                } else if (targetUnit === 'st') {
                    existingValue = this._getValuesForUnit(this._currentUnit);
                    if (existingValue) {
                        totalPounds = this._currentUnit === 'kg' ? (existingValue * conversionFactors['kg']['lb']) : existingValue;

                        pounds = (totalPounds % 14);
                        stone = (totalPounds - (totalPounds % 14)) / 14;
                        $targetFields.addBack().find('.pet-weight-input-st-st').val(stone);
                        $targetFields.addBack().find('.pet-weight-input-st-lb').val(Math.round(pounds * 10) / 10);
                    } else {
                        $targetFields.val('');
                    }
                } else if (this._currentUnit === 'st') {
                    existingValue = this._getValuesForUnit(this._currentUnit);
                    stone = existingValue[0];
                    var p = existingValue[1];

                    if (stone > 0 || p > 0) {
                        totalPounds = (stone * 14) + p;

                        newValue = targetUnit === 'kg' ? (totalPounds * conversionFactors['lb']['kg']) : totalPounds;
                        $targetFields.val(Math.round(newValue * 10) / 10);
                    } else {
                        $targetFields.val('');
                    }
                }

                $targetFields.closest('.pet-weight-input-group').removeClass('hide').show().find('input').removeAttr('disabled');
                this._currentUnit = targetUnit;
            },
            onChangeWeightClick: function (e) {
                e.preventDefault();

                this.$el.find('.pet-weight-input-group-' + this._currentUnit + ' input').val('').first().focus();
                this.$el.closest('form').find('button[type=submit]').removeAttr('disabled');

                this.$weightWarning.slideUp('fast');
                this.$weightError.slideUp('fast');
            },
            closeWeightInfo: function () {
                $("#weight-help").attr('checked', false);
            },
            _getValuesForUnit: function (unit) {
                if (unit === 'kg' || unit === 'lb') {
                    return this.$el.find('.pet-weight-input-group-' + this._currentUnit + ' input').val();
                } else if (unit === 'st') {
                    return [
                        parseInt(this.$el.find('input.pet-weight-input-st-st').val()) || 0,
                        parseFloat(this.$el.find('input.pet-weight-input-st-lb').val()) || 0
                    ];
                }
            }
        }
    );

    t.controllers.define(
        'email-step',
        {
            'keyup #email': "onEmailChange",
            'input #email': "validateEmailField",
            'change #opt_in-0': "validateCommsSelect",
            'change #opt_in-1': "validateCommsSelect",
            'click .email-step-submit': "validateEmailForm",
            'resize': 'resizer',
        },
        {
            "init:after": function () {
                this.input = this.$el.find("#email");
                this.to = null;
                this.email = this.input.val();
                this.reset();
                this.validateEmailField();
                this.initCommsSelect();
                this.productShotHeroText = this.$el.find('#product-shot-hero-text')[0];
                this.ogWidth = this.productShotHeroText.offsetWidth;
                this.ogRotation = window.getComputedStyle(this.productShotHeroText).transform;
                this.resizer();
            },
            reset: function () {
                const inlineError = this.$el.find('.email-inline-error');
                inlineError.css('display', 'none');

                const emailInput = this.$el.find('#email');
                emailInput.css('border', '1px solid #3e4c60');

                const hiddenOptions = this.$el.find('.email-hidden');
                hiddenOptions.css('border', 'none');

                const hiddenOptError = this.$el.find('.opt-in-inline-error');
                hiddenOptError.css('display', 'none');
            },
            getEmail: function () {
                return this.input.val().trim();
            },
            onEmailChange: function (e) {
                this.reset();

                if (this.to) {
                    clearTimeout(this.to);
                }
                this.to = setTimeout(t.util.bind(function () {
                    this.validateEmailField();
                }, this), 300);
            },
            initCommsSelect: function () {
                const hiddenOptError = this.$el.find('.opt-in-inline-error');
                hiddenOptError.css('display', 'none');
            },
            validateCommsSelect: function () {
                this.opt_selected = false;
                if (this.$el.find('input[name=opt_in]:radio:checked').length > 0) {
                    this.opt_selected = true;
                }

                this.validateOptInForm();
            },
            regexValidation: function (email) {
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            },
            validateEmailField: function () {
                let email = this.getEmail();
                this.email_valid = false;

                this.email = email;
                if (this.regexValidation(email)) {
                    this.email_valid = true;
                    const hiddenOptions = this.$el.find('.email-hidden');
                    hiddenOptions.css('display', 'block');
                } else {
                    const hiddenOptions = this.$el.find('.email-hidden');
                    hiddenOptions.css('display', 'none');
                }
            },
            validateEmailForm: function (e) {
                if (!this.email_valid) {
                    const inlineError = this.$el.find('.email-inline-error');

                    const emailInput = this.$el.find('#email');
                    inlineError.css('display', 'flex');

                    emailInput.css('border', '#a91910 1px solid');

                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();

                    }

                    return;
                }

                if (!this.validateOptInForm()) {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();

                    }
                }
            },
            validateOptInForm: function (e) {
                if (!this.opt_selected) {
                    const hiddenOptions = this.$el.find('.email-hidden');
                    hiddenOptions.css('border', '#a91910 1px solid');

                    const hiddenOptError = this.$el.find('.opt-in-inline-error');
                    hiddenOptError.css('display', 'flex');

                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    return false;
                }
                return true;
            },
            resizer: function () {
                const productShotImage = $('#product-shot-hero-text').parent();
                const targetWidth = productShotImage.width() * 0.15;
                const downScale = this.ogWidth / targetWidth;
                const actualWidth = Math.cos(-0.24) * this.ogWidth;
                const actualHeight = Math.tan(-0.24) * actualWidth;
                const widthOffset = (actualWidth / 1.8) * downScale;
                const heightOffset = (actualHeight / 2) * downScale;
                const transformString = `${this.ogRotation} scale(${targetWidth / this.ogWidth}) translate(-${widthOffset}px, ${heightOffset}px)`;
                this.productShotHeroText.style.transform = transformString;
            }
        },
    );

    t.controllers.define(
        'motivation-bag',
        {
            'resize': 'resizer',
        },
        {
            "init:after": function () {
                this.productShotHeroText = this.$el.find('#product-shot-hero-text')[0];
                this.ogWidth = this.productShotHeroText.offsetWidth;
                this.ogRotation = window.getComputedStyle(this.productShotHeroText).transform;
                this.resizer();
            },
            resizer: function () {
                const productShotImage = $('#product-shot-hero-text').parent();
                if (productShotImage.hasClass('weight-motivation-info-v2')) {
                    productShotImage.css("text-align", "center")
                    const targetWidth = productShotImage.width() * 0.15;
                    const downScale = this.ogWidth / targetWidth;
                    const actualWidth = Math.cos(-0.42) * this.ogWidth;
                    const actualHeight = Math.tan(0.14) * actualWidth;
                    const widthOffset = (actualWidth / 1.8) * downScale;
                    const heightOffset = (actualHeight / 2.5) * downScale;
                    const transformString = `${this.ogRotation} scale(${targetWidth / this.ogWidth}) translate(-${widthOffset}px, ${heightOffset}px)`;
                    this.productShotHeroText.style.transform = transformString;
                } else {
                    const targetWidth = productShotImage.width() * (productShotImage.hasClass('delivery-motivation-info-v2') ? 0.11 : 0.15);
                    const downScale = this.ogWidth / targetWidth;
                    const actualWidth = Math.cos(-0.24) * this.ogWidth;
                    const actualHeight = Math.tan(-0.24) * actualWidth;
                    const widthOffset = (actualWidth / 1.8) * downScale;
                    const heightOffset = (actualHeight / 2) * downScale;
                    const transformString = `${this.ogRotation} scale(${targetWidth / this.ogWidth}) translate(-${widthOffset}px, ${heightOffset}px)`;
                    this.productShotHeroText.style.transform = transformString;
                }
            }
        },
    );

    t.controllers.define(
        'dry-recipe-choice',
        {
            'click .macro-dry-blend': 'selectThis',
            'click .details-toggle': 'detailsToggle',
            'click .details-popup': 'popupClose',
            'click .can-expand': 'expand',
            'change [name="recipe"]': 'recipeChange'
        },
        {
            "init:after": function () {

            },
            selectThis: function (e) {
                const component = e.target.closest('.macro-dry-blend');
                if (!component) {
                    return
                }
                const input = $(component.querySelector('input'));
                input.not(':checked').prop('checked', true);
                this.recipeChange(null, $(component));
            },
            detailsToggle: function (e) {
                const target = $(e.target);
                const blendType = target.data('blend-type');
                const popup = $(`.details-popup[data-blend-type="${blendType}"]`);
                popup.show();
                e.preventDefault();
            },
            popupClose: function (e) {
                const target = $(e.target);
                const blendType = target.data('blend-type');
                const popup = $(`.details-popup[data-blend-type="${blendType}"]`);
                popup.hide();
                e.preventDefault();
            },
            expand: function (e) {
                const target = $(e.target);
                target.closest('.details-popup').prependTo('.step-content');
                target.closest('.expander').find('.to-expand').toggle();
                target.closest('.expander').find('.icon-toggle-open').toggle();
                target.closest('.expander').find('.icon-toggle-close').toggle();
                e.preventDefault();
            },
            recipeChange: function (e, component) {
                if (e) {
                    component = $(e.target.closest('.macro-dry-blend'));
                }
                $('.recipe-choice-selected').hide();
                $('.recipe-choice-not-selected').show();
                $('.radio-buttons').removeClass('selected');
                $('.dry-display-border-purple').removeClass('dry-display-border-purple');
                component.find('.recipe-choice-selected, .recipe-choice-not-selected').toggle();
                component.find('.dry-display').addClass('dry-display-border-purple');
                component.find('.radio-buttons').addClass('selected');
            },
        },
    );

    t.controllers.define(
        'gender-input',
        {
            'change input[name=sex-sex]': 'setNeutered',
        },
        {
            'init:after': function () {
                this.neuteredData = this.$el.find('#neutered-data');
                this.setNeutered();
            },
            'getOptionValues': function (gender) {
                const options = {
                    'yes': '',
                    'no': ''
                };
                if (gender === 'male') {
                    options.yes = this.neuteredData.data('male-neutered');
                    options.no = this.neuteredData.data('male-not-neutered');
                } else {
                    options.yes = this.neuteredData.data('female-spayed');
                    options.no = this.neuteredData.data('female-not-spayed');
                }

                return options;
            },
            'setNeutered': function () {
                const gender = this.$el.find('input[name="sex-sex"]:checked').val();
                const options = this.getOptionValues(gender);
                this.$el.find('label[for="neutered-choice-yes"] span').text(options.yes);
                this.$el.find('label[for="neutered-choice-no"] span').text(options.no);
                if (gender) {
                    this.$el.find('.neutered-container').removeClass("hide");
                }
            },
        }
    );

    t.controllers.define(
        'pet-activity-slider',
        {
            'input input[name=activity-slider_value]': 'setActivityPanel',
        },
        {
            'init:after': function () {
                this.slider = this.$el.find('#activity-slider_value');
                this.activityLevel = this.$el.find('.activity-level');
                this.setActivityPanel();
            },
            setActivityPanel: function () {
                this.slider.attr("value", this.slider.val());
                this.slider.attr("style", `background: linear-gradient(to right, var(--primary-blueberry) 0%, var(--primary-blueberry) ${this.slider.val()}%, #fff ${this.slider.val()}%, white 100%)`);
                this.activityLevel.addClass("hidden");
                if (this.slider.val() < 26) {
                    this.activityLevel = this.$el.find(`[data-activity-level='2']`);
                    this.activityLevel.removeClass("hidden");
                } else if (this.slider.val() < 51) {
                    this.activityLevel = this.$el.find(`[data-activity-level='3']`);
                    this.activityLevel.removeClass("hidden");
                } else if (this.slider.val() < 76) {
                    this.activityLevel = this.$el.find(`[data-activity-level='0']`);
                    this.activityLevel.removeClass("hidden");
                } else {
                    this.activityLevel = this.$el.find(`[data-activity-level='1']`);
                    this.activityLevel.removeClass("hidden");
                }
            }
        },
    );

    t.controllers.define(
        'working-dog',
        {
            'click .working-dog-radio': 'onClickWorkingDogRadio',
        },
        {
            'init:after': function () {
                if (this.$el.find("#working-1")[0].checked) {
                    this.$el.find(".working-dog-notification")
                        .removeClass("hidden")
                        .addClass("warning");
                }
            },
            onClickWorkingDogRadio: function (e) {
                if (e.target.checked) {
                    if (e.target.id === "working-0") {
                        this.$el.find(".working-dog-notification").addClass("hidden");
                    }

                    if (e.target.id === "working-1") {
                        this.$el.find(".working-dog-notification")
                            .removeClass("hidden")
                            .addClass("warning");
                    }
                }
            },
        },
    );

    t.controllers.define(
        'breed-selector',
        {
            'change input[name=breed_type]': 'onSelectBreedType',
            'blur input[name=breed-selector-0]': 'onBreedTypeSelected',
            'blur input[name=breed-selector-1]': 'onBreedTypeSelected',
            'blur input[name=breed-selector-2]': 'onBreedTypeSelected',
            'blur input[name=breed-selector-3]': 'onBreedTypeSelected',
            'blur input[name=breed-selector-4]': 'onBreedTypeSelected',
        },
        {
            'init:after': function () {
                this.$el.find('.tails-view-fuzzy-filter').each(t.util.bind(function (i, element) {
                    t.message.subscribe($(element).data("event"), "item-selected", t.util.bind(function (message) {
                        let selected_element = message['data'];
                        this.updateAlerts(selected_element.data("value"));
                    }, this));
                }, this));

                const crossbreeds = this.$el.find('div[name=crossbreed] input.search');
                crossbreeds.length && (crossbreeds[0].value || crossbreeds[1].value)
                    ? this.toggleCrossbreed('show')
                    : this.toggleCrossbreed('hide');

                const errors = document.querySelector('.notification.error');
                if (errors) {
                    this.showBreedInputIfBreedType();
                } else {
                    this.hidePurebreedInputIfNoData();
                }
                this.hideBreedInputIfBreedTypeUnknown();

                this.onBreedTypeSelected(); // check breed type conditions
            },
            updateAlerts: function (breed_id) {
                this.$el.find('.breed-alert').removeClass('show').addClass('hide');
                this.$el.find('.breed-alert[data-breed-id="' + breed_id + '"]').removeClass("hide").addClass("show");
            },
            showBreedInputIfBreedType: function () {
                const breedType = this.$el.find('input[name=breed_type]:checked')[0];
                if (breedType) {
                    if (breedType.value === 'crossbreed') {
                        this.toggleCrossbreed('show');
                    } else if (breedType.value === 'purebreed') {
                        this.togglePurebreed('show');
                    }
                }
            },
            hidePurebreedInputIfNoData: function () {
                const selected = this.$el.find('input[name=breed_type]:checked')[0];
                if (selected.value === 'purebreed') {
                    const purebreed = this.$el.find('div[name=purebreed] input.search')[0].value;
                    if (!purebreed) {
                        this.togglePurebreed('hide');
                        selected.removeAttribute('checked');
                    } else {
                        this.togglePurebreed('show');
                    }
                }
                ;
            },
            showPurebreed: function () {
                if (this.$el.find('.purebreed').hasClass('hide')) {
                    this.togglePurebreed('show');
                }
            },
            hideBreedInputIfBreedTypeUnknown: function () {
                if (this.$el.find('input[name=breed_type]').val() === 'unknown') {
                    this.togglePurebreed('hide');
                }
            },
            onSelectBreedType: function (e) {
                this.clearSelection();

                if (e.target.value === 'crossbreed') {
                    this.toggleCrossbreed('show');
                    this.togglePurebreed('hide');

                } else {
                    this.toggleCrossbreed('hide');
                }

                if (e.target.value === 'unknown') {
                    this.hideBreedInput()
                    this.$el.find('input[type=radio]').removeAttr('checked');
                } else {
                    this.$el.find('input[type=checkbox]').removeAttr('checked');
                }

                if (e.target.value === 'purebreed') {
                    this.showPurebreed();
                }
            },
            toggleCrossbreed: function (toggle) {
                const remove = toggle === 'hide' ? 'show' : 'hide';
                const crossbreed = this.$el.find('div[name=crossbreed]');
                const purebreed = this.$el.find('div[name=purebreed]');
                crossbreed.removeClass(remove).addClass(toggle);
                if (toggle === 'hide') {
                    purebreed.removeClass('crossbreed').addClass('purebreed')
                } else {
                    this.$el.find('input[type=checkbox]').removeAttr('checked');
                }
            },
            togglePurebreed: function (toggle) {
                const remove = toggle === 'hide' ? 'show' : 'hide';
                this.$el.find('div[name=purebreed]').removeClass(remove).addClass(toggle);
            },
            hideBreedInput: function () {
                this.toggleCrossbreed('hide');
                this.togglePurebreed('hide');
            },
            clearSelection: function () {
                $('.search').val('');
                $('.tails-view-filter-list').attr('data-state', 'init');
                $('.js-step-next').attr({disabled: false});
                $('.notification').removeClass('show').addClass('hide');
            },
            onBreedTypeSelected: function () {
                const dalmatian = ['Dalmatian', 'Dalmatien', 'Dalmatiner', 'Dalmatiër'];
                const minBreedAge = 18;
                let hasDalmatianAndNotOfAge = false;
                const breedTypes = document.getElementsByClassName('search');
                for (const breedType of breedTypes) {
                    if (dalmatian.includes(breedType.value) && $('.age-in-months').val() < minBreedAge) {
                        hasDalmatianAndNotOfAge = true;
                        break;
                    }
                }

                if (hasDalmatianAndNotOfAge) {
                    // changing the CTA type to `button` from `submit` since button types cannot
                    // submit forms. If in the future the product team agrees to include disabled
                    // buttons in DSM definitions, then this should be changed to
                    // $('.js-step-next').attr({ disabled: true }); and
                    // $('.js-step-next').attr({ disabled: false }); for the respective conditions
                    $('.js-step-next').attr({type: 'button'});
                    $('.notification.error.breed-alert').removeClass('hide').addClass('show');
                } else {
                    $('.js-step-next').attr({type: 'submit'});
                    $('.notification.error.breed-alert').removeClass('show').addClass('hide');
                }
            }
        }
    );

    t.controllers.define(
        'pregnant',
        {
            'change select[name=pregnant]': 'onPregnantChange',
            'change input[name=pregnant]': 'onSelectPregnant',
        },
        {
            'init:after': function () {
                this.$pregnant = this.$el.find('select[name=pregnant]');
                this.onPregnantChange();
                this.onSelectPregnant();
            },
            onSelectPregnant: function () {
                const selected = this.$el.find('input[name=pregnant]:checked')[0];
                const message = this.$el.find('div[name=pregnant-notification]');
                if (selected) {
                    message.toggleClass('hidden', selected.value === 'no');
                    const nextBtn = $('button[name="next_btn_pregnant"]');
                    nextBtn.attr('disabled', selected.value === 'yes');
                }
            },
            onPregnantChange: function () {
                if (this.$pregnant.val() === 'yes') {
                    this.$el.find('.notifications.hidden').removeClass('hidden');
                    this.$el.find('button[name=next_btn_pregnant]').attr('disabled', 'disabled');
                } else {
                    this.$el.find('.notifications').addClass('hidden');
                    this.$el.find('button[name=next_btn_pregnant]').removeAttr('disabled', 'disabled');
                }
            }
        }
    );

    t.controllers.define(
        'new-food-conversation',
        {
            'change input[name="menu"]': 'onRadioChange'
        },
        {
            onRadioChange: function (event) {
                let value = event.target.value;
                if (value === 'dry-wet-treats') {
                    this.$el.find('.radio-buttons-new').addClass('dry-wet-treats');
                    this.$el.find('.radio-buttons-new').removeClass('dry-wet');
                } else if (value === 'dry-wet') {
                    this.$el.find('.radio-buttons-new').addClass('dry-wet');
                    this.$el.find('.radio-buttons-new').removeClass('dry-wet-treats');
                } else {
                    this.$el.find('.radio-buttons-new').removeClass('dry-wet');
                    this.$el.find('.radio-buttons-new').removeClass('dry-wet-treats');
                }
            }
        }
    );

    t.controllers.define(
        'notification',
        {
            'click #dismiss-notification': 'dismissNotification',
        },
        {
            dismissNotification: function () {
                this.$el.addClass('hidden');
            }
        }
    );

    t.controllers.define(
        'hide-trustpilot',
        {},
        {
            'init:after': function () {
                this.hideTrustpilot();
            },
            hideTrustpilot: function () {
                const trustpilot = $('div.trustpilot-block');
                trustpilot.length === 1 && trustpilot.addClass('hidden');
            },
        }
    );

})($, window.tails);
