import $ from "jquery"
import Fuse from "fuse.js"
import "imports-loader?window.jQuery=jquery!jquery.kinetic/jquery.kinetic"
import "slick-carousel"
import "jquery-waypoints/waypoints.js"
import Mailcheck from "mailcheck"
import dragscroll from "dragscroll"
import Vue from "vue"
import IBAN from "iban"

// Patch to mitigate XSS vulnerability
// See https://api.jquery.com/jQuery.htmlPrefilter/
// and https://npmjs.com/advisories/1518  for info.
$.htmlPrefilter = (html) => html;

(function () {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    window.isMobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0, 4));
    window.is_native_android = ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));
    window.is_ie = window.navigator.userAgent.indexOf("MSIE ") != -1 || window.navigator.userAgent.indexOf("Trident") != -1;
    console.log(window.navigator.userAgent)
}());

(function ($) {
    $.fn.findShallowest = function (sel) {
        return findShallowest(this, sel);
    };

    function findShallowest(root, sel) {
        var children = root.children();
        if (children.length) {
            var matching = children.filter(sel);
            if (matching.length) {
                return matching.first();
            } else {
                return findShallowest(children, sel);
            }
        } else {
            return $();
        }
    }

    Array.prototype.diff = function (a) {
        return this.filter(function (i) {
            return a.indexOf(i) < 0;
        });
    };

    if (window.XDomainRequest) {
        $.ajaxTransport(function (s) {
            if (s.crossDomain && s.async) {
                if (s.timeout) {
                    s.xdrTimeout = s.timeout;
                    delete s.timeout;
                }
                var xdr;
                return {
                    send: function (_, complete) {
                        function callback(status, statusText, responses, responseHeaders) {
                            xdr.onload = xdr.onerror = xdr.ontimeout = $.noop;
                            xdr = undefined;

                            complete(status, statusText, responses, responseHeaders);
                        }

                        xdr = new XDomainRequest();
                        xdr.onload = function () {
                            callback(200, "OK", {text: xdr.responseText}, "Content-Type: " + xdr.contentType);
                        };
                        xdr.onerror = function () {
                            callback(404, "Not Found");
                        };
                        xdr.onprogress = $.noop;
                        xdr.ontimeout = function () {
                            callback(0, "timeout");
                        };
                        xdr.timeout = s.xdrTimeout || Number.MAX_VALUE;
                        xdr.open(s.type, s.url);
                        xdr.send((s.hasContent && s.data) || null);
                    },
                    abort: function () {
                        if (xdr) {
                            xdr.onerror = $.noop;
                            xdr.abort();
                        }
                    }
                };
            }
        });
    }

    $('a.scrollto').click(function (e) {
        e.preventDefault();

        var anchorName = $(this).attr('href').substring(1);
        var $scrollToAnchor = $('[name=' + anchorName + '],[id=' + anchorName + ']');
        var nav = $('.top-nav');
        var navHeight = nav ? nav.height() : 0;
        $('html, body').animate({
            scrollTop: $scrollToAnchor.offset().top - navHeight
        }, 500);

        return false;
    });

})($);


(function ($, t) {
    // $('input:radio:checked').parent().button('toggle');


    t.controllers.define(
        'password-entry',
        {
            'click .toggle-password-visibility': 'toggleVisible'
        },
        {
            'init:after': function () {
                this.icon = $(".toggle-password-visibility");
                this.passwordField = this.findWithinDOM(".password-field");
            },
            toggleVisible: function () {
                const EYE = 0xe994;
                const STRIKE_THROUGH = 0xe993;

                this.icon.toggleClass('currently-hidden currently-visible');
                this.icon.html() === String.fromCodePoint(EYE) ? this.icon.html(String.fromCodePoint(STRIKE_THROUGH)) : this.icon.html(String.fromCodePoint(EYE));
                this.passwordField.attr('type') === 'password' ? this.passwordField.attr('type', 'text') : this.passwordField.attr('type', 'password');
            }
        }
    );


    t.controllers.define(
        'change-password',
        {
            'click .change-password': 'toggleVisibility'
        },
        {
            toggleVisibility(e) {
                if (e.target.classList.contains('toggle-visibility')) {
                    const eyeIcon = 0xe994;
                    const strikeThroughIcon = 0xe993;
                    const button = e.target;
                    const inputParent = button.previousElementSibling;
                    const input = inputParent.querySelector('.form-control');
                    button.innerText === String.fromCodePoint(eyeIcon)
                        ? button.innerText = String.fromCodePoint(strikeThroughIcon)
                        : button.innerText = String.fromCodePoint(eyeIcon);
                    input.getAttribute('type') === 'password'
                        ? input.setAttribute('type', 'text')

                        : input.setAttribute('type', 'password');
                }
            }
        }
    );


    t.controllers.define(
        'nav',
        {
            'click .nav-toggle': 'onNavToggleClick',
            'click .nav-close': 'onNavCloseClick',
        },
        {
            'init:after': function () {
                this.$navMenu = this.findWithinDOM('.nav-menu');
                this.$getStartedLink = this.findWithinDOM(".nav-get-started a");

                this._boundClickHandler = t.util.bind(this.onClickHideMenu, this);

                $(window).scroll(t.util.bind(this.onScroll, this));
                $(document).on('touchmove', t.util.bind(this.onScroll, this));

                $('.get-started-waypoint').waypoint(t.util.bind(this._accentGetStartedLink, this));
            },
            _accentGetStartedLink: function (direction) {
                if (direction === 'down') {
                    this.$getStartedLink.addClass('accent');
                } else {
                    this.$getStartedLink.removeClass('accent');
                }
            },
            onNavToggleClick: function (e) {
                e.preventDefault();
                this.show();
                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: 'button',
                    eventAction: 'click',
                    eventLabel: 'old hamburger'
                });
            },
            onNavCloseClick: function (e) {
                e.preventDefault();
                this.hide();
            },
            onScroll: function (e) {
                // temporarily disable while we have the upgrade banner in place
                if ($(document).scrollTop() > 0) {
                    if (!this.$el.hasClass('nav-shrunk')) {
                        this.$el.addClass('nav-shrunk');
                    }
                } else {
                    this.$el.removeClass('nav-shrunk');
                }
            },
            show: function () {
                $(document).on('click', this._boundClickHandler);
                this.$navMenu.addClass('nav-menu-show');
            },
            hide: function () {
                $(document).off('click', this._boundClickHandler);
                this.$navMenu.removeClass('nav-menu-show');
                var currentPosition = $('body').position();
                $(window).scrollTop(currentPosition.top * -1);
            },
            onClickHideMenu: function (e) {
                if ($(e.target).closest(this.$el).length === 0) {
                    e.preventDefault();
                    this.hide();
                }
            },
        }
    );

    t.controllers.define(
        'carousel',
        {
            'init:after': function () {
                $(document).ready(t.util.bind(this._startCarousel, this));
            },
            _startCarousel: function () {
                this.$el.addClass('carousel-run');
            }
        }
    );

    t.controllers.define(
        'blend-details',
        {
            'click a.show-detail': 'onShowDetailClick',
            'click a.hide-detail': 'onHideDetailClick'
        },
        {
            onShowDetailClick: function (e) {
                $(e.target).addClass('hide');
                this.$el.find('.blend-detail').removeClass('hide').hide().slideDown('fast');

                e.preventDefault();
            },
            onHideDetailClick: function (e) {
                this.findWithinDOM('a.show-detail').removeClass('hide');
                this.$el.find('.blend-detail').slideUp();

                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'fieldset',
        {
            'change *': 'onChange'
        },
        {
            'init:after': function () {
                this.$fieldset = this.$el;
            },
            _renderErrorMessage: function (message) {
                this.$fieldset.addClass('has-error').findShallowest('.help-block').first().append('<p>' + message + '</p>');
            },
            _clearErrorMessage: function () {
                this.$fieldset.removeClass('has-error').find('.help-block').first().html('');
            },
            onChange: function () {
                this._clearErrorMessage();
            }
        }
    );

    t.controllers.define(
        '.address-lookup-fuzzy',
        {
            'click #toggle-mode': 'toggleMode',
            'click .edit': 'toggleEdit'
        },
        {
            'init:after': function () {
                this.mode = this.$el.attr('data-mode');
                this.search = this.$el.find('#search');
                if (this.mode === 'auto') {
                    this.setupLoqate();
                }
            },
            setupLoqate: function () {
                const fields = [
                    {element: "search", field: "", mode: pca.fieldMode.SEARCH},
                    {element: "company", field: "Company", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                    {element: "line1", field: "Line1"},
                    {element: "line2", field: "Line2", mode: pca.fieldMode.POPULATE},
                    {element: "line3", field: "Line2", mode: pca.fieldMode.POPULATE},
                    {element: "city", field: "City", mode: pca.fieldMode.POPULATE},
                    {element: "postcode", field: "PostalCode"},
                ];

                let options = {
                    key: this.$el.attr('data-key'),
                    search: {},
                    culture: this.$el.attr('data-locale')
                };
                if (this.$el.attr('data-country')) {
                    options['search']['countries'] = this.$el.attr('data-country');
                }

                this.control = new pca.Address(fields, options);
                this.control.listen("populate", (address, variations) => {
                    if (address['Id']) {
                        this.$el.find('#uaid').val(address['Id']);
                    }
                    this.$el.attr("data-state", 'populated');
                    this.populateSummary(address);
                });
            },

            populateSummary: function (address) {
                const summary = this.$el.find('.summary');
                summary.find('.first-line').text(address['FormattedLine1']);
                summary.find('.city').text(address['City']);
                summary.find('.postcode').text(address['PostalCode']);
            },

            toggleEdit: function (e) {
                this.$el.attr("data-state", 'init');
            },

            toggleMode: function (e) {
                this.mode = this.mode === 'auto' ? 'manual' : 'auto';
                this.$el.attr("data-mode", this.mode);
                if (this.mode === 'auto') {
                    this.search.prop('disabled', false);
                    if (!this.control) {
                        this.setupLoqate();
                    } else {
                        this.control.load();
                    }
                } else {
                    this.control.destroy();
                    this.$el.find('#uaid').val('');
                    this.search.prop('disabled', true);
                }
            }
        }
    );

    t.controllers.define(
        'fieldset.address-lookup',
        {
            'click a.postcode-lookup': 'onButtonClick',
            'click a.not-listed': 'onNotListedClick',
            'change .address-picker select': 'onSelectChange',
            'click a.change-address': 'onChangeClick',
            'keypress #search-postcode': 'onPostcodeKeypress',
            'keypress #search-postcode-redesign': 'onPostcodeKeypress',
            'keypress #search-street': 'onPostcodeKeypress',
            'blur #address-postal_code': 'onPostCodeBlur',
            'keypress #manual-address-input input': 'onManualAddressKeypress'
        },
        {
            'init:after': function () {
                this.$addressPicker = this.$el.find('.address-picker');
                this.$addressInput = this.$el.find('.address-input');
                this.postcode_search = this.$el.find('#search-postcode');
                if (this.postcode_search.length === 0) {
                    this.postcode_search = this.$el.find('#search-postcode-redesign');
                }
                this.postCodeField = this.$el.find('#address-postal_code');
                this.country = this.$el.find('#country');
                // this.assignPlaceHolder();
            },
            onButtonClick: function (e) {
                e.preventDefault();
                var postcode = this.postcode_search.val();
                var country = this.country.val();
                this.lookupPostcode(postcode, country);
            },
            onPostcodeKeypress: function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    var postcode = this.postcode_search.val();
                    var country = this.country.val();
                    this.lookupPostcode(postcode, country);
                }
            },
            onManualAddressKeypress: function (e) {
                this.$addressInput.find('input[name=address-uaid]').val("");
            },
            onPostCodeBlur() {
                this.postCodeField[0].value = this.postCodeField[0].value.trim();
            },
            lookupPostcode: function (postcode, country) {
                if (postcode.trim() === '') {
                    return
                }

                this.$el.attr("data-state", "searching");
                $.post(
                    this.$el.data("search-url"),
                    {
                        'postcode': postcode,
                        'country': country,
                        'for_delivery': this.$el.attr('data-mode') === 'delivery'
                    },
                    t.util.bind(function (result) {
                        if (result['error']) {
                            this.$el.attr("data-state", 'invalid-postcode');
                            this.onNotListedClick({
                                preventDefault: () => {
                                }
                            });
                        } else {
                            this.updateAddressSelect(postcode, result['addresses']);
                        }
                    }, this)
                )
            },
            updateAddressSelect: function (postcode, addresses) {
                const $select = this.$addressPicker.find('select');
                const trans_select_from_list = this.$addressPicker.data('trans-select-option');

                $select.html('');
                $select.append(`<option value="">- ${trans_select_from_list} -</option>`);
                $.each(addresses, t.util.bind(function (index, item) {
                    let text = item['text'].replace(/^\s*(?:[A-Z]{1,2}[0-9][0-9A-Z]?\s*[0-9][A-Z]{2}, )?(?:(\d+[a-z]?),)?/i, '$1');
                    $select.append('<option value="' + item['id'] + '" data-postcode="' + item['postcode'] + '">' + text + '</option>');
                }, this));
                this.$el.attr("data-state", "pick-address");
            },
            onSelectChange: function (e) {
                let selectedAddressId = $(e.target).val();
                let postcode = $(e.target).data('postcode');
                this.$addressInput.find('input[name=address-uaid]').val(selectedAddressId);
                $('.btn-next').prop("disabled", false);

                t.message.publish(
                    'postcode', 'changed', {
                        address_id: selectedAddressId,
                        postcode: postcode
                    });
            },
            onChangeClick: function (e) {
                e.preventDefault();

                this.clearForm();
                this.$el.attr("data-state", "postcode-search");
                this.postcode_search.focus();
            },
            onNotListedClick: function (e) {
                this.clearForm();

                this.$el.attr("data-state", "long-form");
                this.$addressInput.removeClass('hide');

                const postcode = this.postcode_search.val().toUpperCase();
                // Empty the search postcode field so we don't validate the address list
                this.postcode_search.val(null);
                this.$addressInput.find('input[name=address-postal_code]').val(postcode);

                t.message.publish(
                    'postcode', 'changed', {
                        postcode: postcode
                    }
                );

                //if there were old validation errors - clear them out
                this.$addressInput.find('.help-block').empty();
                this.$addressInput.find('.form-group').removeClass('has-error');
                this.$addressInput.find('input[name=address-first_line]').focus();

                e.preventDefault();
            },
            clearForm: function () {
                this.$addressInput.find('input').val('');
            }
        }
    );

    t.controllers.define(
        'delivery-instructions',
        {
            'change select': 'onSelectChange'
        },
        {
            'init:after': function () {
                this.$otherField = this.findWithinDOM('.delivery-instruction-other');
                this.$select = this.findWithinDOM('select');

                this.changed();
            },
            onSelectChange: function (e) {
                this.changed();
            },
            changed: function () {
                this.findWithinDOM('.delivery-instruction-advice').slideUp('fast');

                if (this.$select.val() === 'other') {
                    this.findWithinDOM('.delivery-instruction-advice-other').removeClass('hide').slideDown('fast');
                    this.$otherField.removeClass('hide').hide().slideDown('fast');
                    this.$otherField.focus();
                } else {
                    this.findWithinDOM('.delivery-instruction-advice-' + this.$select.children('option:selected').data('identifier')).removeClass('hide').slideDown('fast');

                    this.$otherField.attr('disabled', 'disabled').slideUp('fast');
                }
            }
        }
    );

    t.controllers.define(
        'add-scoop-wrap',
        {
            'submit form': 'handleSubmit',
            'click i.js-close-popup': 'closePopup'
        },
        {
            'init:after': function () {
                this.form = $(this.$el).find('form');
            },

            'handleSubmit': function (e) {
                e.preventDefault();
                var form = this.form;
                form.find('.loader-shroud').addClass('show');

                $.ajax({
                    url: form.attr('action'),
                    type: 'POST',
                    data: form.serialize(),
                    success: function () {
                        location.reload();
                    }
                })

            },

            'closePopup': function () {
                t.message.publish('auto-popup', 'hide')
            }
        }
    );

    t.controllers.define(
        'remove-scoop-form',
        {
            'submit': 'handleSubmit'
        },
        {
            'init:after': function () {
                this.form = $(this.$el);
            },

            'handleSubmit': function (e) {
                e.preventDefault();
                var form = this.form;
                var subscription_id = $(form.find('input')).val();
                form.find('.loader-shroud').addClass('show');


                $.ajax({
                    url: form.attr('action'),
                    type: 'POST',
                    data: form.serialize(),
                    success: function () {
                        location.reload();

                    }
                })

            }
        }
    );

    t.controllers.define(
        'email-entry',
        {
            'keyup #email': "onEmailChange",
            'input #email': "validateEmailField",
            'change #email': "validateCommsSelect",
            'change #opt_in-0': "validateCommsSelect",
            'change #opt_in-1': "validateCommsSelect",
            'click #suggested-email': 'onSuggestedEmailClick'
        },
        {
            "init:after": function () {
                this.wrapper = this.$el.find('.email-section');
                this.checkoutEmailSuggestion = this.$el.find('#checkout-email-suggestion');
                this.input = this.$el.find("#email");
                this.to = null;
                this.email_suggestion = this.$el.find("#suggested-email");
                this.email = this.input.val();
                this.validateEmailField();
            },
            setState: function (state) {
                this.wrapper.attr("data-state", state);
                this.showCheckoutEmailSuggestion(state);
            },
            showCheckoutEmailSuggestion: function (state) {
                if (state === "has-suggestion") {
                    this.checkoutEmailSuggestion.removeClass('hidden');
                } else if (state === "valid") {
                    this.checkoutEmailSuggestion.addClass("hidden");
                }
            },
            getEmail: function () {
                return this.input.val().trim();
            },
            onEmailChange: function (e) {
                if (this.to) {
                    clearTimeout(this.to);
                }
                this.to = setTimeout(t.util.bind(function () {
                    this.validateEmailField();
                }, this), 300);
            },
            validateEmailField: function () {
                var email = this.getEmail();
                if (email && this.email === email) {
                    return;
                }
                this.email_valid = false;
                this.$el.find("button[type=submit]").prop('disabled', true);

                this.email = email;
                if (this.regexValidation(email)) {
                    this.email_valid = true;
                    this.validateForm();
                    this.mailcheckValidation(email).done(t.util.bind(function (suggestion) {
                        this.showSuggestion(suggestion);
                    }, this));
                } else {
                    this.setState('default');
                }
            },
            validateCommsSelect: function () {
                this.opt_selected = false;
                if (this.$el.find('input[name=opt_in]:radio:checked').length > 0) {
                    this.opt_selected = true;
                    this.validateForm();
                }
            },
            validateForm: function () {
                if (this.opt_selected && this.email_valid) {
                    this.$el.find("button[type=submit]").prop('disabled', false);
                }
                    // Registration page variant where comms selection radio btns are missing
                // TODO: Remove logic for other variants once final variant has been chosen
                else if (this.email_valid && (this.$el.find('input:radio').length == 0)) {
                    this.$el.find("button[type=submit]").prop('disabled', false);
                }
            },
            showSuggestion: function (suggestion) {
                if (suggestion) {
                    this.setState('has-suggestion');
                    this.email_suggestion.text(suggestion);
                } else {
                    this.setState('valid');
                }
            },
            regexValidation: function (email) {
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            },
            mailcheckValidation: function (email) {
                var def = $.Deferred();
                Mailcheck.run({
                    email: email,
                    suggested: function (suggestion) {
                        def.resolve(suggestion['full']);
                    },
                    empty: function () {
                        def.resolve();
                    }
                });
                return def
            },
            onSuggestedEmailClick: function (e) {
                e.preventDefault();
                this.input.val($(e.currentTarget).text());
                this.validateEmailField();
            }
        }
    );

    t.controllers.define(
        'delivery-dates',
        {},
        {
            'init:after': function () {

                t.message.subscribe('courier', 'changed', t.util.bind(this.courierChanged, this));
                this.delivery_windows = this.$el.find('.delivery-window')
            },
            courierChanged: function (data) {
                var courier = data['data']['courier'];
                var delivery_window = this.delivery_windows.filter('.' + courier);
                delivery_window.removeClass("hide");
                delivery_window.siblings().addClass("hide");
            }
        }
    );

    t.controllers.define(
        'delivery-services',
        {
            'click .expand-trigger': 'onExpandClick',
            'keydown .expand-trigger': 'onExpandClick',
            'change input:radio': 'onDeliveryServiceChange',
            'keydown .delivery-service-radio-label': 'clickOnEnterKey'
        },

        {
            'init:after': function () {
                var courier = this.$el.find('input:radio:checked').data('courier');
                if (courier) {
                    this.setDeliveryInstructionsCopyForCourier(courier);
                }

                this.services = this.$el.find(".available-services");

                t.message.subscribe('postcode', 'changed', t.util.bind(this.postcodeChanged, this));
            },
            clickOnEnterKey: function (event) {
                if (event.key === 'Enter') {
                    event.target.click();
                }
            },

            onExpandClick: function (event) {
                if (event.key && event.key !== 'Enter') {
                    return;
                }

                var trigger = $(event.target);
                var li = trigger.parent('li');
                var viewMore = li.next('.feature-row');

                if (viewMore.hasClass('visible')) {
                    viewMore.stop().slideUp();
                } else {
                    viewMore.stop().slideDown();
                }

                trigger.toggleClass('flip');
                viewMore.toggleClass('visible');

                return false;
            },
            onDeliveryServiceChange: function (event) {
                var radio = $(event.target);

                if (radio.prop('checked')) {
                    var courier = radio.data('courier');
                    if (courier) {
                        this.setDeliveryInstructionsCopyForCourier(courier);
                    }
                    t.message.publish('courier', 'changed', {
                        courier: courier
                    });
                }
            },
            setDeliveryInstructionsCopyForCourier: function (courier) {
                const $deliveryInstructions = $('.delivery-instructions-info');
                const firstOption = $('select[name="address-delivery_instruction_id"] option, select[name="delivery_instruction_id"] option').first();

                $deliveryInstructions.hide();

                if (courier === 'yodel') {
                    const $yodelInstructions = $deliveryInstructions.filter('.yodel');
                    const yodelOptionText = $yodelInstructions.data('trans-yodel-option');

                    $yodelInstructions.show();
                    firstOption.text(`- ${yodelOptionText} -`);
                } else {
                    const $dpdInstructions = $deliveryInstructions.filter('.dpd');
                    const dpdOptionText = $dpdInstructions.data('trans-dpd-option');

                    $dpdInstructions.show();
                    firstOption.text(dpdOptionText);
                }
            },
            postcodeChanged: function (data) {
                const newAddressUAID = data['data']['address_id'];
                const newAddressPostcode = data['data']['postcode'];
                var that = this;
                this.showServicesLoader();

                $.ajax({
                    type: "GET",
                    url: this.options.getUrl,
                    data: {
                        "postcode": newAddressPostcode,
                        "address_id": newAddressUAID
                    },
                    success: function (data, text) {
                        var html = $.parseHTML(data);
                        var new_services = $(html).find(".available-services");
                        new_services.appendTo(that.services.empty());
                        that.hideServicesLoader();
                    },
                    error: function (request, status, error) {
                        that.services.empty()
                        that.services.html(document.querySelector('#delivery-services-notification-text').outerHTML);
                        that.hideServicesLoader();
                    }
                });

            },
            showServicesLoader: function () {
                this.$el.attr("data-state", "searching");
                this.$el.find('#delivery-services-content').addClass("hidden");
                document.querySelector('#next_btn').disabled = true;
            },
            hideServicesLoader: function () {
                this.$el.attr("data-state", "done");
                this.$el.find('#delivery-services-content').removeClass("hidden");
                document.querySelector('#next_btn').disabled = false;
            }
        }
    );

    function IncrementDecrementCounter(element, callback) {
        this.element = $(element);
        this.input = this.element.find('input');
        this.options = {
            min: null,
            max: null
        };

        $.extend(this.options, this.element.data());

        this.increment = function () {
            this.setValue(this.getValue() + 1);
        };

        this.decrement = function () {
            this.setValue(this.getValue() - 1);
        };

        this.setValue = function (value) {
            if (this.validateValue(value)) {
                var oldValue = this.getValue();

                var parsed = parseInt(value);
                this.input.val(parsed);

                $(this).trigger('change', [parsed, oldValue]);
                if (callback) {
                    callback(parsed);
                }
            } else {
                $(this).trigger('invalid', [value]);
            }
        };

        this.validateValue = function (value) {
            if (isNaN(parseInt(value))) {
                return false;
            } else if (this.options.min !== null && value < this.options.min) {
                return false;
            } else if (this.options.max !== null && value > this.options.max) {
                return false;
            }

            return true;
        };

        this.getValue = function () {
            var value = parseInt(this.input.val());
            return isNaN(value) ? 0 : value;
        };

        var scope = this;
        this.element.find('.btn.plus').click(function () {
            scope.increment();
        });

        this.element.find('.btn.minus').click(function () {
            scope.decrement();
        });

        this.input.change(function () {
            var value = scope.getValue();

            if (!scope.validateValue(value)) {
                value = 0;
            }

            scope.setValue(value);
        });

        this.element.data('counter', this);
    }

    t.controllers.define(
        'single-product-widget',
        {
            'click .btn.set-qty': "onAddQuantityClick",
            'click .btn.save': "onSaveClick",
            'click .close': "onCloseClick",
            'click .btn.save-next': "onSaveNextClick",
            'click .size': "changeSizeClick",
            'click .size-selection[data-product-id]': "selectSizes",
        },
        {
            'init:after': function () {
                this.xhr = null;
                this.add_url = this.$el.data("addUrl");
                this.redirect_url = this.$el.data("redirectUrl");
                this.product_type = this.$el.data("productType");
                this.pet_name = this.$el.data("petName");
                this.save_button = this.$el.find(".btn.save");
                this.pet_id = this.$el.data("petId");
                this.counter = null;
                this.counter_elem = this.$el.find(".counter");

                if (this.counter_elem) {
                    this.initCounter();
                }
            },
            changeSizeClick(e) {
                const variant_id = e.target.dataset.variant;
                document
                    .getElementsByClassName('variant')
                    .forEach(v => v.classList.add('hide'));
                document
                    .getElementById(`variant-${variant_id}`)
                    .classList
                    .remove('hide');

                document.querySelectorAll('[data-fader="true"]')
                    .forEach(elem => elem.style.visibility = 'hidden');
                document.querySelectorAll('[data-size-popup="true"]')
                    .forEach(elem => {
                        if (elem) {
                            elem.style.visibility = 'hidden';
                            elem.style.maxHeight = 0;
                        }
                    });

                // Stop scrolling in the backdrop
                document.body.style.overflowY = 'initial';
                document.querySelector('.footer').style.zIndex = 'initial';
            },
            selectSizes(e) {
                let target = e.target;
                // child elements are capturing click event
                if (target.dataset.productId === undefined) {
                    target = $(target).closest('[data-product-id]')[0];
                }
                const sizePopup = document.querySelector(`.size-popup-${target.dataset.productId}`);
                if (sizePopup) {
                    sizePopup.style.visibility = 'visible';
                    sizePopup.style.maxHeight = '400px';
                }
                const fader = document.querySelector(`.fader-${target.dataset.productId}`);
                if (fader) {
                    fader.style.visibility = 'visible';
                }
                document.body.style.overflowY = 'hidden';
                document.querySelector('.footer').style.zIndex = -10;
            },

            initCounter() {
                this.counter = new IncrementDecrementCounter(
                    this.counter_elem,
                    t.util.bind(this.onCounterChange, this)
                );
            },
            onAddQuantityClick(e) {
                e.preventDefault();
                var target = $(e.currentTarget);
                var quantity = parseInt(target.data('qty'));

                this.updateQuantity(quantity);
            },
            onCounterChange(quantity) {
                this.$el.attr("data-taken", parseInt(quantity) !== 0);
            },
            updateQuantity(quantity) {
                this.counter.setValue(quantity);
                this.postQuantity(quantity, false);
            },
            onSaveClick(e) {
                this.save(false);
            },
            onSaveNextClick() {
                this.save(true);
            },
            save(nextOrder) {
                var quantity = this.counter.getValue();
                this.postQuantity(quantity, nextOrder);
                var status = quantity > 0 ? "taken" : "not_taken";
                var plural_string = quantity > 1 ? "s" : "";
                t.message.publish('upsell-' + this.pet_id, status, {
                    'product_type': this.product_type,
                    'quantity': quantity,
                    'plural_string': plural_string
                });

            },
            postQuantity(quantity, nextOrder) {
                this.save_button.prop("disabled", true);
                if (this.xhr === null) {
                    this.xhr = $.post(
                        this.add_url, {
                            quantity: quantity,
                            next_delivery_only: nextOrder
                        },
                        t.util.bind(this.postSuccess, this)
                    );
                }
            },
            postSuccess(data) {
                this.quantityUpdated(data['quantity']);
                this.xhr = null;
                if (this.redirect_url !== undefined) {
                    window.location.href = this.redirect_url;
                }
                this.save_button.prop("disabled", false);
            },
            quantityUpdated(quantity) {
                t.message.publish(this.product_type, 'changed');
                t.message.publish('auto-popup', 'hide');
                t.message.publish('price-change', 'changed');
            },
            onCloseClick() {
                t.message.publish('auto-popup', 'hide');
            },
        }
    );

    t.controllers.define(
        'wet-trial-upsell-modal',
        {
            'click .btn.add': 'onActionClick',
            'click .btn.remove': 'onActionClick'
        },
        {
            'init:after': function () {
                var widget = this.$el.children(".offer");
                var pet_id = this.$el.attr("data-pet-id");
                t.message.subscribe('upsell-widget', 'taken', t.util.bind(function (e) {
                    if (e.data.product_type === 'wet-food' && e.data.pet_id == pet_id) {
                        widget.attr("data-status", 'taken');
                    }
                }, this));
                t.message.subscribe('upsell-widget', 'not_taken', t.util.bind(function (e) {
                    if (e.data.product_type === 'wet-food' && e.data.pet_id == pet_id) {
                        widget.attr("data-status", 'not_taken');
                    }
                }, this));
            },
            'onActionClick': function (e) {
                e.preventDefault();
                var target = $(e.currentTarget);
                var url = target.data("url");
                this.$el.prop("disabled", true);
                $.post(
                    url,
                    t.util.bind(function (response) {
                        var quantity = response['quantity'];
                        var plural_string = quantity > 1 ? "s" : "";
                        var status = quantity > 0 ? "taken" : "not_taken";
                        var pet_id = response['pet_id'];
                        if (response['success'] === true) {
                            t.message.publish('upsell-widget', status, {
                                'pet_id': pet_id,
                                'product_type': 'wet-food',
                                'quantity': quantity,
                                'plural_string': plural_string
                            });
                            var product_id = response['product_id'];
                            t.message.publish('price-change', 'changed');
                            t.message.publish(product_id, 'changed');
                            t.message.publish('auto-popup', 'hide');
                            t.message.publish('upsell-' + pet_id, status, {
                                'product_type': 'wet-food',
                                'quantity': quantity,
                                'plural_string': plural_string
                            });
                            this.$el.prop("disabled", false);
                        }
                    }, this)
                )
            }
        }
    );


    t.controllers.define(
        'multi-dog-slider',
        {},
        {
            'init:after': function () {
                this.pets_name = this.$el.find(".pets-name");
                this.$el.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        appendDots: ".dots",
                        adaptiveHeight: true,
                        slide: '.slide',
                        arrows: true,
                        prevArrow: '.slick-arrow.left',
                        nextArrow: '.slick-arrow.right'
                    }
                ).on('beforeChange', t.util.bind(function () {
                        this.pets_name.addClass("fade");
                    }, this)
                ).on('afterChange', t.util.bind(function (e, slick, current_slide) {
                    var pets_name = $(slick.$slides[current_slide]).data("pets-name");
                    this.pets_name.text(pets_name);
                    this.pets_name.removeClass("fade");

                }, this));
            }
        }
    );


    t.controllers.define(
        'product-section',
        {
            'click .show-popup': 'showPopup',
            'click .btn.toggle': 'toggleClick',
            'click .edit-selection': 'clickedProductTile',
            'click .add': 'clickedProductTile',
        },
        {
            'init:after': function () {
                this.xhr = null;
                this.product_id = this.$el.data("product-id");
                this.refresh_url = this.$el.data("refresh-url");
                t.message.subscribe(this.product_id, 'changed', t.util.bind(function () {
                    this.update(this.refresh_url);
                }, this));
            },
            optimizelyTrackApi: function (event) {
                var params = new URLSearchParams({
                    event,
                    tags: JSON.stringify({'slug': this.product_id}),
                })
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?' + params,
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
            showPopup: function (e) {
                e.preventDefault();
                var data = $(e.currentTarget).data();
                var product_id = data["productId"];
                var pet_id = data["petId"];
                t.message.publish('auto-popup', 'show-popup-' + product_id + '-' + pet_id);
            },
            toggleClick: function (e) {
                e.preventDefault();
                var target = $(e.currentTarget);
                var url = target.attr("href");
                this.update(url);
            },
            clickedProductTile: function () {
                this.optimizelyTrackApi('clicked_product_tile')
            },
            update: function (url) {
                if (this.xhr) {
                    this.xhr.abort();
                }
                this.showLoader();
                this.xhr = $.get(
                    url,
                    t.util.bind(this.getUpdateSuccess, this)
                )
            },
            getUpdateSuccess: function (data, status, response) {
                delete this.xhr;
                var content_type = response.getResponseHeader("content-type") || "";
                if (content_type.indexOf('html') > -1) {
                    var html = $.parseHTML(data);
                    var selector = '.section[data-product-id=' + this.product_id + ']';
                    var new_html = $(html).find(selector);
                    this.$el.html(new_html.html());
                    $.each(new_html[0].attributes, t.util.bind(function (i, attribute) {
                        this.$el.attr(attribute.name, attribute.value);
                    }, this));
                } else if (content_type === 'application/json') {
                    if (data['redirect_url']) {
                        window.location = data['redirect_url'];
                    }
                }
            },
            showLoader: function () {
                this.$el.find('.loader-shroud').addClass("show");
            }
        }
    );

    t.controllers.define(
        'multi-product-selector-nav',
        {
            'click .close': "onCloseClick",
            'click .select-product': "onProductSelect"
        },
        {
            'init:after': function () {
                this.xhr = null;
                this.add_url = this.$el.data("addUrl");
                this.product_type = this.$el.data("productType");
            },
            onCloseClick: function () {
                t.message.publish('auto-popup', 'hide');
            },
            onProductSelect: function (e) {
                e.preventDefault();
                var data = $(e.currentTarget).data();
                var render_url = data["renderUrl"];
                var pet_id = data["petId"];
                t.message.publish('auto-popup', 'show-popup-dental-dailies' + '-' + pet_id, {
                    "render_url": render_url
                });
            }
        }
    );

    t.controllers.define(
        'product-image-wrapper',
        {},
        {
            'init:after': function () {
                const mainImage = this.$el.find('.product-image');
                this.$el.find('.secondary-product-image').each(function () {
                    $(this).click(function () {
                        // Swap selected image
                        const secondaryImage = $(this);
                        mainImage.css('background-image', `url(${secondaryImage.attr('src')})`);

                        // Toggle active highlight
                        $('.active').removeClass('active');
                        secondaryImage.addClass('active');
                    })
                });
            },
        }
    );

    t.controllers.define(
        'auto-popup',
        {},
        {
            'init:after': function () {
                this.render_url = this.$el.data("renderUrl");
                this.auto_show = this.$el.data('autoShow');
                this.show_event = this.$el.data('showEvent');
                this.window_class = this.$el.data('window-class');

                if (this.auto_show === true) {
                    this.show();
                }
                if (this.show_event) {
                    this.subscription = t.message.subscribe('auto-popup', this.show_event, t.util.bind(this.show, this));
                }
                t.message.subscribe('auto-popup', 'hide', t.util.bind(this.hide, this))
            },
            show: function (event_data) {
                if (event_data && event_data['data'] && event_data['data']['render_url']) {
                    this.render_url = event_data['data']['render_url'];
                }
                $.get(
                    this.render_url,
                    t.util.bind(function (html) {
                        html = $($.parseHTML(html));
                        t.ui.alert.show(
                            '',
                            '',
                            '',
                            [],
                            t.util.bind(function (button) {
                                return false;
                            }, this)
                        );

                        if (this.window_class) {
                            t.ui.alert.window().addClass(this.window_class);
                        }
                        t.controllers.init(html);
                        t.ui.alert.window(html);
                    }, this)
                );
            },
            onCloseClick: function (e) {
                t.ui.alert.hide();
            },
            hide: function () {
                t.ui.alert.hide();
            }
        }
    );

    t.controllers.define(
        'shop-banner',
        {
            'click .show-popup': 'showPopup',
        },
        {
            'init:after': function () {

            },
            showPopup: function (e) {
                const data = $(e.currentTarget).data();
                const product_id = data["productId"];
                const pet_id = data["petId"];
                t.message.publish('auto-popup', `show-popup-${product_id}-${pet_id}`);
            }
        }
    );

    t.controllers.define(
        'preorder-summary',
        {
            'click .rm-preorder': 'removePreorder',
        },
        {
            removePreorder(e) {
                e.preventDefault();
                const {url, name, id} = e.currentTarget.dataset;

                t.ui.alert.show(
                    '',
                    '',
                    'Are you sure you want to remove this item?',
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        t.ui.alert.BUTTON_OK
                    ],
                    t.util.bind(function (button) {
                        if (button === t.ui.alert.BUTTON_OK) {
                            this.removePreorderPost(url);
                        }
                        return true;
                    }, this)
                );
            },
            removePreorderPost(url) {
                $.post(
                    url,
                    {
                        'preorder': true,
                    },
                    t.util.bind((res) => {
                        if (res.success === true) {
                            window.location.reload();
                        }
                    }),
                );
            },
        }
    );

    t.controllers.define(
        'payment-summary',
        {
            'click .rm-wetfood': 'removeWetFood',
            'click .rm-treats': 'removeTreats',
            'change .trial-order-radio': 'showTrialTab',
            'change .ongoing-order-radio': 'showOngoingTab',
            'click #show-first': 'onTrialClick',
            'click #show-next': 'onOngoingClick',
            'click .rm-oneoff': 'removeOneOff',
            'click .plusminus-oneoff.minus': 'plusMinus',
            'click .plusminus-oneoff.plus': 'plusMinus',
            'click .update-oneoff-quantity': 'updateOneoffQuantity',
        },
        {
            'init:after': function () {
                this.refresh_url = this.$el.data('refresh-url');
                this.to_replace = this.$el.data('replace');
                this.savings_banner_url = this.$el.find('#savings-banner-message-url-src').attr('data-savings-banner-message-url');
                this.discount_limit_notification_url = this.$el.find('#discount-limit-notification-url-src').attr('data-discount-limit-notification-url');
                this.updateButtonElementClass = "button.btn.btn-primary.update-oneoff-quantity";
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.refresh, this));
                t.message.subscribe('price-change', 'changed', t.util.bind(this.refresh, this));
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.showTrialPricingTable, this));
                t.message.subscribe('price-change', 'changed', t.util.bind(this.showTrialPricingTable, this));
                this.displayDiscountLimitNotification();
                this.displaySavingsBanner();
                this.setPriceMessageHTML();
                this.showTrialTab();
                this.showTrialPricingTable();
            },
            showPromoDiscount() {
                const promoDiscountNotification = $('#promotion-discount-notification')
                const pricingHeaderBackground = $('.background-header-design');
                const container = $('.container');

                if (promoDiscountNotification.length) {
                    container.css({'padding-top': '16px'});

                    if (pricingHeaderBackground.length) {
                        promoDiscountNotification.attr('style', 'display: inline-block;');
                        pricingHeaderBackground.css({'height': '276px'});
                    } else {
                        promoDiscountNotification.attr('style', 'display: block;');
                    }
                }
            },
            optimizelyTrackApi: function (event) {
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=' + event,
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
            onTrialClick: function () {
                console.log('in onTrialClick')
                this.showTrialPricingTable('block')
            },
            onOngoingClick: function () {
                this.optimizelyTrackApi('clicked_ongoing_tab');
                console.log('in onOngoingClick')
                this.showOngoingPricingTable('block')
            },
            setPriceMessageHTML: function () {
                let priceMessageSource = $('.ongoing-subscription-hidden');
                let priceMessage = $('.ongoing-price-message');
                let trialDeliveryDisclaimer = $('.trial-delivery-disclaimer');
                let ongoingDeliveryDisclaimer = $('.delivery-disclaimer');

                if (priceMessageSource[0]) {
                    priceMessage[0].innerHTML = priceMessageSource[0].innerHTML;
                }
                if (trialDeliveryDisclaimer[0]) {
                    ongoingDeliveryDisclaimer[0].innerHTML = trialDeliveryDisclaimer[0].innerHTML;
                }
            },
            toggleTrialMessage(toggle) {
                ['.ongoing-price-message', '.price-page-separator-ongoing', '.trial-delivery-disclaimer'].forEach(
                    classname => $(classname).toggleClass('hidden', !toggle));
                $('.delivery-disclaimer').toggleClass('hidden', toggle);
            },
            showTrialTab() {
                let trialRadio = document.querySelector('.trial-order-radio');
                if (trialRadio) {
                    trialRadio.checked = true;
                    this.toggleTrialMessage(true);
                }
            },
            showTrialPricingTable(display = 'block') {
                let trialTable = document.querySelector('.trial-price-table');
                if (trialTable) {
                    trialTable.style.display = display
                }

                if (display === 'block') {
                    this.showOngoingPricingTable('none')
                }
            },
            showOngoingPricingTable(display = 'block') {
                let ongoingTable = document.querySelector('.next-price-table');
                if (ongoingTable) {
                    ongoingTable.style.display = display
                }

                if (display === 'block') {
                    this.showTrialPricingTable('none')
                }
            },
            showOngoingTab() {
                this.toggleTrialMessage(false);
            },
            removeWetFood(e) {
                e.preventDefault();
                this.optimizelyTrackApi('clicked_remove_link');
                const {url, petName} = e.currentTarget.dataset;
                let content = this.$el.find('.templates .remove-modal').html();
                content = content.replace(new RegExp('{pet_name}', 'g'), petName);
                content = content.replace(new RegExp('{wet_id}', 'g'), 'wet-other');
                content = content.replace(new RegExp('{dry_id}', 'g'), 'dry-only');

                t.ui.alert.show(
                    '',
                    '',
                    content,
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        t.ui.alert.BUTTON_OK
                    ],
                    t.util.bind(function (button) {
                        const option = t.ui.alert.content().find('input:checked').val();
                        if (button === t.ui.alert.BUTTON_OK) {
                            this.removeWetFoodPost(url, option === 'dry-only');
                        }
                        return true;
                    }, this)
                );
                t.ui.alert.window().addClass('remove-wet-food-popup');
            },
            removeWetFoodPost(url, scale_dry) {
                this.$el.find('.loader-shroud').addClass('show');
                $.post(
                    url, {
                        'scale_dry': scale_dry
                    },
                    t.util.bind((res) => {
                        const status = 'not_taken';
                        const {pet_id} = res;
                        if (res.success === true) {
                            t.message.publish('upsell-widget', status, {
                                pet_id,
                                product_type: 'wet-food',
                                quantity: 'not_taken',
                                plural_string: '',
                            });
                            t.message.publish('price-change', 'changed');
                        }
                    }),
                );
            },
            removeTreats(e) {
                e.preventDefault();
                this.optimizelyTrackApi('clicked_remove_link');
                const {url} = e.currentTarget.dataset;
                this.removeTreatsPost(url);
            },
            removeTreatsPost(url) {
                this.$el.find('.loader-shroud').addClass('show');
                $.post(
                    url,
                    t.util.bind((res) => {
                        if (res.success === true) {
                            t.message.publish('price-change', 'changed');
                        }
                    }),
                );
            },
            refresh(data) {
                this.$el.find('.loader-shroud').addClass('show');
                $.get(
                    this.refresh_url,
                    t.util.bind((html) => {
                        // Async price generation will return empty responses
                        // on price table cache miss. In that scenario, we try
                        // again after a timeout
                        if (html === '') {
                            setTimeout(() => {
                                t.message.publish('price-change', 'changed');
                            }, 5000);
                        } else {
                            this.$el.find('.loader-shroud').removeClass('show');
                            const newHtml = $($.parseHTML($.trim(html)));
                            console.log(newHtml);
                            this.$el.find(this.to_replace)
                                .replaceWith(newHtml.find(this.to_replace));
                            console.log(this.to_replace)
                            this.displayDiscountLimitNotification();
                            this.showTrialPricingTable();
                            this.displaySavingsBanner();
                            this.setPriceMessageHTML();
                            this.applyIconInfoColour();
                        }
                    }, this),
                );
            },
            removeOneOff(e) {
                e.preventDefault();
                const {url, name} = e.currentTarget.dataset;

                t.ui.alert.show(
                    '',
                    '',
                    `Are you sure you want to remove ${name}\<br\>\<br\>This item has limited stock and you may not be able to add it again`,
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        t.ui.alert.BUTTON_OK
                    ],
                    t.util.bind(function (button) {
                        if (button === t.ui.alert.BUTTON_OK) {
                            this.removePreorderPost(url);
                        }
                        return true;
                    }, this)
                );
            },
            removePreorderPost(url) {
                $.post(
                    url,
                    {
                        'preorder': true,
                    },
                    t.util.bind((res) => {
                        if (res.success === true) {
                            t.message.publish('price-change', 'changed');
                        }
                    }),
                );
            },
            plusMinus(e) {
                const action = e.currentTarget.classList[e.currentTarget.classList.length - 2];
                const classInputTarget = e.currentTarget.classList[e.currentTarget.classList.length - 1];
                const classTarget = `input.order-oneoff-quantity.${classInputTarget}`;
                const inputOrderQuantity = this.$el.find(classTarget)[0];
                const oldInputQuantity = parseInt(inputOrderQuantity.value);
                const updateButton = this.$el.find(this.updateButtonElementClass)[0];

                switch (action) {
                    case('plus'):
                        inputOrderQuantity.value = oldInputQuantity + 1;
                        break;
                    case('minus'):
                        inputOrderQuantity.value = oldInputQuantity - 1 < 0 ? 0 : oldInputQuantity - 1;
                        break;
                }
                updateButton.disabled = this.checkOneOffOrderQuantiesAreChanged() ? false : true;
            },
            checkOneOffOrderQuantiesAreChanged() {
                const changedValues = [];
                this.$el.find('input.order-oneoff-quantity').each(
                    (index, input) => {
                        changedValues.push(
                            parseInt(input.value) !== parseInt(input.dataset.originalQuantity)
                        )
                    }
                )
                return changedValues.some((changedValue) => changedValue === true);
            },
            updateOneoffQuantity(e) {
                e.preventDefault();
                const updateButton = this.$el.find(this.updateButtonElementClass)[0];
                const productQuantityInputs = this.$el.find('input.order-oneoff-quantity');
                const products_quantities_map = {};
                $.each(productQuantityInputs,
                    (index, productQuantityInput) => {
                        const newQuantiy = parseInt(productQuantityInput.value)
                        if (
                            parseInt(
                                productQuantityInput.dataset.originalQuantity
                            ) !== newQuantiy
                        ) {
                            products_quantities_map[
                                productQuantityInput.dataset.legacyProductPk
                                ] = newQuantiy;
                        }
                    }
                )
                const formData = new FormData()
                formData.append('products_quantities_map', JSON.stringify(products_quantities_map));
                formData.append('next_delivery_only', true);
                formData.append('preorder', true);
                const requestOptions = {method: 'POST', body: formData};

                fetch(updateButton.dataset.url, requestOptions).then(
                    (response) => {
                        if (response.ok === true) {
                            t.message.publish('price-change', 'changed');
                        }
                    }
                )
            },
            displayDiscountLimitNotification() {
                $.get(
                    this.discount_limit_notification_url,
                    t.util.bind(
                        (res) => {
                            if (res && res.message) {
                                const inner_content = `
                                    <div class="icon notify-icon icons-info-exclamation-circle"></div>
                                    <div class="notification-content">
                                    <p>${res.message}</p>
                                    </div>
                                `;
                                $('#promotion-discount-limit-notification').html(inner_content);
                            } else {
                                $('#promotion-discount-limit-notification').html('');
                            }
                        },
                        this
                    )
                );
            },
            displaySavingsBanner() {
                $.get(this.savings_banner_url, t.util.bind((res) => {
                    if (res && res.message) {
                        const savingsBannerHtml = `
                            <div class="notification success
                                promotion-notification box-style-notification
                                promotion-discount-notification
                                promotion-discount-notification-variant"
                               id="box-notification"
                            >
                                <div class="icon notify-icon icons-info-check-circle"></div>
                                <div class="notification-content
                                    box-notification-content">
                                    <p>${res.message}</p>
                                </div>
                            </div>
                        `;

                        $('#promotion-discount-notification').html(savingsBannerHtml);
                        this.showPromoDiscount();
                    }
                }, this));
            },
            applyIconInfoColour() {
                document.getElementsByClassName("icons-info-scope")
                    .forEach(scope => {
                        let colourCycle = 0;
                        scope.getElementsByClassName("icons-info")
                            .forEach(e => {
                                const closest_parent_scope = e.closest('.icons-info-scope');
                                if (closest_parent_scope !== scope) {
                                    return;
                                }

                                colourCycle++;
                                if (colourCycle >= 5) colourCycle = 1;
                                if (e.nextElementSibling.classList.contains("first-block")) {
                                    e.classList.add(`icons-info-colour-1`);
                                } else if (e.nextElementSibling.classList.contains("last-block")) {
                                    e.classList.add(`icons-info-colour-3`);
                                } else {
                                    e.classList.add(`icons-info-colour-${colourCycle}`);
                                }
                            });
                    });
            }
        },
    );

    t.controllers.define(
        'apple-wallet',
        {
            'click .add-to-wallet': 'addToWallet',
            'click .apple-wallet-modal-close': 'closeModal',
        },
        {
            'init:after': function () {
                this.modal = document.querySelector('.modal-full');
                this.overlay = document.querySelector('.modal-overlay');
                this.body = document.querySelector('body');
            },
            addToWallet: function (event) {
                this.optimizelyTrackApi('clicked_add_to_apple')
                this.openModal()
            },
            openModal: function () {
                this.modal.style.display = 'block';
                this.overlay.style.display = 'block';
                this.body.style.position = 'fixed';
            },
            closeModal: function () {
                this.optimizelyTrackApi('add_to_apple_close_modal')
                this.modal.style.display = 'none';
                this.overlay.style.display = 'none';
                this.body.style.position = 'revert';
            },
            optimizelyTrackApi: function (event) {
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=' + event,
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            }
        },
    );

    t.controllers.define(
        'paypal-button',
        {},
        {
            'init:after': function () {
                this.uuidStored = false;
                this.attachClientAndCheckout();
            },

            clientPromise() {
                return new Promise((resolve, reject) => {
                    let client = this.createClient();
                    if (client) {
                        resolve(client);
                    } else {
                        reject('Error creating client, please try refreshing your browser');
                    }
                })
            },
            checkoutPromise(client) {
                return new Promise((resolve, reject) => {
                    let checkout = this.createCheckout(client);
                    if (checkout) {
                        resolve(checkout);
                    } else {
                        reject('Error creating checkout, please try refreshing your browser');
                    }
                })
            },
            attachClientAndCheckout(retries = 3) {
                this.clientPromise().then(client => {
                    this.client = client;
                    this.checkoutPromise(client).then(checkout => {
                        this.checkoutCreated(checkout);
                    })
                        .catch(error => {
                            Promise.reject(error);
                        });
                })
                    .catch(error => {
                        const maxRetriesError = document.querySelector('.pp-client-error').value;
                        retries ? this.attachClientAndCheckout(retries - 1) : this.renderNotification('', maxRetriesError);
                    })
            },
            createClient() {
                let client = null;
                try {
                    client = braintree.client.create({
                        authorization: this.$el.data('paypal-client-token')
                    });
                } catch (error) {
                    return client;
                }
                return client;
            },
            createCheckout(clientInstance) {
                let checkout = null;
                try {
                    checkout = braintree.paypalCheckout.create({
                        client: clientInstance
                    });
                } catch (error) {
                    return checkout;
                }
                return checkout;
            },
            renderNotification(title, message, hide = false) {
                const notification = document.querySelector('.notification-payment');
                if (notification && notification.querySelector('.notification-title') && notification.querySelector('.notification-message')) {
                    notification.querySelector('.notification-title').innerHTML = title;
                    notification.querySelector('.notification-message').innerHTML = message;
                    notification.style.display = hide ? 'none' : 'block';
                    return notification;
                } else {
                    return;
                }
            },
            checkoutCreated: function (checkout) {
                this.checkout = checkout;
                let endpoint = this.$el.data('paypal-endpoint');
                const nextUrl = this.$el.data('paypal-next-url');

                paypal.Button.render({
                    env: this.$el.data('paypal-mode'),
                    locale: this.$el.data('paypal-locale'),
                    style: {
                        size: 'responsive',
                        color: 'gold',
                        shape: 'pill',
                        tagline: false,
                        locale: this.$el.data('paypal-locale')
                    },
                    payment: t.util.bind(function () {
                        // We will need to know later whether submitting the uuid was successful
                        const paymentOptions = document.querySelectorAll('.pricing-details-buttons [name=provider]');
                        const uuid = [...paymentOptions].find(el => el.checked).dataset.uuid;
                        const url = `/api/payment/uuid/${uuid}`;
                        fetch(url)
                            .then(response => {
                                this.uuidStored = response.status === 200
                            });

                        return checkout.createPayment({
                            flow: 'vault',
                            currency: this.$el.data('paypal-currency'),
                            billingAgreementDescription: this.$el.data('paypal-agreement'),
                            enableShippingAddress: true,
                            shippingAddressEditable: true
                        });
                    }, this),
                    onAuthorize: t.util.bind(function (data, actions) {

                        // Have we stored the uuid server-side?
                        this.progressSpinner(true);
                        if (this.uuidStored !== true) {
                            this.progressSpinner(false);
                            const errorTranslation = document.querySelector('#uuid-error-text');
                            this.renderNotification(errorTranslation.dataset.title, errorTranslation.dataset.message);
                            return;
                        }
                        this.renderNotification('', '', true);

                        return checkout.tokenizePayment(data, (err, payload) => {
                            $.ajax({
                                url: endpoint,
                                type: 'POST',
                                data: JSON.stringify(payload),
                                contentType: "application/json",
                                dataType: "json",
                                success: (result) => {
                                    if (result['success'] === true) {
                                        document.querySelector('.notification-payment').style.display = 'none';
                                        window.location = nextUrl;
                                    } else {
                                        this.progressSpinner(false);
                                        this.renderNotification('', result.error);
                                    }
                                }
                            });
                        });
                    }, this),
                    onCancel: t.util.bind(function (data) {
                        this.progressSpinner(false);
                        console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
                    }, this),
                    onError: t.util.bind(function (err) {
                        this.progressSpinner(false);
                        console.error('checkout.js error', err);
                    }, this),
                }, '#paypal-button');
            },
            progressSpinner(state) {
                const loaderShroud = document.querySelector('.pricing-details-buttons .loader-shroud');
                if (state === true) {
                    loaderShroud.classList.add('show');
                    return
                }
                loaderShroud.classList.remove('show');
            },
        }
    );

    t.controllers.define(
        'get-delivery-dates',
        {
            'click button[data-action="get-dates"]': 'getDeliveryDates',
            'click button[data-action="change-address"]': 'showAddressField'
        },
        {
            'init:after': function () {
                this.deliveryDatesUrl = this.$el[0].dataset.url;
                this.datesBtn = this.$el.find('button[data-action="get-dates"]')[0];
                this.addressBtn = this.$el.find('button[data-action="change-address"]')[0];
                this.addressDetails = document.querySelector('.address-details');
                this.addressDetailsRedesign = document.querySelector('.address-details-redesign');
                this.csFlow = document.querySelector('.cs-flow');
                this.postCodeError = document.querySelector('.postcode-validation-error-dsm');
                this.postCodeInputs = [document.querySelector('input#postcode'),
                    document.querySelector('input#address-postal_code'),
                    document.querySelector('input#search-postcode'),
                    document.querySelector('input#search-postcode-redesign')];
                this.paymentProviderUUID = document.querySelector('input#payment-provider-uuid')
                this.addPostCodeListeners();
                this.manualAddressForm = document.querySelector('form.tails-view-your-address');
                this.validDeliveryDates = false;
                this.handleManualAddressForm();
                this.validatePostcodeUrl = this.$el[0].dataset.validationurl;
                this.countryCode = this.$el[0].dataset.storecountry;
                this.showHideDatesBtn();
            },
            // Stop tagged customer accidentally submitting the whole form from the first section
            handleManualAddressForm() {
                // Ignore non-tagged
                if (!document.querySelector('div.cs-flow')) {
                    return;
                }
                this.manualAddressForm.addEventListener('submit', (e) => {
                    if (!this.validDeliveryDates) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        return false;
                    }
                });
            },
            showHideDatesBtn() {
                if (!document.querySelector(".tails-view-address-lookup[data-state=long-form]") && !document.querySelector(".tails-view-address-lookup[data-state=address-complete]")) {
                    this.datesBtn.hidden = true;
                }
                let postCodeLookup = document.querySelector(".postcode-lookup");
                if (!postCodeLookup) {
                    postCodeLookup = document.querySelector(".postcode-lookup-redesign");
                }
                const index = postCodeLookup.className.includes('redesign') ? 3 : 2
                postCodeLookup.addEventListener('click', () => {
                    if (this.postCodeInputs[index].value) {
                        this.datesBtn.hidden = false;
                    }
                })
                this.postCodeInputs[index].addEventListener('keypress', (e) => {
                    if (e.keyCode === 13) {
                        if (this.postCodeInputs[index].value) {
                            this.datesBtn.hidden = false;
                        }
                    }
                })
            },
            // Get delivery windows
            getDeliveryDates() {
                if (this.validatePostcode()) {
                    this.fetchDeliveryDates().then(data => this.updateSelect(data));
                }
                ;
            },
            // Show second section, hide first section
            showDeliveryDates() {
                if (this.addressDetailsRedesign) {
                    this.addressDetailsRedesign.style.display = 'block';
                }
                this.addressDetails.style.display = 'none';
                this.datesBtn.style.display = 'none';
                this.csFlow.style.display = 'block';
                this.addressBtn.style.display = 'block';
                this.validDeliveryDates = true;
            },
            // Show first section, hide second section
            showAddressField() {
                this.addressDetails.style.display = 'block';
                this.datesBtn.style.display = 'block';
                this.csFlow.style.display = 'none';
                this.addressBtn.style.display = 'none';
                this.validDeliveryDates = false;
            },
            // Replace existing select element's fake delivery dates with real delivery dates
            updateSelect(data) {
                this.clearError();
                const windows = data['windows'];
                const dateSelect = document.querySelector('select#your_first_delivery-delivery_date');
                const choices = dateSelect.querySelectorAll('option');
                choices.forEach(choice => choice.parentNode.removeChild(choice));
                windows.forEach((window, i) => {
                    const select = document.createElement('option');
                    select.value = window.value;
                    select.innerHTML = window.range;
                    // Select the soonest date by default
                    if (i === 0) {
                        select.setAttribute('selected', true);
                    }
                    dateSelect.appendChild(select);
                });
                this.showDeliveryDates();
                this.validDeliveryDates = true;
            },
            error() {
                this.postCodeError.style.display = 'block';
            },
            clearError() {
                this.postCodeError.style.display = 'none';
            },
            // There are multiple address forms due to different couriers and A/B tests
            getPostCode() {
                const postCodeElement = this.postCodeInputs
                    .filter(elem => elem && elem.value)[0];
                if (!postCodeElement) {
                    return '';
                }
                return postCodeElement.value;
            },
            // Clear existing errors when the customer types in a postcode field
            addPostCodeListeners() {
                this.postCodeInputs.forEach(elem => elem && elem.addEventListener('keypress', () => this.clearError()));
            },
            // check if the postcode is valid
            validatePostcode() {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', encodeURI(this.validatePostcodeUrl + '?country=' + this.countryCode + '&postcode=' + this.getPostCode()), false);
                xhr.send();
                if (!JSON.parse(xhr.response)) {
                    this.error();
                    return false;
                } else {
                    return true;
                }
            },
            // Get the response object from a API request
            fetchDeliveryDates() {
                return fetch(this.deliveryDatesUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        'postcode': this.getPostCode(),
                        'limit': 12,
                        'payment_provider_uuid': this.paymentProviderUUID.value
                    })
                }).then(response => response.json());
            }
        },
    )

    t.controllers.define(
        'your-address',
        {
            'submit': 'handleSubmit'
        },
        {
            handleSubmit(e) {
                const tailsData = JSON.parse(localStorage.getItem('signupData'))

                const coupon = $('#code').val()
                const deliveryDate = $("#your_first_delivery-delivery_date :selected").text();
                const currency = $('#currency').val();

                window.dataLayer.push(
                    {
                        event: 'add_shipping_info',
                        tailsData: tailsData,
                        ecommerce: {
                            currency: currency,
                            coupon: coupon,
                            delivery_date: deliveryDate
                        }
                    }
                )

                return true;
            }
        }
    )

    t.controllers.define(
        'payment-new',
        {
            'submit': 'handleSubmit'
        },
        {
            'init:after': function () {
                this.errors = this.$el.find('.card-errors');
                this.cardCountry = this.$el.find('input[name=card_country]');
                this.cardCurrency = this.$el.find('input[name=card_currency]');
                this.stripe = Stripe(this.$el.data("stripe-key"));
                this.clientSecret = this.$el.data("token");
                this.returnUrl = this.$el.data("return-url");
                this.loader = $('.step[data-status=active] .loader-shroud')
                this.generateStripeElements();
            },
            updateCardIcon(brand) {
                const cardDiv = document.querySelector('#card-number.card-icon');
                const supportedBrands = ['visa', 'mastercard', 'amex'];
                if (supportedBrands.indexOf(brand) >= 0) {
                    cardDiv.classList.remove(...supportedBrands);
                    cardDiv.classList.add(brand);
                }
            },
            generateStripeElements() {
                const args = {
                    fonts: [
                        {
                            family: 'Montserrat',
                            src: 'local("Montserrat-Regular"), url("/static/fonts/Montserrat/montserrat-regular.woff2") format("woff2")',
                        }
                    ]

                };
                if (this.cardCountry.val()) {
                    // Stripe does not have a `locale` for Austria (country code AT).
                    // Convert Austria's country code to German DE
                    args['locale'] = this.cardCountry.val() === 'AT'
                        ? 'de'
                        : this.cardCountry.val().toLowerCase();
                }
                this.stripeElements = this.stripe.elements(args);
                const style = {
                    base: {
                        fontFamily: "'Montserrat', Arial, sans-serif",
                        color: "#036",
                        fontSize: "18px",
                        fontSmoothing: 'subpixel-antialiased',
                        fontWeight: "500",
                        lineHeight: 1.42857,
                        '::placeholder': {
                            color: '#BBB',
                            fontWeight: "500",
                        }
                    },
                    invalid: {
                        color: '#BB0000',
                        ':focus': {
                            color: '#003366'
                        }
                    }
                }

                this.card_number = this.stripeElements.create('cardNumber', {
                    hideIcon: false,
                    style: style,
                    placeholder: ""
                });
                this.card_number.mount('#card-number');
                this.card_number.on('change', (e) => {
                    const {brand} = e;
                    this.updateCardIcon(brand);
                });

                this.card_expiry = this.stripeElements.create('cardExpiry', {
                    style: style,
                    placeholder: ""
                });
                this.card_expiry.mount('#card-expiry');

                this.card_cvc = this.stripeElements.create('cardCvc', {
                    style: style,
                    placeholder: ""
                });
                this.card_cvc.mount('#card-cvc');
            },
            handleSubmit(e) {
                if (!e.originalEvent) return; // Skip events from Stripe
                e.preventDefault();
                this.loader.addClass('show')
                this.cardSetup();
            },
            cardSetup() {
                this.stripe.confirmCardSetup(
                    this.clientSecret,
                    {
                        payment_method: {
                            card: this.card_number,
                        },
                        return_url: this.return_url
                    }
                ).then(result => {
                    if (result.error) {
                        // 3d authentication failed
                        const {message: errorMessage} = result.error;
                        this.toggleSubmit(true);
                        this.renderError(errorMessage);
                        this.loader.removeClass('show');
                    } else {
                        // https://stripe.com/docs/payments/3d-secure#manual-redirect
                        const action = result.setupIntent.next_action;
                        if (action && action.type === 'redirect_to_url') {
                            this.saveRedirectUrl(action.redirect_to_url.url)
                        } else {
                            this.saveToken(result.setupIntent.payment_method);
                        }
                    }
                });
            },
            saveRedirectUrl(redirect_url) {
                this.$el.append($('<input type="hidden" name="token">').val(redirect_url));
                this.$el.append($('<input type="hidden" name="redirect_url">').val(redirect_url));
                this.$el.submit();
            },
            saveToken(token) {
                // token contains id, last4, and card type
                // Insert the token into the form so it gets submitted to the server
                this.$el.append($('<input type="hidden" name="token">').val(token));
                // and submit
                if (token) {
                    this.$el.submit();
                } else {
                    // Log error on sentry since we should not have this case
                    window.Raven && window.Raven.captureException && window.Raven.captureException('stripe no token')
                }
            },
            toggleSubmit(enabled) {
                this.$el
                    .find("button[type=submit]")
                    .prop("disabled", !enabled)
                    .toggleClass("btn-progress", !enabled);
            },
            renderError(text) {
                const content = `
                    <div class="icon notify-icon icons-info-exclamation-triangle"></div>
                    <div class="notification-content">
                    <p>${text}</p>
                    </div>
                `;
                this.errors.html(content);
                this.errors.show();
            },
        }
    )

    t.controllers.define(
        'details',
        {
            'submit': 'handleSubmit'
        },
        {
            'init:after': function () {
                this.errors = this.$el.find('.card-errors');
                this.stripe = Stripe(this.$el.data("stripe-key"));
                this.clientSecret = this.$el.data("client-secret");
                this.loaderShroud = document.querySelector('.loader-shroud');
                this.notification = document.querySelector('.notification');
                this.messagesAndActions = document.querySelector('.message-actions');
                this.completedSCANotification = document.querySelector('.sca-completed-notification')
            },
            handleSubmit(e) {
                if (!e.originalEvent) return; // Skip events from Stripe
                e.preventDefault();
                this.loaderShroud.classList.add('show');

                // The payment intent should be returned directly by the backend and the submit should go directly to transactionConfirm
                this.stripe.retrievePaymentIntent(this.clientSecret).then(result => {
                    if (result.error) {
                        // 3d authentication failed
                        this.loaderShroud.classList.remove('show');
                        const {message: errorMessage} = result.error;
                        this.toggleSubmit(true);
                        this.renderError(errorMessage);
                    } else {
                        if (
                            result.paymentIntent.status === "requires_source"
                            || result.paymentIntent.status === "requires_payment_method") {
                            this.transactionConfirm(result.paymentIntent);
                        } else if (result.paymentIntent.status === "succeeded") {
                            // Here, in case the paymentIntent is succeeded but the shipment is still in SCA_pending
                            // we can trigger the shipment update on backend again
                            this.toggleSubmit(true);
                        } else {
                            this.toggleSubmit(true);
                        }
                    }
                });
            },
            transactionConfirm(paymentIntent) {
                this.stripe.handleCardPayment(
                    this.clientSecret,
                    {
                        payment_method: paymentIntent.last_payment_error.payment_method.id,
                    }
                ).then(result => {
                    if (result.error) {
                        // 3d authentication failed
                        this.loaderShroud.classList.remove('show');
                        const {message: errorMessage} = result.error;
                        this.toggleSubmit(true);
                        this.renderError(errorMessage);
                    } else {
                        // we made it
                        const form = this.$el[0];
                        const url = form.getAttribute('action');
                        const notification = this.notification;
                        const loaderShroud = this.loaderShroud;
                        const messagesAndActions = this.messagesAndActions;
                        const completedSCANotification = this.completedSCANotification;
                        $.ajax({
                            url: url,
                            type: 'POST',
                            data: {},
                            success() {
                                loaderShroud.classList.remove('show');
                                notification.classList.remove('fail');
                                $(messagesAndActions).hide();
                                notification.classList.add('success');
                                completedSCANotification.classList.remove('hidden');
                                notification.classList.add('hidden');
                            }
                        });
                    }
                });
            },
            toggleSubmit(enabled) {
                this.$el.find('button[type=submit]').prop('disabled', !enabled).toggleClass('btn-progress', !enabled);
            },
            renderError(text) {
                this.errors.text(text);
                this.errors.show();
            }
        }
    );

    t.controllers.define(
        // This controller is currently only in use in admin.
        // Strings inside _validatorRequired and _validatorEmail functions
        // have not been translated.
        // If these are to be utilised in the web app again,
        // then strings should be moved to template
        // and tagged with {%trans%}{%endtrans%} tags.
        'form',
        {
            'submit': 'onFormSubmit',
            'click button[type=submit]': 'onSubmitButtonClick',
            'click a.help-tip': 'onHelpTipClick'
        },
        {
            'init:after': function () {
                this.$form = this.$el;

                this._validators = [
                    this._validatorRequired,
                    this._validatorEmail,
                    this._validatorMaxLength
                ];

                this.$form
                    .attr('novalidate', 'novalidate');
            },
            clearFormErrors: function () {
                this.$form.find('fieldset, .form-group').removeClass('has-error').find('.help-block').html('');
            },
            onFormSubmit: function (e) {
                this._hasErrors = false;
                this.clearFormErrors();

                var groupedFields = this._getGroupedFields();

                var customValidators = this._customValidators();

                for (var fieldName in groupedFields) {
                    var group = groupedFields[fieldName];

                    var fieldValidators = customValidators[fieldName] || [];
                    fieldValidators = fieldValidators.concat(this._validators);

                    for (var i = 0; i < fieldValidators.length; i++) {
                        var validator = fieldValidators[i];
                        if (!validator.apply(this, [group])) {
                            break;
                        }
                    }
                    var $collection = $(group.fields);
                    var $parentErrorElement = $collection.closest('.form-group');
                    if (!($parentErrorElement.length && $parentErrorElement.find('.help-block').length)) {
                        $parentErrorElement = $collection.closest('fieldset');
                    }

                    var $helpBlock = $parentErrorElement.find('.help-block');

                    if (group.errors.length > 0) {
                        this._hasErrors = true;
                        $parentErrorElement.addClass('has-error');
                        $helpBlock.html('');

                        if (group.type == 'radio' || group.type == 'checkbox') {
                            for (var i = 0; i < group.errors.length; i++) {
                                $helpBlock.append('<p>' + group.errors[i] + '</p>');
                            }
                        } else {
                            for (var i = 0; i < group.errors.length; i++) {
                                $helpBlock.append('<p>' + group.errors[i] + '</p>');
                            }
                        }
                    }
                }

                var promises = [];
                var controllers = this.find();
                for (var i = 0; i < controllers.length; i++) {
                    var controller = controllers[i];
                    if (controller.validate) {
                        promises.push(controller.validate());
                    }
                }

                $.when.apply(null, promises)
                    .then(
                        t.util.bind(function () {
                            for (var i = 0; i < arguments.length; i++) {
                                if (arguments[i] !== undefined && arguments[i] === false) {
                                    this._hasErrors = true;
                                }
                            }
                        }, this),
                        t.util.bind(function (val) {
                            this._hasErrors = true;
                        }, this)
                    )
                    .done(t.util.bind(this._submitForm, this))
                    .fail(t.util.bind(this._submitForm, this));

                return false;
            },
            onSubmitButtonClick: function (e) {
                e.stopPropagation();
                e.preventDefault();
                this.$clickedSubmitButton = $(e.target);
                if (this.$clickedSubmitButton.not(":disabled")) {
                    this.$clickedSubmitButton.prop("disabled", true);
                    this.$form.submit();
                }
            },
            _submitForm: function () {
                if (!this._hasErrors) {
                    this.$clickedSubmitButton.addClass('btn-progress').prop('disabled', true);

                    // IE10 hack
                    this.$el.append($('<input type="hidden">').attr('name', this.$clickedSubmitButton.attr('name')).val(this.$clickedSubmitButton.val()));
                    this.$form[0].submit();
                } else {
                    this.$clickedSubmitButton.prop("disabled", false);
                    var $firstError = this.$form.find('.has-error').first();

                    if ($firstError.length) {
                        var offset = ($firstError.offset().top - 120);
                        if ($('body').scrollTop() > offset) {
                            $('html, body').stop().animate({
                                scrollTop: offset
                            }, 500);
                        }
                    }
                }
            },
            _focusFirstField: function () {
                this.$form.find('input,select').filter(':visible:not(:disabled)').first().focus();
            },
            _getGroupedFields: function () {
                var groupedFields = {};
                var fieldSelector = 'input[name]:not(:disabled),select[name]:not(:disabled)';
                this._groupFields(groupedFields, this.findWithinDOM(fieldSelector));

                var x = this.find();

                this.find().forEach(t.util.bind(function (controller) {
                    if (!controller.validate) {
                        this._groupFields(groupedFields, controller.findWithinDOM(fieldSelector));
                    }
                }, this));

                return groupedFields;
            },
            _groupFields: function (groupedFields, $fields) {
                $fields.each(function () {
                    var $field = $(this);
                    var type = $field.attr('type');
                    var name = $field.attr('name').replace(/-\d+$/, '');

                    if (!(type === 'radio' || type === 'checkbox')) {
                        if ($field.is(':not(:visible):hidden')) {
                            return;
                        }
                    }

                    if (!groupedFields[name]) {
                        groupedFields[name] = {
                            type: type,
                            hasEnabled: false,
                            hasVisible: false,
                            fields: [],
                            errors: []
                        };
                    }

                    groupedFields[name].fields.push(this);
                });
            },
            _customValidators: function () {
                return []
            },
            _validatorRequired: function (group) {
                var $collection = $(group.fields);

                if ($collection.filter('[required]').length) {
                    if (group.type == 'radio' || group.type == 'checkbox') {
                        if (!$collection.filter(':checked').length) {
                            group.errors.push('Please make a selection');
                            return false;
                        }
                    } else if (!$collection.val()) {
                        group.errors.push('This field is required');
                        return false;
                    }
                }

                return true;
            },
            _validatorEmail: function (group) {
                var $collection = $(group.fields);

                if ($collection.filter('[type=email]').length) {
                    var hasError = false;
                    $collection.each(function () {
                        var $this = $(this);
                        if (!$this.val()) {
                            return;
                        }

                        if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test($this.val())) {
                            hasError = true;
                        }
                    });
                    if (hasError) {
                        group.errors.push('Please enter a valid email address');
                        return true;
                    }
                }
                return true;
            },
            _validatorMaxLength: function (group) {
            },
            // Help tip
            onHelpTipClick: function (e) {
                e.preventDefault();
                var $target = $(e.target);
                this.$el.find($target.data('target')).slideToggle('fast');
            }
        }
    );

    t.controllers.define(
        'form.feeding-plan-form',
        {
            'click a.change-target-kcal': 'onChangeTargetKcal'
        },
        {
            onChangeTargetKcal: function (e) {
                this.findWithinDOM('.wet-kcal-percent').removeClass('hide').hide().slideDown('fast').find('select').removeProp('disabled');
                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'testimonial-slider',
        {
            'click a.testimonial-slider-left': 'onLeftClick',
            'click a.testimonial-slider-right': 'onRightClick'
        },
        {
            'init:after': function () {
                this._width = 400;
                this._currentSlide = 0;
                this._totalSlides = this.findWithinDOM('blockquote').length - 1;
                this._isAnimating = false;
                this._slideshowDelay = 10000;

                this.$wrapper = this.findWithinDOM('.testimonial-wrapper');

                $(document).ready(t.util.bind(this._startSlideshow, this));
            },
            onLeftClick: function (e) {
                e.preventDefault();
                clearInterval(this._slideInt);
                this._moveSlide(-1);
                return false;
            },
            onRightClick: function (e) {
                e.preventDefault();
                clearInterval(this._slideInt);
                this._moveSlide(1);
                return false;
            },
            _startSlideshow: function () {
                this._slideInt = setInterval(t.util.bind(this._advanceSlideshow, this), this._slideshowDelay);
            },
            _advanceSlideshow: function () {
                this._moveSlide(1);
            },
            _moveSlide: function (direction) {
                if (this._isAnimating) {
                    return;
                }
                this._isAnimating = true;

                if (this._currentSlide + direction < 0) {
                    this._animateSlide(0, function () {
                        this._currentSlide = this._totalSlides - 1;
                        this._setOffset('-' + this._totalSlides * 100 + '%');
                        this._isAnimating = false;
                    });
                } else if (this._currentSlide + direction > (this._totalSlides - 1)) {
                    this._setOffset(0);
                    this._animateSlide('-100%', function () {
                        this._currentSlide = 0;
                        this._isAnimating = false;
                    });
                } else {
                    this._animateSlide((direction > 0 ? '-' : '+') + '=100%', function () {
                        this._currentSlide += direction;
                        this._isAnimating = false;
                    });
                }
            },
            _animateSlide: function (offset, callback) {
                this.$wrapper.animate({
                    marginLeft: offset
                }, 500, t.util.bind(callback, this));
            },
            _setOffset: function (offset) {
                this.$wrapper.css({
                    marginLeft: offset
                });
            }
        }
    );

    t.controllers.define(
        'ingredients',
        {
            'click .category-nav a': 'onCategoryNavClick',
            'click .ingredient-nav a': 'onIngredientNavClick',
            'click .category-nav-container .scroll-left': 'onCategoryScrollLeftClick',
            'click .category-nav-container .scroll-right': 'onCategoryScrollRightClick'
        },
        {
            'init:after': function () {
                this.$categoryNav = this.$el.find('.category-nav');

                // Create and add scroll indicators
                this.$scrollLeftIndicator = $('<a href="#" class="scroll scroll-left">&laquo;</a>');
                this.$scrollRightIndicator = $('<a href="#" class="scroll scroll-right">&raquo;</a>');

                this.$el.find('.category-nav-container')
                    .prepend(this.$scrollLeftIndicator)
                    .append(this.$scrollRightIndicator);

                window.setInterval(t.util.bind(this._updateScrollIndicators, this), 100);

                // Enable touch/drag based scrolling of category nav
                this.$categoryNav.kinetic({
                    y: false
                });
            },
            onCategoryNavClick: function (e) {
                e.preventDefault();

                var $categoryAnchor = $(e.target).closest('a');
                var categoryFragmentIdentifier = $categoryAnchor.attr('href');

                this.$el.find('.category-nav a.selected').removeClass('selected');
                $categoryAnchor.addClass('selected');

                this.$el.find('.ingredient-categories .category.selected').removeClass('selected');
                this.$el.find(categoryFragmentIdentifier).addClass('selected');
            },
            onCategoryScrollLeftClick: function (e) {
                e.preventDefault();
                this.$categoryNav.animate({scrollLeft: "-=" + this.$categoryNav.width()}, 300);
            },
            onCategoryScrollRightClick: function (e) {
                e.preventDefault();
                this.$categoryNav.animate({scrollLeft: "+=" + this.$categoryNav.width()}, 300);
            },
            onIngredientNavClick: function (e) {
                e.preventDefault();

                var $ingredientAnchor = $(e.target).closest('a');
                var ingredientFragmentIdentifier = $ingredientAnchor.attr('href');

                this.$el.find('li.category.selected .ingredient-nav a.selected').removeClass('selected');
                $ingredientAnchor.addClass('selected');

                this.$el.find('li.category.selected li.ingredient.selected').removeClass('selected');
                this.$el.find(ingredientFragmentIdentifier).addClass('selected');
            },
            _updateScrollIndicators: function () {
                var scrollLeft = this.$categoryNav[0].scrollLeft;

                if (scrollLeft == 0) {
                    this.$scrollLeftIndicator.fadeOut('fast');
                } else {
                    this.$scrollLeftIndicator.fadeIn('fast');
                }

                if (scrollLeft >= this.$categoryNav[0].scrollWidth - this.$categoryNav[0].clientWidth) {
                    this.$scrollRightIndicator.fadeOut('fast');
                } else {
                    this.$scrollRightIndicator.fadeIn('fast');
                }
            }
        }
    );

    t.controllers.define(
        'tab-link',
        {
            'click': 'onClick'
        },
        {
            'init:after': function () {
                this.$fieldset = this.$el;
            },
            onClick: function (e) {
                e.preventDefault();
                var el = $(this)[0].$el[0];
                var link = $(el).attr('href');
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    window.location = link;
                    return;
                } else {
                    var tab = window.open(link, '_blank');
                    tab.focus();
                }


            }
        }
    );

    t.controllers.define(
        'blend-details',
        {
            'click a.show-ingredients': 'onShowIngredientsClick',
            'click a.what-are-additives': 'onWhatAreAdditivesClick',
            'click a.ash': 'onAshClick'
        },
        {
            'init:after': function () {
                var ingredientData = this.data.ingredients;
                var additiveData = this.data.additives;

                this.$el.find('.ash-explanation').removeClass('hide').hide();
            },
            onShowIngredientsClick: function (e) {
                e.preventDefault();
                $(e.target).slideUp('fast', t.util.bind(this._showIngredients, this));
            },
            onWhatAreAdditivesClick: function (e) {
                e.preventDefault();
                $(e.target).slideUp('fast', t.util.bind(this._showAdditivesExplanation, this));
            },
            _showIngredients: function () {
                this.$el.find('.ingredients-list').removeClass('hide').hide().slideDown('fast');
            },
            _showAdditivesExplanation: function () {
                this.$el.find('.additives-explanation').removeClass('hide').hide().slideDown('fast');
            },
            onAshClick: function (e) {
                e.preventDefault();
                this.$el.find('.ash-explanation').slideToggle('fast');
            }
        }
    );

    t.controllers.define(
        'toggle-modal',
        {
            'change': 'onChange'
        },
        {
            'init:after': function () {
                this.input = this.$el[0];
            },
            onChange: function () {
                this.input.checked ? $('body').addClass('modal-open') : $('body').removeClass('modal-open');
            }

        }
    );

    t.controllers.define(
        'feeding-plan',
        {
            'change .select-feeding-plan': 'selectFeedingPlan',
            'onload': 'onLoad',
        },
        {
            'init:after': function () {
                this.form = $("#feeding_plan");
                this.onLoad()
            },
            selectFeedingPlan: function (e) {
                e.preventDefault();
                this.form.submit();
            },
            onLoad: function () {
                $('.submit-button').css({
                    'display': 'none'
                });
            },
        }
    );

    t.controllers.define(
        'paypal-button-settings',
        {},
        {
            'init:after': function () {
                this.createClient();
            },
            createClient() {
                const paypalConfig = {
                    env: this.$el.data('paypal-mode'),
                    billingAgreementDescription: this.$el.data('paypal-agreement'),
                    currency: this.$el.data('paypal-currency'),
                    locale: this.$el.data('paypal-locale'),
                    endpoint: this.$el.data('paypal-endpoint'),
                    nextUrl: this.$el.data('paypal-next-url'),
                    authorization: this.$el.data('paypal-client-token'),
                    uuid: this.$el.data('uuid'),
                    uuidStored: false,
                }
                braintree.client.create({
                    authorization: paypalConfig.authorization,
                }).then(clientInstance => {

                    return braintree.paypalCheckout.create({
                        client: clientInstance
                    });
                }).then(paypalCheckoutInstance => {
                    return paypal.Button.render({
                        env: paypalConfig.env,
                        style: {
                            size: 'responsive',
                            color: 'gold',
                            shape: 'pill',
                            tagline: false,
                            locale: paypalConfig.locale,
                        },

                        payment() {
                            fetch(`/api/payment/uuid/${paypalConfig.uuid}`)
                                .then(response => {
                                    paypalConfig.uuidStored = response.status === 200
                                });
                            return paypalCheckoutInstance.createPayment({
                                flow: 'vault',
                                billingAgreementDescription: paypalConfig.billingAgreementDescription,
                                enableShippingAddress: true,
                                shippingAddressEditable: true,
                                currency: paypalConfig.currency,
                            });
                        },

                        onAuthorize(data, actions) {
                            const queryString = new URLSearchParams();
                            queryString.append('keep-existing-address', 'true');
                            const form = document.querySelector('#payment-provider-form');
                            return paypalCheckoutInstance.tokenizePayment(data)
                                .then(payload => {
                                    fetch(`${paypalConfig.endpoint}?${queryString}`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(payload)
                                    }).then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                form.submit();
                                            }
                                        })
                                });
                        },

                        onCancel(data) {
                            console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
                        },

                        onError(err) {
                            console.error('checkout.js error', err);
                        }
                    }, '#paypal-button');
                }).then(function () {
                    // runs when button is fully ready to use
                }).catch(function (err) {
                    // runs when button did not render correctly
                });
            },
        }
    )


    t.controllers.define(
        'video-modal',
        {
            'click a.video-modal-close': 'onModalCloseClick',
            'click': 'onModalCloseClick'
        },
        {
            'init:after': function () {
                this.$videoModalFrame = $('.video-modal-frame');
                this.$videoModalLoading = $('.video-modal-loading');
                this._url = this.options.url;
                this._mobileHLSURL = this.options.mobileHlsUrl;
                this._mobileURL = this.options.mobileUrl;

                this._initLinks();
            },
            _initLinks: function () {
                $('a.watch-video').click(t.util.bind(this._onWatchClick, this));
            },
            _onWatchClick: function (e) {
                e.preventDefault();

                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: 'video',
                    eventAction: 'play',
                    eventLabel: this.options.identifier
                });
                this._showVideo();
            },
            _showVideo: function () {
                $('body').css({
                    'overflow': 'hidden',
                    'top': $(document).scrollTop() * -1
                });

                if (window.isMobile) {
                    if (!this.$video) {
                        //window.location.href = this._mobileURL;
                        var hls = document.createElement('video').canPlayType('application/vnd.apple.mpegURL');
                        if (hls == 'probably') {
                            this.$video = $('<video src="' + this._mobileHLSURL + '" controls webkit-playsinline><source src="' + this._mobileURL + '"></source></video>')
                                .insertAfter(this.$videoModalLoading);
                        } else {
                            this.$video = $('<video controls webkit-playsinline><source src="' + this._mobileURL + '"></source></video>')
                                .insertAfter(this.$videoModalLoading);
                        }
                    }
                } else {
                    if (!this.$videoIframe) {
                        this.$videoIframe = $('<iframe src="' + this._url + '?autoplay=1" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>')
                            .insertAfter(this.$videoModalLoading);
                    }
                }
                this.$el.hide().removeClass('hide').fadeIn('fast');
                this._playFromStart();
            },
            _playFromStart: function () {
                if (this.$videoIframe) {
                    try {
                        this.$videoIframe[0].contentWindow.postMessage(JSON.stringify({
                            'method': 'seekTo',
                            'value': '0'
                        }), '*');
                        this.$videoIframe[0].contentWindow.postMessage(JSON.stringify({
                            'method': 'play'
                        }), '*');
                    } catch (e) {
                    }
                } else {
                    this.$video[0].currentTime = 0;
                    this.$video[0].play();
                }
            },
            _reset: function () {
                if (this.$videoIframe) {
                    try {
                        this.$videoIframe[0].contentWindow.postMessage(JSON.stringify({
                            'method': 'seekTo',
                            'value': '0'
                        }), '*');
                        this.$videoIframe[0].contentWindow.postMessage(JSON.stringify({
                            'method': 'pause'
                        }), '*');
                    } catch (e) {
                    }
                } else if (this.$video) {
                    this.$video[0].currentTime = 0;
                    this.$video[0].pause();
                }
            },
            onModalCloseClick: function (e) {
                e.preventDefault();

                if ((this.$video && e.target === this.$video[0]) || (this.$videoIframe && e.target === this.$videoIframe[0])) {
                    return;
                }

                this._reset();

                this.$el.fadeOut('fast');
                $('body').css({
                    'overflow': 'visible'
                });

            }
        }
    );

    t.controllers.define(
        'reviews-grid',
        {},
        {
            'init:after': function () {
                var container = document.querySelector('.main .reviews');
                $(container).imagesLoaded(function () {
                    new Masonry(container, {
                        "itemSelector": '.review',
                        "gutter": 20,
                        "columnWidth": container.querySelector('.grid-sizer'),
                        "percentPosition": true,
                        "stamp": ".reviews-header",
                        "transitionDuration": 0
                    });
                });
            }
        }
    );

    t.controllers.define(
        'faq-search',
        {
            'submit': "onSubmit",
            'click button': "submit"
        },
        {
            'init:after': function () {
                this.input = this.$el.find('input[type=text]');
            },
            'submit': function () {
                if (this.input.val() !== "") {
                    this.$el.submit();
                }
            },
            'onSubmit': function () {
                var input_text = this.input.val();
                if (input_text) {
                    window.dataLayer.push({
                        event: 'ga',
                        eventCategory: 'faq',
                        eventAction: 'search',
                        eventLabel: input_text
                    });
                }
            }
        }
    );

    t.controllers.define(
        'body',
        {
            'click [data-ga-label]': "onGaEventClick",
            'click [data-event]': "onEventClick",
            'change input[type=checkbox]': "onCheckboxChange",
            'submit form': 'onFormSubmit',
            'click .raf-exp-click-feeding-plan': 'onClickRafExperimentFeedingPlan',
            'click .raf-exp-click-profile': 'onClickRafExperimentProfile',
            'click .raf-exp-click-nav': 'onClickRafExperimentNavItem',
            'click #tongue': 'tracko8yEvent'
        },
        {
            'init:after': function () {
                this.top_nav = this.$el.find(".top-nav");
                this.logo = this.top_nav.find(".logo");
                this.hamburger = this.$el.find("#hamburger");
                this.initTips();
                this.initStickyCtas();
                this.submitting = false;
                t.message.subscribe('tracking', 'event', t.util.bind(this.onEventMessage, this));
            },
            onFormSubmit: function (e) {
                $(e.currentTarget).find('button[type="submit"]').addClass('btn-progress');
            },
            initStickyCtas: function () {
                this.cta = this.$el.find('.sticky-cta');

                if (this.cta.length === 0) {
                    return;
                }

                this.nav = this.$el.find('nav');
                this.last_scroll = 0;
                this.inertia = this.cta.data("inertia") || 0;
                this.point_of_change = null;
                this.current_direction = null;
                this.initial_state = this.cta.data("state");

                // Default waypoint is 1/3 screen height
                this.hide = 0;
                this.waypoint = null;

                if (this.cta.data("waypoint")) {
                    // Could make this update on resize
                    var waypoint_elem = $(this.cta.data("waypoint"));
                    this.waypoint = waypoint_elem.offset().top + waypoint_elem.outerHeight();
                    this.hide = this.waypoint;
                } else {
                    this.waypoint = Math.round($(window).height() / 3);
                }

                $(window).on('scroll', t.util.bind(function () {
                    var current_scroll = $(window).scrollTop();
                    var new_direction = null;

                    if (current_scroll <= this.hide) {
                        this.$el.removeClass("scroll-down");
                        if (this.initial_state === 'hide') {
                            this.cta.attr("data-state", "hide");
                        }
                    } else if (current_scroll >= this.waypoint) {
                        this.cta.attr("data-state", "show");

                        if (current_scroll > this.last_scroll) {
                            new_direction = 'down';
                        } else if (current_scroll < this.last_scroll) {
                            new_direction = 'up'
                        }

                        if (new_direction !== this.current_direction) {
                            this.point_of_change = Math.max(current_scroll - this.inertia, 0);
                            this.current_direction = new_direction;
                        }

                        if (this.current_direction === 'up' && current_scroll <= this.point_of_change) {
                            this.$el.removeClass('scroll-down');
                        } else if (this.current_direction === 'down') {
                            this.$el.addClass('scroll-down');
                        }

                        this.last_scroll = current_scroll;
                    }
                }, this));
            },
            initTips: function () {
                this.tips = this.$el.find(".with-tip");
                this.tips.each(function (i, v) {
                    var tip = $(v).find('.tip');
                    var sticky_links = $('.links').outerHeight();
                    $(v).waypoint({
                        handler: function (direction) {
                            if (direction === "down") {
                                tip.addClass("bottom");
                            } else {
                                tip.removeClass("bottom");
                            }
                        },
                        offset: tip.outerHeight() + sticky_links + $('.top-nav').outerHeight()
                    });
                });
            },
            onCheckboxChange: function (e) {
                if (window.is_native_android) {
                    $(e.target).parent().addClass("force-repaint");
                    setTimeout(function () {
                        $(e.target).parent().removeClass("force-repaint");
                    }, 300)
                }
            },
            onGaEventClick: function (e) {
                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: 'button',
                    eventAction: 'click',
                    eventLabel: $(e.currentTarget).data("ga-label")
                });
            },
            onEventClick: function (e) {
                var data = $(e.currentTarget).data("event");
                this.onEvent(data);
            },
            onEventMessage: function (e) {
                this.onEvent(e.data);
            },
            onEvent: function (data) {
                var parts = data.split(":");
                this.trackEvent(parts[0], parts[1]);
            },
            trackEvent: function (key, value) {
                $.get(
                    "/record/",
                    {
                        'key': key,
                        'value': value
                    }
                )
            },
            onClickRafExperimentFeedingPlan: function () {
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=raf_banner_clicked_on_feeding_plan_page',
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
            onClickRafExperimentProfile: function () {
                if (window.location.href.includes('profiles') || window.location.href.includes('profile')) {
                    $.ajax({
                        type: 'GET',
                        url: '/api/optimizely/track/?event=banner_clicked_on_confirm_component',
                        dataType: 'json',
                        success: (response) => {
                        },
                    });
                }
            },
            onClickRafExperimentNavItem: function () {
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=raf_nav_item_clicked',
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
            tracko8yEvent: function () {
                console.log('event is being tracked')
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=clicked_nav_promo',
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
        }
    );

    t.controllers.define(
        'contact-us',
        {
            'click .option.phone': "onPhoneClick"
        },
        {
            "init:after": function (e) {
                this.chat = this.$el.find(".option.chat");
            },
            'onPhoneClick': function (e) {
                this.$el.find(".option.phone").addClass("show-number");
            }
        }
    );


    t.controllers.define(
        'contact-form',
        {
            'click button': "onSubmit"
        },
        {
            'onSubmit': function (e) {
                $(e.target).attr("disabled", "disabled");
                this.$el.submit();
            }
        }
    );

    t.controllers.define(
        'bag-slider',
        {},
        {
            'init:after': function () {
                if (this.$el.data('bag-count') > 1) {
                    this.$el.slick({
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        mobileFirst: true,
                        prevArrow: false,
                        nextArrow: false
                    });
                }
            }
        }
    );

    t.controllers.define(
        'feeding-plan-links',
        {},
        {
            'init:after': function () {
                var that = this;
                this.sticky_links = $('.links');
                this.placeholder = $('.links-wrapper');
                $('.section.claims').waypoint({
                    handler: function (direction) {
                        if (direction == "down") {
                            that.placeholder.css("min-height", that.placeholder.innerHeight())
                            that.sticky_links.addClass("fixed");
                        } else {
                            that.placeholder.css("min-height", 0);
                            that.sticky_links.removeClass("fixed");
                        }
                    },
                    offset: 0
                });
            }
        }
    );


    t.controllers.define(
        'anchor-transition',
        {
            "click": "onClick",
            "click a": "onClick"
        },
        {
            'init:after': function (e) {
                this.offset = this.$el.data("offset") || 0;
                this.body = $("body");
            },
            'onClick': function (e) {
                e.preventDefault();
                var anchor = $(e.currentTarget).attr("href");
                var target = $(anchor);
                var scrollto = target.offset().top - $('.question .input').outerHeight(true) - $('.top-nav').outerHeight(true) - $('.fixed').outerHeight();
                scrollto = scrollto - this.offset;
                $("html, body").animate({
                    scrollTop: scrollto
                }, 300);
            }
        }
    );

    t.controllers.define(
        'manage-pets',
        {
            'click a.remove-pet': 'onRemovePetClick',
            'click a.re-add-pet': 'onReAddPetClick'
        },
        {
            onRemovePetClick: function (e) {
                var url = new t.url.URL($(e.target).attr('href'));
                this.$el.find('.loader-shroud').addClass("show");
                $.ajax({
                    type: 'POST',
                    url: url.path(),
                    data: {
                        pet_id: url.param('pet_id')
                    },
                    success: t.util.bind(function () {
                        t.message.publish("price-change", "changed");
                    }, this)
                });

                e.preventDefault();
            },
            onReAddPetClick: function (e) {
                var url = new t.url.URL($(e.target).attr('href'));
                this.$el.find('.loader-shroud').addClass("show");
                $.ajax({
                    type: 'POST',
                    url: url.path(),
                    data: {
                        pet_id: url.param('pet_id')
                    },
                    success: t.util.bind(function () {
                        t.message.publish("price-change", "changed");
                    }, this)
                });

                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'order-tables',
        {
            'keydown .tab': 'onEnterKey'
        },
        {
            onEnterKey: function (e) {
                if (e.key == 'Enter') {
                    e.target.click();
                }
            }
        }
    );

    t.controllers.define(
        'calories',
        {
            'click a.edit': 'onEditClick',
            'click a.cancel': 'onCancelClick',
            'change .adjustor input': 'onRangeChange',
            'input .adjustor input': 'onRangeChange'
        },
        {
            "init:after": function () {
                this.adjustor = this.$el.find(".adjustor");
                this.input = this.$el.find(".adjustor input");
                this.value = this.$el.find(".adjustor .value");
            },
            onCancelClick: function () {
                this.adjustor.removeClass("show");
            },
            onEditClick: function () {
                this.adjustor.addClass("show");
            },
            onRangeChange: function () {
                this.value.text(this.input.val() + "%");
            }
        }
    );


    t.controllers.define(
        'wet-recipe-carousel',
        {},
        {
            'init:after': function () {
                this.$nextButton = $('<button type="button" class="next"></button>');
                this.$previousButton = $('<button type="button" class="previous"></button>');

                this.$nextButton.appendTo(this.$el);
                this.$previousButton.appendTo(this.$el);

                this.$el.find('ul').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    nextArrow: this.$nextButton,
                    prevArrow: this.$previousButton,
                    mobileFirst: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 850,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        }
    );

    t.controllers.define(
        'wet-food-hero',
        {
            'click a.get-started': 'onGetStartedClick'
        },
        {
            'init:after': function () {
                var $selectWrapper = this.$el.find('.select-wrapper');
                var $container = $selectWrapper.parent();
                $container.waypoint({
                    handler: function (direction) {
                        if (direction == "down") {
                            $container.css('height', $selectWrapper.outerHeight() + 'px');
                            $selectWrapper.addClass('fixed');
                        } else {
                            $container.css('height', 'auto');
                            $selectWrapper.removeClass('fixed');
                        }
                    },
                    offset: $('.top-nav').outerHeight()
                });
            },
            onGetStartedClick: function (e) {
                if (this.options.canBuyNow === 'n') {
                    e.preventDefault();

                    t.ui.alert.show(
                        '',
                        '',
                        "You'll need to restart your deliveries from the dashboard in order to add wet food. Would you like to go there now?",
                        [
                            t.ui.alert.BUTTON_NO,
                            t.ui.alert.BUTTON_YES
                        ],
                        t.util.bind(function (button) {
                            if (button == t.ui.alert.BUTTON_YES) {
                                window.location.assign(this.options.restartUrl);
                            }
                        }, this)
                    );
                }
            }
        }
    );

    t.controllers.define(
        'price-page',
        {},
        {
            'init:after': function () {
                t.message.subscribe('price-change', 'changed', t.util.bind(this.refresh, this));
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.refresh, this));
                this.$el.find('.loader-shroud').removeClass("show");
            },
            refresh: function () {
                this.$el.find('.loader-shroud').addClass("show");
                location.reload();
            }
        }
    );

    t.controllers.define(
        'products-wrapper',
        {
            'click .update-product': 'updateProduct'
        },
        {
            'init:after': function () {
            },
            updateProduct: function (e) {
                e.preventDefault();
                var url_api = $(e.currentTarget).data('url');
                $.ajax({
                    url: url_api,
                    type: 'POST',
                    success: function () {
                        t.message.publish("price-change", "changed");
                    }
                })
            },
        }
    );


    t.controllers.define(
        'promo',
        {
            'submit': 'onSubmit',
            'click .no-voucher-code': 'onNoVoucherCodeClick',
            'click .tounge': 'onToungeClick',
            'click .error-default-code': 'onDefaultCodeClick',
            'click #tongue': 'tracko8yEvent'
        },
        {
            "init:before": function () {
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.onPromoChanged, this));
            },
            "init:after": function () {
                this.form = this.$el.find("form");
                if (this.options.defaultPromoCode && this.options.defaultPromoDescription) {
                    this.$noVoucherCodeLink = $('<a></a>')
                        .addClass('no-voucher-code')
                        .attr('data-ga-label', 'tongue-default')
                        .attr('href', '#')
                        .text("or click here for " + this.options.defaultPromoDescription);
                    this.$el.find('#nocode').append(this.$noVoucherCodeLink);
                    var signup_promo = this.$el.find('#code').attr("value");
                    if (signup_promo !== this.options.defaultPromoCode) {
                        this.$el.find(".no-voucher-code").show();
                    } else {
                        this.$el.find(".no-voucher-code").hide();
                    }
                }
            },
            onPromoChanged: function (promo_data) {
                this.updateNavPromo(promo_data.data);
                this.updatePromo(promo_data.data);
            },
            onToungeClick: function (e) {
                $('.tounge button[type="submit"]').removeClass('btn-progress');
            },
            onSubmit: function (e) {
                var that = this;
                e.preventDefault();
                if (this.form.find("#code").val()) {
                    $.ajax({
                        type: "POST",
                        url: this.form.attr("action") + "?ajax=true",
                        data: this.form.serialize(),
                        success: function (data) {
                            that.updatePromo(data);
                        }
                    });

                    try {
                        // Update Promo code in GTM
                        const tailsData = JSON.parse(localStorage.getItem('signupData'))
                        const coupon = this.form.find("#code").val()
                        tailsData[0].code = coupon
                        window.dataLayer.push({tailsData: tailsData})
                        localStorage.setItem('signupData', JSON.stringify(tailsData))
                    } catch (err) {
                    }
                } else {
                    var errorMessage = this.$el.find(".error")
                    if (errorMessage) {
                        errorMessage.html("")
                    }
                    this.$el.find(".error-empty-input").removeAttr('hidden')
                    this.$el.find(".error-default-code").html("<a>" + this.options.defaultPromoDescription + "</a>");
                    this.$el.find(".error-default-code").show();
                }
            },
            updateNavPromo: function (data) {
                const promo_description = data.description;
                this.$el.find('.offer-text').text(promo_description);
            },
            updatePromo: function (data) {
                var promo_data = data.promo;
                var defaultPromoCode = this.options.defaultPromoCode;
                this.$el.find(".error-empty-input").attr("hidden", "hidden")
                if (data.status == "success") {
                    if (promo_data) {
                        $('#tounge').prop("checked", false);
                        this.$el.attr('data-has-promo', "true");
                        this.$el.find('.offer-text').text(promo_data.description);
                        this.$el.find(".error").html("");
                        this.$el.find(".alert-info-arrow-nw").hide();
                        this.$el.find("#code").val(data.promo.code);
                        if (data.promo.code !== defaultPromoCode) {
                            this.$el.find(".no-voucher-code").show();
                        } else {
                            this.$el.find(".no-voucher-code").hide();
                        }
                    } else {
                        this.$el.attr('data-has-promo', "false");
                        this.$el.find('.offer-text').text("");
                        this.$el.find(".no-voucher-code").show();
                    }
                    t.message.publish('promo-widget', 'changed', promo_data);
                } else if (data.error) {
                    this.$el.find(".error").html("<span>" + data.error + "</span>");
                    this.$el.find(".error-default-code").html("<a>" + this.options.defaultPromoDescription + "</a>");
                    this.$el.find(".error-default-code").show();
                }
            },
            onNoVoucherCodeClick: function (e) {
                e.preventDefault();

                this.$el.find('#code').val(this.options.defaultPromoCode);
                var that = this;
                this.$noVoucherCodeLink.slideUp(function () {
                    that.onSubmit({
                        preventDefault: function () {
                        }
                    });
                });
            },
            onDefaultCodeClick: function (e) {
                e.preventDefault();
                this.$el.find('#code').val(this.options.defaultPromoCode);
                var that = this;
                this.$noVoucherCodeLink.slideUp(function () {
                    that.onSubmit({
                        preventDefault: function () {
                        }
                    });
                });
                this.$el.find(".error-default-code").hide();
            },
            tracko8yEvent: function () {
                console.log('event is being tracked')
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=clicked_nav_promo',
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },
        }
    );

    t.controllers.define(
        'promo-popup',
        {
            'submit': 'onSubmit',
            'click .no-voucher-code': 'onNoVoucherCodeClick',
            'click .modal-toggle': 'onChangeClick'
        },
        {
            "init:before": function () {
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.onPromoChanged, this));
            },
            "init:after": function () {
                this.form = this.$el.find("form");
                if (this.options.defaultPromoCode && this.options.defaultPromoDescription) {
                    this.$noVoucherCodeLink = this.$el.find('.no-voucher-code');
                    this.$noVoucherCodeLink.removeClass('hidden');
                    this.$el.find('#nocode').append(this.$noVoucherCodeLink);
                }
            },
            onChangeClick: function () {
                this.$el.find(".error").html("");
            },
            onPromoChanged: function (promo_data) {
                this.updatePromo(promo_data.data);
            },
            onSubmit: function (e) {
                var that = this;
                e.preventDefault();
                if (this.form.find("#code").val()) {
                    $.ajax({
                        type: "POST",
                        url: this.form.attr("action") + "?ajax=true",
                        data: this.form.serialize(),
                        success: function (data) {
                            that.updatePromo(data);
                        }
                    });
                } else {
                    return false;
                }
            },
            updatePromo: function (data) {
                var promo_data = data.promo;
                $('button[type="submit"]').removeClass('btn-progress');
                if (data.status == "success") {
                    if (promo_data) {
                        $('#promo-popup').prop("checked", false);
                        this.$el.attr('data-has-promo', "true");
                        this.$el.find('.offer-text').text(promo_data.description);
                    } else {
                        this.$el.attr('data-has-promo', "false");
                        this.$el.find('.offer-text').text("");
                    }
                    t.message.publish('promo-widget', 'changed', promo_data);
                } else if (data.error) {
                    this.$el.find(".error").html("<span>" + data.error + "</span>");
                }
            },
            onNoVoucherCodeClick: function (e) {
                e.preventDefault();
                this.$el.find('#code').val(this.options.defaultPromoCode);
                this.onSubmit({
                    preventDefault: function () {
                    }
                });
            }
        }
    );

    t.controllers.define(
        'trustpilot',
        {},
        {
            'init:after': function () {
                this.scrollbox = this.$el.find('.reviews');
                this.full_width = this.scrollbox.innerWidth();
                this.$el.removeClass("nojs");
                this.$el.find(".reviews").slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                    nextArrow: this.$el.find(".button.next"),
                    prevArrow: this.$el.find(".button.previous"),
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 850,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                }).on({
                    'setPosition': t.util.bind(this.resize, this),
                    'mousedown': function () {
                        $(this).addClass("grabbing");
                    },
                    'mouseup': function () {
                        $(this).removeClass("grabbing");
                    }
                });
                this.resize();
            },
            'resize': function () {
                this.$el.find('.slick-dots').insertAfter('.reviews-wrapper > h2');
            }
        }
    );

    t.controllers.define(
        'slider-select',
        {
            'change input[type=range]': 'onRangeChange',
            'input input[type=range]': 'onRangeChange',
            'click input[type=radio]': 'onRadioClick',
        },
        {
            "init:after": function () {
                this.range = this.$el.find("input[type=range]");
                this.count = this.$el.find(".option").length;
                this.block_width = (100 / this.count);
                this.pointer = this.$el.find(".pointer");
                var currentElement = this.$el.find("input:checked").parent().attr('data-index');
                this.selectIndex(currentElement);
            },
            onRangeChange: function (e) {
                var index = $(e.target).val();
                $('.option[data-index=' + index + '] input').prop("checked", true);
                this.selectIndex(index);
            },
            onRadioClick: function (e) {
                var index = $(e.target).parent().attr("data-index");
                this.range.val(index);
                this.selectIndex(index);
                $('.seanbox').removeClass("hide").addClass("show");
            },
            selectIndex: function (index) {
                $('.description').removeClass('selected');
                $('.description[data-index=' + index + ']').addClass("selected");
                this.$el.find(".descriptions").attr("data-selected", index);
                if (this.pointer.length) {
                    var offset = this.block_width * index;
                    var half_block = this.block_width / 2;
                    this.pointer.css("transform", "translate3d(calc(" + offset + "% - " + half_block + "%), 0, 0)")
                }
            }
        }
    );

    t.controllers.define(
        'choose-pause',
        {},
        {
            'init:after': function () {
                var radios = this.$el.find('.pause-option input[type="radio"]');
                var resumeInfo = this.$el.find('.resume-info');
                var trackingField = this.$el.find('[name="tracking_label"]');

                radios.change(function () {
                    var radio = $(this);

                    resumeInfo.removeClass('visible');

                    if (radio.prop('checked')) {
                        var dateString = radio.val();
                        var prettyValue = radio.data("pretty-value");
                        var label = radio.data('label');

                        trackingField.val(label);

                        if (dateString !== 'manual') {
                            resumeInfo.addClass('visible');
                            resumeInfo.find('.value')
                                .prop('datetime', dateString)
                                .text(prettyValue);
                        }
                    }
                });
            }
        }
    );


    t.controllers.define(
        'exclusions',
        {
            'change input[type=checkbox][name="allergen_id"]': 'onAllergenCheckboxClick',
            'change input[type=checkbox][name="exclusions-allergen_id"]': 'onAllergenCheckboxClick',
            'change input[type="checkbox"][name="allergen-category"]': 'onCategoryCheckboxChange',
            'click .tip a.close': 'onCloseTipClick',
            'click #exclusion-reset': 'onAllergenResetClick'
        },
        {
            'init:after': function () {
                this.data = JSON.parse(this.$el.find('.allergen-data').html());
                this._allergenMap = this.data.allergen_map;
                this._categories = this.data.categories;
                this._updateMutualExclusions();
            },
            onAllergenCheckboxClick: function () {
                this._updateMutualExclusions();
            },
            onAllergenResetClick() {
                document.querySelectorAll('input[name=allergen_id], input[name=exclusions-allergen_id]')
                    .forEach(e => {
                        e.disabled = false;
                        e.checked = false;
                    })
                this._updateMutualExclusions();
            },
            onCloseTipClick: function (e) {
                this.$el.find(".tip-input").prop("checked", false);
                e.preventDefault();
                e.target.focus();
            },
            onCategoryCheckboxChange: function (e) {
                var $checkbox = $(e.target);
                var category = $checkbox.val();
                var shouldCheckAllergens = $checkbox.prop('checked');
                this.toggleCategory(category, shouldCheckAllergens);
            },
            toggleCategory: function (category, on) {
                if (on) {
                    this.$el.find("#exclusions-yes").prop("checked", true);
                }

                category = this._categories[category];
                $.each(category.allergen_ids, t.util.bind(function (index, allergenId) {
                    this.findWithinDOM('#allergen_id_' + allergenId).prop('checked', on);
                }, this));

                this._updateMutualExclusions();
            },
            _updateMutualExclusions: function () {
                var that = this;
                var allergenCheckMap = [];
                var selectedAllergenIds = [];

                // All IDs we expect to get a status for
                let remainingIDs = []
                if (that && that._allergenMap) {
                    remainingIDs = Object.keys(that._allergenMap)
                }

                this.findWithinDOM('.allergens input:checkbox').each(function () {
                    var $this = $(this);
                    allergenCheckMap.push(
                        [$this.val(), $this.prop('checked')]
                    );
                    if ($this.prop('checked')) {
                        selectedAllergenIds.push(parseInt($this.val(), 10))
                    }
                    remainingIDs.splice(remainingIDs.indexOf($this.val()), 1)
                }).get();

                remainingIDs.forEach(function name(id) {
                    // Add an entry indicating unchecked state for any expected allergen IDs not found in DOM
                    allergenCheckMap.push([id, 0]);
                })

                var lookupInt = 0;
                allergenCheckMap.sort(function (a, b) {
                    return a[0] - b[0]
                });

                for (var i = 0; i < allergenCheckMap.length; i++) {
                    lookupInt = lookupInt | ((allergenCheckMap[i][1] ? 1 : 0) << i);
                }

                var incompatibleAllergenIds = [];
                this.findWithinDOM('.allergens input:checkbox').each(function () {
                    var $this = $(this);
                    if (that._allergenMap[$this.val()].indexOf('|' + lookupInt + '|') >= 0) {
                        $this.attr('disabled', 'disabled');
                        incompatibleAllergenIds.push(parseInt($this.val(), 10))
                    } else {
                        $this.removeAttr('disabled');
                    }
                });

                // Checks if there are conflicting allergens selected disables submit and
                // displays error
                if (incompatibleAllergenIds.length > 0) {
                    var conflictingAllergenError = false;
                    selectedAllergenIds.forEach(function (selectedAllergenId) {
                        if (incompatibleAllergenIds.indexOf(selectedAllergenId) >= 0) {
                            conflictingAllergenError = true;
                            $('button[type=submit]').attr('disabled', '');
                        }
                    });
                    if (conflictingAllergenError === true) {
                        $('#exclusion-error').removeClass('hidden');
                    } else {
                        $('#exclusion-error').addClass('hidden');
                        $('button[type=submit]').removeAttr('disabled', '');
                    }
                } else {
                    $('#exclusion-error').addClass('hidden');
                    $('button[type=submit]').removeAttr('disabled', '');
                }

                // Disable categories that would check these allergens, and check/undeck ones whose requirements
                // have been met
                this.findWithinDOM('.categories input:checkbox').each(function () {
                    var $this = $(this);

                    $this.removeAttr('disabled');

                    var category = that._categories[$this.val()];
                    if (category == undefined) {
                        return;
                    }
                    var allCategoryExclusionsSelected = true;
                    $.each(category.allergen_ids, function (index, allergenId) {
                        if ($.inArray(allergenId, incompatibleAllergenIds) !== -1) {
                            $this.attr('disabled', 'disabled');
                        }
                        if ($.inArray(allergenId, selectedAllergenIds) === -1) {
                            allCategoryExclusionsSelected = false;
                        }
                    });

                    $this.prop('checked', allCategoryExclusionsSelected);
                });
            }
        }
    );


    t.controllers.define(
        'confirm-pause',
        {},
        {
            'init:after': function () {
                var checkboxes = this.$el.find('input[type="checkbox"][name="delay_reason_id"]');
                var tips = this.$el.find('li.tip');

                function updateTips() {
                    tips.hide();

                    checkboxes.each(function () {
                        var checkbox = $(this);
                        var id = checkbox.val();

                        if (checkbox.is(':checked')) {
                            tips.filter(function (index, el) {
                                return $(el).data('reasonId') == id;
                            }).show();
                        }
                    });
                }

                checkboxes.change(function () {
                    updateTips();
                });

                updateTips();
            }
        }
    );

    t.controllers.define(
        'vets',
        {},
        {
            'init:after': function () {
                this.$el.find('.dropdowns .dropdown').each(function () {
                    var elem = $(this);

                    elem.find('.header').click(function (event) {
                        elem.toggleClass('open');
                        event.preventDefault();
                    });
                });

                var xhr = null;

                this.$el.find('#contact form').submit(function () {
                    var form = $(this);

                    form.find('.error').remove();

                    if (xhr) {
                        xhr.abort();
                    }

                    xhr = $.post(form.prop('action'), form.serialize(), function (resp, result) {
                        if (result === 'success') {
                            if (!resp.success) {
                                for (var name in resp.errors) {
                                    var fieldErrors = resp.errors[name];

                                    var fieldContainer = form.find('.field').filter(function (i, elem) {
                                        return $(elem).data('name') === name;
                                    });

                                    if (fieldContainer.length > 0) {
                                        for (var i = 0; i < fieldErrors.length; i++) {
                                            var error = $('<p class="error"></p>').text(fieldErrors[i]);

                                            fieldContainer.find('.input').append(error);
                                        }
                                    }
                                }
                            } else {
                                t.ui.alert.show(
                                    '',
                                    '',
                                    'Thank you, you are now being redirected to the homepage.',
                                    [],
                                    null
                                );

                                setTimeout(function () {
                                    window.location = 'http://tails.com';
                                }, 2000);
                            }
                        } else {
                            form[0].submit();
                        }
                    });

                    return false;
                });
            }
        }
    );

    $('.ingredients-directory').each(function () {
        var container = $(this);
        var lettersScroll = container.find('.letters');
        var letters = container.find('.letter');
        var ingredients = container.find('li');
        var descriptions = container.find('.description');
        var controls = container.find('.control');

        letters.click(function () {
            var trigger = $(this);
            var letter = trigger.data('letter');

            ingredients.removeClass('visible');

            var matchingIngredients = ingredients.filter(function (i, item) {
                return $(item).data('letter') === letter;
            });

            matchingIngredients.addClass('visible').first().click();

            descriptions.removeClass('mobile-visible');


            matchingIngredients.each(function () {
                var trigger = $(this);
                var ingredientId = trigger.data('ingredientId');
                var additiveId = trigger.data('additiveId');
                var compareKey = ingredientId ? 'ingredientId' : 'additiveId';
                var compareId = ingredientId ? ingredientId : additiveId;

                descriptions.filter(function (i, item) {
                    return $(item).data(compareKey) === compareId;
                }).addClass('mobile-visible');
            });

            letters.removeClass('selected');
            trigger.addClass('selected');

            return false;
        });


        ingredients.click(function () {
            var trigger = $(this);
            var ingredientId = trigger.data('ingredientId');
            var additiveId = trigger.data('additiveId');
            var compareKey = ingredientId ? 'ingredientId' : 'additiveId';
            var compareId = ingredientId ? ingredientId : additiveId;

            descriptions.removeClass('visible');
            descriptions.filter(function (i, item) {
                return $(item).data(compareKey) === compareId;
            }).addClass('visible');

            ingredients.removeClass('selected');
            trigger.addClass('selected');
        });

        // Width of single letter
        function getLetterWidth() {
            return letters.first().outerWidth(true);
        }

        function resizeLettersContainer() {
            lettersScroll.width('auto');

            var letterWidth = getLetterWidth();
            lettersScroll.width(numberOfLettersInView() * letterWidth);

            var currentScroll = lettersScroll.scrollLeft();
            var letterWidth = getLetterWidth();
            lettersScroll.scrollLeft(Math.floor(currentScroll - (currentScroll % letterWidth)));
        }

        function numberOfLettersInView() {
            var letterWidth = getLetterWidth();
            var containerWidth = lettersScroll.width();

            return Math.floor(containerWidth / letterWidth);
        }

        resizeLettersContainer();
        $(window).resize(resizeLettersContainer);

        controls.click(function () {
            lettersScroll.stop();

            var trigger = $(this);
            var currentScrollLeft = lettersScroll.scrollLeft();
            var scrollTo = currentScrollLeft;
            var scrollDelta = getLetterWidth() * numberOfLettersInView();

            if (trigger.hasClass('next')) {
                scrollTo += scrollDelta;
            } else {
                scrollTo -= scrollDelta;
            }

            var duration = Math.max(currentScrollLeft, scrollTo) - Math.min(currentScrollLeft, scrollTo);

            lettersScroll.animate({scrollLeft: scrollTo}, 500);

            return false;
        });

        letters.not('.disabled').first().click();
    });

    t.controllers.define(
        'scroll-anchors',
        {},
        {
            "init:after": function () {
                var anchors = this.$el.find('a');

                anchors.click(function () {
                    var trigger = $(this);
                    var matches = trigger.prop('href').match(/#.+/);
                    if (matches) {
                        var target = $(matches[0]);
                        t.animatedScrollTop(target);
                        return false;
                    }
                });

                anchors.each(function () {
                    var trigger = $(this);
                    var matches = trigger.prop('href').match(/#.+/);
                    if (matches) {
                        var target = $(matches[0]);

                        if (target.is($(window.location.hash))) {
                            t.animatedScrollTop(target);
                            return false;
                        }
                    }
                });
            }
        }
    );

    t.controllers.define(
        'filter-list',
        {
            'keyup .search': "onKeypress",
            'keydown .search': "onKeydown",
            'blur .search': "onSearchBlur",
            'click .item': "onItemClick"
        },
        {
            'init:after': function () {
                this.$el.removeClass("nojs");
                this.event_ns = this.$el.data("event");
                this.search = this.$el.find("input.search");
                this.input = this.$el.find(".value select");
                this.list = this.$el.find('.list');
                this.empty_message = this.list.data("empty-message");
                this.search_timeout = null;

                const sticky_elem = this.$el.find('.sticky');
                if (sticky_elem.length !== 0) {
                    this.sticky = {
                        name: sticky_elem.html(),
                        id: sticky_elem.data('value'),
                    };
                }
                this.list.html("");

                t.message.subscribe(this.event_ns, 'results', t.util.bind(this.processResults, this));
            },
            setState(state) {
                this.$el.attr("data-state", state);
            },
            getState() {
                return this.$el.data("state");
            },
            onSearchBlur(e) {
                setTimeout(t.util.bind(function () {
                    if (!this.search.val()) {
                        this.input.val("");
                        this.setState('init');
                    } else if (this.input.val()) {
                        this.setState("complete");
                    } else if (this.search.val().length > 2) {
                        this.setState("searching");
                    }
                }, this), 300);
            },
            onKeydown(e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            onKeypress(e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.input.val() === "") {
                        this.selectNext();
                    }
                    this.search.blur();
                    return
                } else if (e.keyCode === 38) {
                    this.selectPrevious();
                } else if (e.keyCode === 40) {
                    this.selectNext();
                } else {
                    var input = $(e.target);
                    var value = input.val();
                    this.onSearchChange(value);
                }
                this.search.focus();
            },
            selectPrevious() {
                var prev = this.list.find(".selected").prev();
                if (prev.length !== 0) {
                    this.selectItem(prev);
                }
            },
            selectNext() {
                var selected = this.list.find(".selected");
                var next = selected.next();
                if (next.length === 0) {
                    next = this.list.find(".item:first-child");
                }
                this.selectItem(next);
            },
            onItemClick(e) {
                var target = $(e.target);
                this.selectItem(target);
                this.search.blur();
            },
            onSearchChange(value) {
                if (this.getState() !== 'searching' && value.length > 2) {
                    this.setState('searching');
                    this.input.val("");
                }
                if (value.length < 2) {
                    this.setState('init');
                }

                clearTimeout(this.search_timeout);
                this.search_timeout = setTimeout(
                    t.util.bind(this.applyFilter, this, value),
                    300,
                );
            },
            applyFilter(value) {
                if (value) {
                    t.message.publish(this.event_ns, 'apply-filter', value);
                }
            },
            selectItem(item) {
                var value = item.data("value");

                item.addClass("selected");
                item.siblings().removeClass("selected");
                t.message.publish(this.event_ns, 'item-selected', item);
                this.input.val(value);
                this.search.val(item.text());
                this.setState('complete');
            },
            processResults(data) {
                const search_term = this.search.val();

                if (search_term.length <= 1) {
                    this.list.html("");
                    return;
                }

                const results = data.data;
                if (results.length === 0) {
                    this.list.html(`<div class="item empty">${this.empty_message}</div>`);
                    return;
                }

                let html = '';
                $.each(results, (_i, item) => {
                    html += `<div class="item" data-value="${item.id}">${item.name}</div>`;
                });

                if (search_term.length > 3 && this.sticky !== undefined) {
                    html += `<div class="item sticky" data-value="${this.sticky.id}">${this.sticky.name}</div>`;
                }

                this.list.html(html);
            },
        },
    );

    t.controllers.define(
        'fuzzy-filter',
        {},
        {
            'init:after': function () {
                this.event_ns = this.$el.data('event');
                this.json = JSON.parse($('.list-data').html()).items;
                this.id_lookup = {};
                this.items = [];
                // this.current_id = null;
                this.no_result_timer = null;
                this.max_list_length = 5;

                $.each(this.json, t.util.bind((i, item) => {
                    this.items.push(item);
                    this.id_lookup[item.id] = item;
                }, this));

                this.fuse = new Fuse(this.items, {
                    keys: ['name', 'metadata'],
                    shouldSort: true,
                    threshold: 0.45,
                    location: 0,
                    distance: 75,
                    maxPatternLength: 20,
                    minMatchCharLength: 2,
                });

                t.message.subscribe(
                    this.event_ns,
                    'apply-filter',
                    t.util.bind(this.applyFilterMessage, this),
                );
            },
            applyFilterMessage(data) {
                const results = this.applyFilter(data.data);
                this.handleResults(results);
            },
            applyFilter(search_term) {
                const matches = this._substringMatcher(search_term);
                const match_count = matches.length;
                if (match_count < this.max_list_length) {
                    const fuzzies = this._fuzzyMatcher(search_term);
                    if (fuzzies.length) {
                        for (const fuzzy of fuzzies) {
                            if (matches.indexOf(fuzzy) === -1) {
                                matches.push(fuzzy);
                            }
                            if (matches.length === this.max_list_length) {
                                break;
                            }
                        }
                    }
                }

                // Log breeds not found
                if (search_term && matches.length === 0 && this.options.logApiEndpoint) {
                    this.logNoResults(search_term);
                }
                // limit the results returned
                return matches.slice(0, this.max_list_length);
            },
            handleResults(results) {
                t.message.publish(this.event_ns, 'results', results);
            },
            _fuzzyMatcher(search_term) {
                const results = [];
                const search_results = this.fuse.search(search_term);

                const to_remove = [].concat();
                for (const item of search_results) {
                    if ($.inArray(item.id, to_remove) <= -1) {
                        results.push(item);
                    }
                }

                return results;
            },
            _substringMatcher(search_term) {
                const matches = [];
                if (search_term) search_term = search_term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const substrRegex = new RegExp(search_term, 'i');

                $.each(this.items, t.util.bind((_index, item) => {
                    if (substrRegex.test(item.name)) {
                        matches.push(item);
                    } else if (item.aliases) {
                        $.each(item.aliases, t.util.bind((alias) => {
                            if (substrRegex.test(alias)) {
                                matches.push(
                                    $.extend(
                                        {},
                                        item,
                                        {name: `${item.name} (${alias})`},
                                    ),
                                );
                                return false;
                            }
                            return true;
                        }, this));
                    }
                }, this));
                return matches;
            },
            logNoResults(search_term) {
                if (this.no_result_timer) {
                    window.clearTimeout(this.no_result_timer);
                }

                const {options} = this;
                this.no_result_timer = window.setTimeout(() => {
                    $.post(options.logApiEndpoint, {
                        input: search_term,
                        matches: 0,
                        pet_id: options.petId,
                        csrf_token: options.csrfToken,
                    });
                }, 1000);
            },
        },
    );

    t.controllers.define(
        'pricing',
        {},
        {
            "init:after": function () {
                this.$el.find('.testimonials').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    adaptiveHeight: true
                });
            }
        }
    );


    t.controllers.define(
        'reviews-page',
        {},
        {
            'init:after': function () {
                this.$el.find('.blog-reviews').slick({
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                $('.review-tiles .tile').click(function () {
                    $(this).toggleClass('show-overlay');
                });
            }
        }
    );

    t.controllers.define(
        'team-info',
        {
            'click .dog .img': 'onToggleClick',
            'click .close': 'onCloseClick',
        },
        {
            'init:after': function () {
                this.dogs = this.$el.find('.dog');
                this.info = this.$el.find('.dog-info');
                this.img = this.$el.find('.img');
                this.caret = this.$el.find('.caret');
                this.close = this.$el.find('.close');
            },
            onToggleClick: function (e) {
                var parent = $(e.target).parent();
                $('.show-info').removeClass('show-info');
                $('.show-caret').removeClass('show-caret');
                parent.find('.dog-info').addClass('show-info');
                parent.find('.caret').addClass('show-caret');
            },
            onCloseClick: function () {
                $('.show-info').removeClass('show-info');
                $('.show-caret').removeClass('show-caret');
            }
        }
    );

    t.controllers.define(
        'delivery-options',
        {
            'click .tab': 'onTabClick'
        },
        {
            'init:after': function () {
                this.$tabs = this.$el.find('.tabs .tab');
                this.$pages = this.$el.find('.pages .page');
            },
            onTabClick: function (event) {
                var tab = $(event.target);

                this.$tabs.removeClass('active');
                this.$pages.removeClass('active');

                tab.addClass('active');
                this.$pages.eq(tab.index()).addClass('active');
            }
        }
    );

    t.controllers.define(
        'checkout-orders',
        {
            'click #show-first': 'onTrialClick',
            'click #show-next': 'onOngoingClick',
        },
        {
            'init:after': function () {
                this.refresh_url = this.$el.data("refresh-url");
                this.to_replace = this.$el.data("replace");
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.refresh, this));
                t.message.subscribe('price-change', 'changed', t.util.bind(this.refresh, this));
                t.message.subscribe('promo-widget', 'changed', t.util.bind(this.showTrialPricingTable, this));
                t.message.subscribe('price-change', 'changed', t.util.bind(this.showTrialPricingTable, this));
                this.showTrialPricingTable();
            },
            refresh: function (data) {
                this.$el.find('.loader-shroud').addClass("show");
                $.get(
                    this.refresh_url,
                    t.util.bind(function (html) {
                        ;this.$el.find('.loader-shroud').removeClass("show");
                        html = $($.parseHTML($.trim(html)));
                        this.$el.find(this.to_replace).replaceWith(html.find(this.to_replace));
                        this.showTrialPricingTable();
                    }, this)
                )
            },
            onTrialClick: function () {
                this.showTrialPricingTable('block')
            },
            onOngoingClick: function () {
                this.showOngoingPricingTable('block')
            },
            showTrialPricingTable(display = 'block') {
                let trialTable = document.querySelector('.trial-price-table');
                if (trialTable) {
                    trialTable.style.display = display
                }

                if (display === 'block') {
                    this.showOngoingPricingTable('none')
                }
            },
            showOngoingPricingTable(display = 'block') {
                let ongoingTable = document.querySelector('.next-price-table');
                if (ongoingTable) {
                    ongoingTable.style.display = display
                }

                if (display === 'block') {
                    this.showTrialPricingTable('none')
                }
            },
        }
    );

    t.controllers.define(
        'fyf-widget',
        {
            'click .copy-link': 'onCopyLinkClick'
        },
        {
            'init:after': function () {
                this.code = this.$el.find("input[name=your-code]");
            },
            onCopyLinkClick: function (e) {
                this.code.select();
                document.execCommand("copy");
            }
        }
    );

    t.controllers.define(
        'image-zoom',
        {},
        {
            'init:after': function () {
                this.clone = null;

                $("body").on("click", ".infographic-viewport", t.util.bind(function (e) {
                    var target = $(e.currentTarget);
                    e.stopPropagation();
                    if (!this.clone) {
                        this.clone = target.clone();
                        this.clone.appendTo("body");
                        this.clone.addClass("dragscroll");
                        dragscroll.reset();
                        $("body").css("overflow", "hidden");
                    }
                }, this));

                $("body").on("click", ".infographic-viewport .close", t.util.bind(function (e) {
                    e.stopPropagation();
                    if (this.clone) {
                        this.clone.remove();
                        this.clone = null;
                        $("body").css("overflow", "auto");
                    }
                }, this));
            }
        }
    );

    t.controllers.define(
        'modal',
        {
            'click .btn': 'closeModal'
        },
        {
            'init:after': function () {
                this.openModal();
            },
            openModal: function () {
                var body = document.querySelector('body');
                var screenMiddle = Math.floor(window.outerHeight / 2);
                body.classList.add('modal-open');
                window.scrollTo(0, screenMiddle);
            },
            closeModal: function () {
                var body = document.querySelector('body');
                body.classList.remove('modal-open');
            }
        }
    )

    t.controllers.define(
        'dog-message-module',
        {
            'click .close': 'closeModule'
        },
        {
            'init:after': function () {
            },
            closeModule(e) {
                const module = document.querySelector('.dog-message-module');
                e.preventDefault();
                module.classList.add('hidden');
            }
        }
    )

    t.controllers.define(
        'fyf-share',
        {
            'click .share-facebook': 'onFacebookClick',
            'click .share-messenger': 'onFacebookMessengerClick',
            'click .link': 'onCopyLinkClick',
            'click .your-code': 'onCopyRafClick',
            'click .your-link': 'onCopyRafClick',
            'click .native-share': 'onNativeShareLinkClick',
            'click .share-whatsapp': 'onWhatsappClick',
            'click .share-email': 'onEmailClick',
            'click [data-track-click]': 'trackGenericClick',
        },
        {
            'init:after': function () {
                this.postcard_template = this.$el.find('.postcard-send-html');
                this.alert = null;
                this.facebookWidgetAlert = this.$el.find('.facebook-widget-alert');
                this.isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (navigator.share && isMobile) {
                    this.$el.find('.raf-widget-share').addClass("hidden");
                    this.$el.find('.btn-share.link').addClass("hidden");
                    this.$el.find('.native-share').removeClass("hidden");
                }
                this.shareLink = this.$el.data('share-link');
                this.shareTitle = this.$el.data('share-title');
                this.shareDescription = this.$el.data('share-description');
                this.pathName = window.location.pathname;
            },
            onCopyLinkClick: function (e) {
                e.preventDefault();
                this.optimizelyTrackApi('clicked_share_link_uk');
                var referAFriendInput = document.getElementById('your-code');
                this.ClipBoard().copy(referAFriendInput);
                this.$el.find('.link').addClass('copy-active');
            },

            onCopyRafClick: function (e) {
                e.preventDefault();

                // On the new RAF page in accounts, we have two forms of sharing: link and code.
                // We determine which copy link the customer clicks in order to copy the right input
                // value and update the correct `copy` to `copied`
                let referType = "";
                if (e.currentTarget.classList.contains("your-code")) referType = "your-code";
                if (e.currentTarget.classList.contains("your-link")) referType = "your-link";

                if (referType !== "") {
                    this.optimizelyTrackApi("clicked_share_link_uk");
                    let referAFriendInput = document.getElementById(referType);
                    this.ClipBoard().copy(referAFriendInput);
                    this.$el.find(`.${referType}`).addClass("copy-active");
                }
            },

            optimizelyTrackApi: function (event) {
                $.ajax({
                    type: 'GET',
                    url: '/api/optimizely/track/?event=' + event,
                    dataType: 'json',
                    success: (response) => {
                    },
                });
            },

            ClipBoard: function () {
                return {
                    copy: function (element) {
                        this.selectText(element);
                        this.copyToClipboard();
                    },
                    selectText: function (element) {
                        var selection = window.getSelection();
                        selection.removeAllRanges();
                        element.focus();
                        element.setSelectionRange(0, 999999);
                    },
                    copyToClipboard: function () {
                        document.execCommand('copy');
                    }
                }
            },
            onFacebookClick: function (e) {
                e.preventDefault();
                this.optimizelyTrackApi('clicked_share_link');
                if (FB && FB.ui) {
                    FB.ui({
                        method: 'feed',
                        link: this.data.link,
                        name: this.data.name,
                        description: this.data.share_description,
                        picture: this.data.facebook_picture
                    }, function (response) {
                    });
                } else {
                    t.ui.alert.show(
                        '',
                        '',
                        this.facebookWidgetAlert.html(),
                        [t.ui.alert.BUTTON_CANCEL],
                        t.util.bind(function () {
                            return;
                        })
                    );
                }
            },
            onFacebookMessengerClick: function (e) {
                this.optimizelyTrackApi('clicked_share_link');
                var target = $(e.currentTarget);
                var link = target.data('link');

                if (FB && FB.ui) {
                    var timeout = setTimeout(function () {
                        FB.ui({
                            method: 'send',
                            link: link
                        });
                    }, 500);
                    document.addEventListener('visibilitychange', function () {
                        clearTimeout(timeout);
                    });
                }
            },
            showPostcardAlert: function () {
                var content = this.postcard_template.clone().html();
                t.ui.alert.show(
                    '',
                    '',
                    content,
                    [
                        t.ui.alert.BUTTON_CANCEL,
                        "Send"
                    ],
                    t.util.bind(function (button) {
                        if (button === "Send") {
                            var form = t.ui.alert.content().find('form');
                            this.onPostcardFormSubmit(form);
                            return false;
                        } else if (button === "Send another") {
                            this.resetPostcard();
                            return false;
                        }
                    }, this)
                );
            },
            resetPostcard: function () {
                var content = this.postcard_template.clone();
                t.ui.alert.content().find('.form').replaceWith(
                    content.find('.form')
                );
                t.ui.alert.content().find('.postcard-widget').removeClass("sent");
                t.ui.alert.buttons([
                    t.ui.alert.BUTTON_CANCEL,
                    "Send"
                ]);
            },
            onPostcardFormSubmit: function (form) {
                var loader = form.find('.loader-shroud');
                $.ajax({
                    type: "post",
                    url: form.attr("action"),
                    data: form.serialize(),
                    success: function (data) {
                        if (data['success'] !== true) {
                            $.each(data['errors'], function (name, error) {
                                form.find("[name=" + name + "]").addClass("error");
                            });
                            form.find('.loader-shroud').removeClass('show');
                        } else {
                            setTimeout(t.util.bind(function () {
                                loader.attr('data-status', "success");
                                loader.find('.message').attr('data-message', 'Sent');
                                t.ui.alert.content().find('.postcard-widget').addClass("sent");
                                t.ui.alert.buttons([
                                    t.ui.alert.BUTTON_CANCEL,
                                    "Send another"
                                ])
                            }, this), 1000);
                        }
                    }
                });
                loader.addClass('show');
            },
            onNativeShareLinkClick: function (e) {
                e.preventDefault();
                this.optimizelyTrackApi('clicked_share_link_uk');
                if (navigator.share && isMobile) {
                    navigator.share({
                        title: this.shareTitle,
                        url: this.shareLink,
                        text: this.shareDescription
                    }).catch(e => {
                        if (e.name != "AbortError" && e.name != "NotAllowedError") throw e
                    })
                }
            },
            onWhatsappClick: function (e) {
                this.optimizelyTrackApi('clicked_share_link_uk');
            },
            onEmailClick: function () {
                this.optimizelyTrackApi('clicked_share_link_uk');
            },
            trackGenericClick: function (e) {
                this.optimizelyTrackApi(e.target.dataset.trackClick);
            }
        }
    );

    t.controllers.define(
        'vue-delivery',
        {},
        {
            'init:after': function () {
                this.load();
            },
            displayApp(selector) {
                document.querySelector(selector).style.display = 'block';
            },
            load() {
                const form = document.querySelector('.legacy-form');
                const methods = JSON.parse(document.querySelector('.methods').innerText.trim());
                const getDeliveryDatesURL = document.querySelector('.delivery-dates-url').innerText.trim();
                const getCollectionPointsURL = '/api/gfs/collection-points';
                const singlePointURL = '/api/gfs/collection-point';
                const collectionPointTranslated = document.querySelector('#collection-point-translated').innerText.trim();
                const geocodeKey = document.querySelector('.google-maps-key').innerText.trim();
                const addressErrors = JSON.parse(document.querySelector('.legacy-form-errors').innerText.trim());
                const paymentProviderUUID = document.querySelector('input#payment-provider-uuid');

                // Helper for fetching form errors from WTForms object
                const getError = (obj, key) => {
                    if (!obj['address']) {
                        return '';
                    }
                    const addressErrors = obj['address'];
                    return (addressErrors[key] && addressErrors[key][0]) || '';
                };

                // Get prefilled values from legacy form
                const getVal = (form, selector) => form.querySelector(selector) ? form.querySelector(selector).value : '';
                const csrfToken = getVal(form, '[name="csrf_token"]');
                const addressCsrfToken = getVal(form, '[name="address-csrf_token"]');
                const deliveryCsrfToken = getVal(form, '[name="your_first_delivery-csrf_token"]');
                const countryCode = getVal(form, '[name="address-country_code"]');
                const recipientName = getVal(form, '[name="address-recipient_name"]');
                const company = getVal(form, '[name="address-company"]');
                const firstLine = getVal(form, '[name="address-first_line"]');
                const secondLine = getVal(form, '[name="address-second_line"]');
                const postcode = getVal(form, '[name="address-postal_code"]');
                const city = getVal(form, '[name="address-city"]');
                const shopName = getVal(form, '[name="address-shop_name"]');
                const shopID = getVal(form, '[name="address-shop_id"]');
                const addressType = getVal(form, '[name="address-address_type"]');
                const serviceID = getVal(document.body, '[name="service_id"]');

                // Share data between components

                const sourceOfTruth = {
                    csrfToken: csrfToken,
                    addressCsrfToken: addressCsrfToken,
                    deliveryCsrfToken: deliveryCsrfToken,
                    countryCode: countryCode,
                    recipientName: recipientName,
                    company: company,
                    firstLine: firstLine,
                    secondLine: secondLine,
                    city: city,
                    postcode: postcode,
                    searchPostcode: '',
                    methods: methods,
                    showCompanyName: false,
                    deliveryDatesReady: false,
                    deliveryDates: [],
                    postcodeError: getError(addressErrors, 'postal_code'),
                    firstLineError: getError(addressErrors, 'first_line'),
                    cityError: getError(addressErrors, 'city'),
                    collectionPoints: [],
                    shopName: shopName,
                    shopID: shopID,
                    addressType: addressType,
                    serviceID: serviceID,
                    postcodeLatLng: null,
                    collectionLatLng: null,
                    map: null,
                    showMapFor: null,
                    locationError: false,
                    paymentProviderUUID: paymentProviderUUID && paymentProviderUUID.value || null
                };


                const deliveryMethods = new Vue({
                    el: '#delivery-methods',
                    delimiters: ['<%', '%>'],
                    data: sourceOfTruth,
                    methods: {
                        chooseMethod: function (type, service_id) {
                            if (this.addressType !== type) {
                                this.postcode = '';
                                this.searchPostcode = '';
                                this.postcodeError = false;
                                this.firstLineError = false;
                                this.showCompanyName = false;
                                this.cityError = false;
                                this.firstLine = '';
                                this.secondLine = '';
                                this.city = '';
                                this.company = '';
                            }
                            this.addressType = type;
                            this.serviceID = service_id;
                            this.deliveryDatesReady = false;
                        }
                    }
                });
                this.displayApp('#delivery-methods');

                const collectionPoints = new Vue({
                    el: '#collection-points',
                    delimiters: ['<%', '%>'],
                    data: sourceOfTruth,
                    methods: {
                        getDeliveryPoints() {
                            // Reset (in case of a positive and then negative search)
                            this.deliveryDates = [];
                            this.collectionPoints = [];
                            fetch(`${getCollectionPointsURL}/${this.searchPostcode}`)
                                .then(resp => {
                                    if (resp.status !== 200) {
                                        this.postcodeError = true;
                                        return
                                    }
                                    this.postcodeError = false;
                                    return resp.json()
                                })
                                .then(data => {
                                    if (!data || data.length === 0) {
                                        this.postcodeError = true;
                                        return
                                    }
                                    this.postcodeError = false;
                                    this.collectionPoints = data;
                                    this.collectionPoints.sort((a, b) => a.distance - b.distance);
                                    for (let i = 0; i < this.collectionPoints.length; i++) {
                                        // Metres -> KM
                                        this.collectionPoints[i].distance = (this.collectionPoints[i].distance / 1000).toString().replace(".", ",");
                                    }
                                    this.getDeliveryPointDetails(this.collectionPoints[0].shop_id);
                                });
                        },
                        getDeliveryPointDetails(shopID) {
                            fetch(`${singlePointURL}/${shopID}`)
                                .then(resp => resp.json())
                                .then(data => {
                                    const foundPoint = this.collectionPoints.find(point => point.shop_id === shopID);
                                    foundPoint.opening_hours = data.opening_hours;
                                    const [firstLine, postcode] = foundPoint.address;
                                    this.shopID = data.shop_id;
                                    this.shopName = data.title;
                                    this.firstLine = firstLine;
                                    this.postcode = postcode;
                                    this.city = data.city;
                                    this.getDeliveryDates();
                                    const point = {
                                        latitude: data.latitude,
                                        longitude: data.longitude,
                                    }
                                    this.setMap(point);
                                });
                        },
                        getDeliveryDates() {
                            fetch(getDeliveryDatesURL, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    'service_id': this.serviceID,
                                    'limit': 12,
                                    'payment_provider_uuid': this.paymentProviderUUID,
                                })
                            }).then(res => {
                                if (res.status === 200) {
                                    res.json().then(data => {
                                        data.windows.forEach(date => this.deliveryDates.push(date));
                                        this.deliveryDatesReady = true;
                                        this.postcodeError = false;
                                    });
                                } else {
                                    this.postcodeError = true;
                                }
                            });
                        },
                        // Get user's lat/long from browser API, reverse geocode into a postcode
                        useMyLocation() {
                            const success = geo => {
                                this.locationError = false;
                                const lat = geo.coords.latitude;
                                const long = geo.coords.longitude;
                                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${geocodeKey}`)
                                    .then(res => res.json())
                                    .then(json => {
                                        for (const result of json.results) {
                                            for (const line of result.address_components) {
                                                if (line.types.includes('postal_code')) {
                                                    this.searchPostcode = line.short_name || line.long_name;
                                                    return;
                                                }
                                            }
                                        }
                                    })
                            };
                            const error = () => {
                                this.locationError = true;
                            }
                            navigator.geolocation.getCurrentPosition(success, error);
                        },
                        // Display a shop's map, pass `null` to hide all
                        toggleMap(shopID) {
                            this.showMapFor = shopID;
                        },
                        // Create map instance, set markers, takes: `{latitude: 0, longitude: 0}`
                        setMap(point) {
                            const render = (google) => {
                                const MarkerWithLabel = require('markerwithlabel')(google.maps);

                                // TODO: don't recreate/destroy map instances, use one and move it around
                                this.$el.querySelectorAll('.map').forEach(el => {
                                    el.innerHTML = '';
                                    el.style.display = 'none'
                                });
                                const mapElem = this.$el.querySelector(`.map[data-shop=${this.shopID}]`);
                                mapElem.style.display = 'block';
                                this.collectionLatLng = new google.maps.LatLng(point.latitude, point.longitude);
                                this.map = new google.maps.Map(mapElem, {
                                    zoom: 8,
                                    center: this.collectionLatLng,
                                    mapTypeId: google.maps.MapTypeId.ROADMAP
                                });

                                // Collection point label
                                new MarkerWithLabel({
                                    position: this.collectionLatLng,
                                    map: this.map,
                                    labelContent: collectionPointTranslated,
                                    labelAnchor: new google.maps.Point(22, 0),
                                    labelClass: 'collection-label',
                                    labelStyle: {opacity: 1}
                                });
                                // Scale map
                                this.map.setZoom(14);
                            };
                            this.initMap(render);
                        },
                        // Runs `func` when Google Maps API is loaded or straight away
                        initMap(func) {
                            if (window.google !== undefined) return func(window.google);

                            const CALLBACK_NAME = 'gmapsCallback';
                            window[CALLBACK_NAME] = () => func(window.google);

                            const script = document.createElement('script');
                            script.async = true;
                            script.defer = true;
                            script.src = `https://maps.googleapis.com/maps/api/js?key=${geocodeKey}&callback=${CALLBACK_NAME}`;
                            document.querySelector('head').appendChild(script);
                        },
                    },
                    watch: {
                        // Clear postcode error when user edits postcode
                        searchPostcode() {
                            this.postcodeError = false;
                        }
                    }
                });
                this.displayApp('#collection-points');

                const deliveryAddress = new Vue({
                    el: '#delivery-address',
                    delimiters: ['<%', '%>'],
                    data: sourceOfTruth,
                    methods: {
                        getDeliveryDates() {
                            fetch(getDeliveryDatesURL, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    'postcode': this.postcode,
                                    'limit': 12,
                                    'payment_provider_uuid': this.paymentProviderUUID,
                                })
                            }).then(res => {
                                if (res.status === 200) {
                                    res.json().then(data => {
                                        data.windows.forEach(date => this.deliveryDates.push(date));
                                        this.deliveryDatesReady = true;
                                        this.postcodeError = false;
                                    });
                                } else {
                                    this.postcodeError = true;
                                }
                            });
                        }
                    },
                    watch: {
                        // Clear postcode error when user edits postcode
                        postcode() {
                            this.postcodeError = false;
                        }
                    }
                });
                this.displayApp('#delivery-address');
            }
        }
    )

    t.controllers.define(
        'vue-billing',
        {},
        {
            'init:after': function () {
                this.load();
            },
            displayApp(selector) {
                document.querySelector(selector).style.display = 'block';
            },
            load() {
                // Setup radio buttons if they were rendered
                const yes = document.querySelector('#delivery-address-yes');
                const no = document.querySelector('#delivery-address-no');
                if (yes && no) {
                    const toggleForm = show => document.querySelector('.tails-view-vue-billing').style.display = show ? 'block' : 'none';
                    const hide = yes.checked;
                    if (hide) {
                        toggleForm(false);
                    }
                    no.onclick = () => toggleForm(true);
                    yes.onclick = () => toggleForm(false);
                }

                // Template variables to integrate with legacy form
                const form = document.querySelector('.legacy-form');
                const addressErrors = JSON.parse(document.querySelector('.legacy-form-errors').innerText.trim());
                // Helper for fetching form errors from WTForms object
                const getError = (obj, key) => {
                    let addressError = '';
                    if (obj[key]) {
                        addressError = obj[key][0] ? obj[key][0] : obj[key];
                    }
                    return addressError;
                };

                const paymentProvider = document.querySelector('[name="provider"]').value;

                // Get prefilled values from legacy form
                const addressCsrfToken = form.querySelector('[name="address-csrf_token"]').value;
                const addressType = form.querySelector('[name="address-address_type"]').value;
                const countryCode = form.querySelector('[name="address-country_code"]').value;
                const company = form.querySelector('[name="address-company"]').value;
                const firstLine = form.querySelector('[name="address-first_line"]').value;
                const secondLine = form.querySelector('[name="address-second_line"]').value;
                const postcode = form.querySelector('[name="address-postal_code"]').value;
                const city = form.querySelector('[name="address-city"]').value;
                // Simple method of sharing data between two components
                const sourceOfTruth = {
                    addressCsrfToken: addressCsrfToken,
                    addressType: addressType,
                    countryCode: countryCode,
                    recipientName: '',
                    company: company,
                    firstLine: firstLine,
                    secondLine: secondLine,
                    city: city,
                    postcode: postcode,
                    showCompanyName: false,
                    postcodeError: getError(addressErrors, 'postal_code'),
                    firstLineError: getError(addressErrors, 'first_line'),
                    cityError: getError(addressErrors, 'city'),
                    paymentProvider: paymentProvider
                };

                const billingAddress = new Vue({
                    el: '#billing-address',
                    delimiters: ['<%', '%>'],
                    data: sourceOfTruth,
                    methods: {},
                    watch: {}
                });
                this.displayApp('#billing-address');
            }
        }
    );

    t.controllers.define(
        'accordion-animation',
        {
            'click details': 'onDetailsClick'
        },
        {
            onDetailsClick: function (e) {
                console.log('here');

                if (e.target.tagName == 'SUMMARY' || e.target.classList.contains('title')) {
                    e.preventDefault();
                    let isClosing = false,
                        isExpanding = false,
                        animation = null,
                        detailsElement = this.$el[0].firstElementChild,
                        summaryElement = detailsElement.querySelector('summary'),
                        contentElement = detailsElement.querySelector('.accordion-content');

                    detailsElement.style.overflow = 'hidden';

                    if (isClosing || !detailsElement.open) {
                        open();
                    } else if (isExpanding || detailsElement.open) {
                        shrink();
                    }

                    function shrink() {
                        isClosing = true;
                        detailsElement.classList.add('closing');
                        const startHeight = `${detailsElement.offsetHeight}px`,
                            endHeight = `${summaryElement.offsetHeight}px`;

                        if (animation) {
                            animation.cancel();
                        }

                        animation = detailsElement.animate({
                            height: [startHeight, endHeight]
                        }, {
                            duration: 400,
                            easing: 'ease-out'
                        });

                        animation.onfinish = () => onAnimationFinish(false);
                        animation.oncancel = () => isClosing = false;
                    }

                    function open() {
                        let startHeight = `${detailsElement.offsetHeight}px`
                        detailsElement.height = `${detailsElement.offsetHeight}px`;
                        detailsElement.open = true;
                        window.requestAnimationFrame(() => expand(startHeight));
                    }

                    function expand(startHeight) {
                        isExpanding = true;
                        detailsElement.classList.add('opening');
                        const endHeight = `${summaryElement.offsetHeight + contentElement.offsetHeight}px`;

                        if (animation) {
                            animation.cancel();
                        }

                        animation = detailsElement.animate({
                            height: [startHeight, endHeight]
                        }, {
                            duration: 400,
                            easing: 'ease-out'
                        });

                        animation.onfinish = () => onAnimationFinish(true);
                        animation.oncancel = () => isExpanding = false;
                    }

                    function onAnimationFinish(open) {
                        detailsElement.open = open;
                        detailsElement.classList.remove('closing', 'opening');
                        animation = null;
                        isClosing = false;
                        isExpanding = false;
                        detailsElement.style.height = detailsElement.style.overflow = '';
                    }
                }
            }
        }
    )

    t.controllers.define(
        'pricing-details-form',
        {
            'click #submit-pricing-details': "onSubmit",
        },
        {
            // We don't want to progress the customer unless we have stored the payment uuid server-side
            onSubmit: function () {
                const form = document.querySelector('.tails-view-pricing-details-form');
                const nextBtn = form.querySelector('#submit-pricing-details');
                const notification = document.querySelector('.notification-payment');
                nextBtn.classList.add('btn-progress');

                const error = () => {
                    nextBtn.classList.remove('btn-progress');
                    const translated = document.querySelector('#uuid-error-text');
                    if (notification && notification.querySelector('.notification-title') && notification.querySelector('.notification-message')) {
                        notification.querySelector('.notification-title').innerHTML = translated.dataset.title;
                        notification.querySelector('.notification-message').innerHTML = translated.dataset.message;
                        notification.style.display = 'block';
                    } else {
                        return;
                    }
                };

                const paymentOptions = document.querySelectorAll('.pricing-details-buttons [name=provider]');
                const uuid = [...paymentOptions].find(el => el.checked).dataset.uuid;
                const url = `/api/payment/uuid/${uuid}`;
                fetch(url, {
                    'credentials': 'same-origin'
                })
                    .then(response => {
                        if (response.status !== 200) {
                            error();
                            return;
                        }
                        notification.style.display = 'none';
                        form.submit();
                    })
                    .catch(() => error());
            },
        }
    );

    t.controllers.define(
        'payment-radios',
        {
            'change input[name="provider"]': 'onRadioChange'
        },
        {
            'init:after': function () {
                let providerRadios = document.querySelectorAll('input[name="provider"]');

                providerRadios.forEach(radio => {
                    if (radio.value == "card") {
                        radio.checked = true;
                        document.querySelector('.payment-options').classList.remove('paypal', 'ideal', 'gocardless');
                        document.querySelector('.payment-options').classList.add('card');
                    }
                });
            },
            onRadioChange: function (e) {
                const paymentOptions = document.querySelector('.payment-options');
                let value = e.target.value;

                paymentOptions.classList.remove('paypal', 'card', 'ideal', 'gocardless');
                paymentOptions.classList.add(value);
            }
        }
    );

    t.controllers.define(
        'gocardless-form',
        {},
        {
            'init:after'() {
                this.load();
            },
            load() {
                const form = document.querySelector('.tails-view-gocardless-form');
                const firstName = form.querySelector('input[name="given_name"]').value;
                const lastName = form.querySelector('input[name="family_name"]').value;
                const accountHolder = `${firstName} ${lastName}`;
                const GoCardlessForm = new Vue({
                    el: '#gocardless-form',
                    delimiters: ['<%', '%>'],
                    data: {
                        confirmPaymentDetails: false,
                        submissionError: '',
                        paymentDetails: {
                            firstName,
                            lastName,
                            accountHolder,
                            'Customer or company name': '',
                            'iban': '',
                            'name': 'TAILSCO Limited',
                            'creditor': 'DE5623300002197951',
                            'address': '23 Sheen Road',
                            'type': 'Recurrent',
                            'reference': 'Available after confirmation',
                            'accountNumber': '',
                            'bankCode': '',
                            'countryCode': 'DE',
                            'date': new Date().toLocaleDateString("en-GB"),
                        }
                    },
                    methods: {
                        confirmDetails() {
                            this.confirmPaymentDetails = true;
                        },
                        changeDetails() {
                            this.confirmPaymentDetails = false;
                        },
                        handleError(error) {
                            const fieldMap = {
                                'bank_code': 'Bitte gib eine gültige 8-stellige Bankleitzahl ein.',
                                'account_number': 'Bitte gib eine gültige 10-stellige Kontonummer ein.',
                                'iban': 'Bitte gib eine gültige IBAN ein.',
                                'validation_failed': 'Bitte gib entweder eine gültige IBAN oder gültige lokale Bankdaten ein.'
                            }
                            const foundError = fieldMap[error] ? fieldMap[error] : 'Wir konnten deine Daten nicht validieren.';
                            this.submissionError = foundError;
                        },
                        handleSubmit() {
                            const accessToken = form.querySelector('#publishable_access_token').value;
                            const {paymentDetails} = this;
                            GoCardless.customerBankAccountTokens.create({
                                publishable_access_token: accessToken,
                                customer_bank_account_tokens: {
                                    account_number: paymentDetails.accountNumber,
                                    iban: paymentDetails.iban,
                                    country_code: paymentDetails.countryCode,
                                    account_holder_name: paymentDetails.accountHolder,
                                    bank_code: paymentDetails.bankCode,
                                }
                            }, (response) => {
                                if (response.error) {
                                    this.confirmPaymentDetails = false;
                                    response.error.errors.forEach(error => {
                                        this.handleError(error.field ? error.field : error.reason);
                                    });
                                } else {
                                    if (paymentDetails.countryCode !== 'DE') {
                                        throw 'Unsupported Country'
                                    }
                                    let iban = paymentDetails.iban;
                                    if (iban === '') {
                                        iban = IBAN.fromBBAN(
                                            paymentDetails.countryCode,
                                            ('00000000' + paymentDetails.bankCode).slice(-8) + ('0000000000' + paymentDetails.accountNumber).slice(-10)
                                        );
                                    }
                                    form.querySelector('#token').value = response.customer_bank_account_tokens.id;
                                    form.querySelector('#internal_iban').value = iban;
                                    form.submit();
                                }
                            })
                        },
                    }
                })
            },
        }
    );

    t.controllers.define(
        'treat-selection',
        {},
        {
            'init:after': function () {
                // Important: the number of items and details pages must match
                const togglers = document.querySelectorAll('.details-toggler');
                const detailsPages = document.querySelectorAll('.details-page');
                const wrapper = $('.wrapper');

                for (let i = 0; i < togglers.length; i++) {
                    const toggler = $(togglers[i]);
                    const detailsPage = $(detailsPages[i]);

                    // Setup button to hide the details page later
                    detailsPage.find('.details-hider').click(() => {
                        detailsPage.hide();
                        wrapper.show();
                        window.scroll(0, 0);
                    });

                    // Move the details page up -- let it live there for now too
                    toggler.click(() => {
                        detailsPage.detach().appendTo('.details-page-show');
                        wrapper.hide();
                        detailsPage.show();
                        window.scroll(0, 0);
                    });
                }
            },
        }
    );

    t.controllers.define(
        'signout',
        {
            'click .signout': 'onSignoutClick'
        },
        {
            onSignoutClick: function () {
                window.sessionStorage.removeItem('suppress-wrong-country-banner')
            }
        }
    );

    t.controllers.define(
        'wftu-delivery-notification',
        {
            'click #close-notification': 'closeNotificationClick',
        },
        {
            closeNotificationClick: function () {
                const notification = document.querySelector('#wftu-delivery-notification');
                notification.classList.add('hidden');

                const curve = document.querySelector('.background-curve-container');
                if (curve.classList.contains('background-curve-container-open-notification')) {
                    curve.classList.remove('background-curve-container-open-notification');
                }
            },
        }
    );

    t.controllers.define(
        'wet-food-top-up',
        {
            'submit': "onFormSubmit",
            'click input[type=radio]': 'onRadioClick',
        },
        {
            onFormSubmit: function (e) {
                $(e.currentTarget).find('button[type="submit"]').addClass('btn-progress');
            },
            onRadioClick: function () {
                $('button[type=submit]').removeAttr('disabled', '');
            }
        }
    );
})($, window.tails);
