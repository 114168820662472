import $ from "jquery"

(function($,t) {

    function DB(key) {
        var store = window.localStorage;

        return {
            get: function() {
                return JSON.parse(store[key] || '{}');
            },
            put: function(data) {
                store[key] = JSON.stringify(data);
            },
            'delete': function() {
                store.removeItem(key);
            }
        };
    }

    // TODO this needs attention
    var jsonData = $('.flow-field-data').html();
    var flowFieldData = jsonData && JSON.parse(jsonData);

    t.controllers.define(
        'flow',
        {
            'submit form': 'onFormSubmit'
        },
        {
            'init:after': function() {
                this.logger.debug('init');

                this._parentStage = this.closest('stage');
                this._stageControllers = this.find('stage');
                this._historyStack = [];

                this._userData = {};
                this._loadFlowRules();

                if (!this.parentStage()) {
                    this._loadFlowUserData();
                }
                this._currentStage = undefined;
                this._initStageData();

                t.message.subscribe('tails', 'init.complete', t.util.bind(this._populateUserData, this));
                t.message.subscribe('tails', 'init.complete', t.util.bind(this.gotoFirstStage, this));
            },
            _loadFlowRules: function() {
                var ruleData = this.findWithinDOM('.flow-rules').html();
                if (ruleData) {
                    this._flowRules = JSON.parse(ruleData);
                }
            },
            _loadFlowUserData: function() {
                // TODO namespace this by flow identifier?
                this._userData = {};
                this._userLocalDB = DB('userData');
                this._userData = this._userLocalDB.get();
            },
            _getDefaultStageId: function() {
                for (var i=0; i<this._stageControllers.length; i++) {
                    var stage = this._stageControllers[i];
                    if (stage.isDefault()) {
                        return stage.stageId();
                    }
                }
            },
            _initStageData: function() {
                this._stageLookup = {};
                this._stageControllers.forEach(function(stage) {
                    this._stageLookup[stage.stageId()] = stage;
                }, this);
            },
            _populateUserData: function() {
                this._stageControllers.forEach(t.util.bind(function(stage) {
                    stage.populateUserData(this._userData);
                }, this));
            },
            _validateUserData: function() {
                if (this._currentStage) {
                    if (!this._currentStage.validate()) {
                        return;
                    }
                }
                return true;
            },
            _validateRoute: function() {
                // iterate through each stage, testing validity of form
                var defaultStage = this.getStageById(this._getDefaultStageId());
                var currentTestStage = defaultStage;
                console.log(currentTestStage);
                // TODO determine invalid route
                while (currentTestStage.stageId() != this._userData.currentStageId
                       && currentTestStage.validate()) {
                    this._historyPush({
                        stageId: currentTestStage.stageId()
                    });
                    currentTestStage = this.getStageById(currentTestStage.getNextStageId());

                    if (!currentTestStage) {
                        this.logger.debug('No route found to stage ' + this._userData.currentStageId);
                        return false;
                    }
                }
                return true;
            },
            _deleteUserData: function() {
                this._userLocalDB.delete();
            },
            _setCurrentStageId: function(stageId) {
                this._userData.currentStageId = stageId;
            },
            _historyPush: function(obj) {
                this._historyStack.push(obj);
                //this.enableBackButton();
            },
            _historyPop: function() {
                var previousHistory = this._historyStack.pop();
                if (this._historyStack.length === 0) {
                //    this.disableBackButton();
                }
                return previousHistory;
            },
            _historyClear: function() {
                this._historyStack = [];
            },
            flowIdentifier: function() {
                return this.$el.data('identifier');
            },
            parentStage: function() {
                return this._parentStage;
            },
            flowRules: function() {
                return this._flowRules;
            },
            getStageById: function(stageId) {
                return this._stageLookup[stageId];
            },
            gotoFirstStage: function() {
                if (this._userData.currentStageId && this._validateRoute()) {
                    this.gotoStage(this._userData.currentStageId);
                } else {
                    // reset currentStage
                    this._currentStage = undefined;
                    // clear out any history we might have created attempting to run _validateRoute()
                    this._historyClear();
                    this.gotoStage(this._getDefaultStageId());
                }
            },
            mergeCurrentStageData: function() {
                if (this._currentStage) {
                    this._currentStage.mergeFormData(this._userData);
                }
            },
            gotoNextStage: function(context) {
                // check validity of current stage first
                if (!this._currentStage.validate()) {
                    return;
                }

                this._historyPush({
                    stageId: this._currentStage.stageId()
                });

                // merge in current form data to calculate next stage

                this.mergeCurrentStageData();

                var nextStageId = this._currentStage.getNextStageId(context);
                if (nextStageId) {
                    this.syncData();
                    this._gotoStage(nextStageId, context);
                } else {
                    // no further stages or flows to continue to
                    this.parentStage().parentFlow().gotoNextStage();
                }
            },
            gotoPreviousStage: function() {
                var previousHistory = this._historyPop();
                if (previousHistory) {
                    this.syncData();
                    this._gotoStage(previousHistory.stageId);
                } else {
                    this.parentStage().parentFlow().gotoPreviousStage();
                }
            },
            gotoStage: function(stageId) {
                this.logger.debug('gotoStage: '+stageId)
                if (this._currentStage) {
                    if (!this._currentStage.validate()) {
                        return;
                    }
                    this.mergeCurrentStageData();
                }

                this._setCurrentStageId(stageId);
                this.syncData();

                this._gotoStage(stageId);
            },
            _gotoStage: function(stageId, context) {
                if (this._currentStage) {
                    this._currentStage.hide();
                }

                var stage = this.getStageById(stageId);

                this._currentContext = context;
                this._currentStage = stage;

                // do we have a particular object we wish this stage to use
                // as context?
                var stageUserData = (context && context.jsonPath) ? this._getDataFromContext(context.jsonPath) : this._userData;
                stage.show(stageUserData);

                this.syncData();
            },
            syncData: function() {
                if (!this.parentStage()) {
                    this._userLocalDB.put(this._userData);
                }
            },
            setUserData: function(userData) {
                this._userData = userData;
            },
            _getDataFromContext: function(contextPath) {
                return Object.byString(this._userData, contextPath);
            },
            onFormSubmit: function(e) {
                this.gotoNextStage();
                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'stage',
        {
            'click button.back': 'onBackButtonClick',
            'click button.next': 'onNextButtonClick'
        },
        {
            'init:after': function() {
                this._userData = undefined;
                this._parentFlow = this.closest('flow');

                var childFlows = this.children('flow');
                if (childFlows) {
                    this._subFlow = childFlows[0];
                }

                this.$back = this.findWithinDOM('button.back');
                this.$next = this.findWithinDOM('button.next');

                if (!this.parentFlow()) {
                    //this.disableBackButton();
                }

                this._populateFieldData();
            },
            hide: function() {
                this.$el.addClass('hide');
            },
            show: function(userData) {
                this._userData = userData;
                if (this.subFlow()) {
                    this.subFlow().setUserData(this._userData);
                    this.subFlow().gotoFirstStage(this._userData);
                } else {
                    // TODO Determine what labels should be on next button.
                    // TODO Disable back button if no previous history and not
                    //      inside a sub flow.
                    this.renderStage(this._userData);
                    this.populateUserData(this._userData);
                }

                this.$el.removeClass('hide');
            },
            validate: function() {
                return true;
            },
            isDefault: function() {
                return this.options.isDefault;
            },
            stageId: function() {
                return this.options.stageId;
            },
            parentFlow: function() {
                return this._parentFlow;
            },
            subFlow: function() {
                return this._subFlow;
            },
            mergeFormData: function(userData) {
                var $formElements = this.findWithinDOM('input,select');
                this._mergeFormData($formElements, userData);

                // loop through child controllers using their custom form data hooks (if provided)
                this.find().forEach(t.util.bind(function(ctor) {
                    if (ctor.mergeFormData) {
                        ctor.mergeFormData(userData);
                    } else {
                        $.extend(
                            userData,
                            this._mergeFormData(ctor.findWithinDOM('input, select'), userData)
                        );
                    }
                }, this));
            },
            _mergeFormData: function($formElements, userData) {
                // TODO optimise
                var radioGroupNames = {};
                $formElements.filter('input:radio').each(function() {
                    if (!radioGroupNames[this.name]) { radioGroupNames[this.name] = 0 }
                    radioGroupNames[this.name]++;
                });
                for (var groupName in radioGroupNames) {
                    delete userData[groupName];

                    var checkedVal = $formElements.filter('input:radio[name='+groupName+']:checked').val();
                    if (checkedVal) {
                        userData[groupName] = checkedVal;
                    }
                }
                var checkboxGroupNames = {};
                $formElements.filter('input:checkbox').each(function() {
                    if (!checkboxGroupNames[this.name]) { checkboxGroupNames[this.name] = 0 }
                    checkboxGroupNames[this.name]++;
                });
                for (var groupName in checkboxGroupNames) {
                    delete userData[groupName];

                    var checkedValues = $formElements.filter('input:checkbox[name='+groupName+']:checked').map(function() {
                        return this.value;
                    }).get();

                    if (checkedValues) {
                        if (checkboxGroupNames[groupName] > 1) {
                            userData[groupName] = checkedValues;
                        } else {
                            userData[groupName] = checkedValues[0];
                        }
                    }
                }

                var multiValueNames = {};
                $formElements.filter("input:hidden[name$='[]']").each(function() {
                    var fieldName = $(this).attr('name').replace('[]','');
                    if (!multiValueNames[fieldName]) { multiValueNames[fieldName] = 0 }
                    multiValueNames[fieldName]++;
                });
                for (var fieldName in multiValueNames) {
                    delete userData[fieldName];
                    var multiValues = $formElements.filter('input:hidden[name="'+fieldName+'[]"]').not(':disabled').map(function() {
                        return this.value;
                    }).get();
                    if (multiValues) {
                        userData[fieldName] = multiValues;
                    }
                }

                $formElements.filter('input:text,input[type=number],input[type=email],select').not(':disabled').each(function() {
                    var $this = $(this);
                    // TODO optimisations
                    if ($this.is(':disabled')) {
                        delete userData[$this.attr('name')];
                    } else {
                        userData[$this.attr('name')] = $this.val();
                    }
                });
            },
            renderStage: function(userData) {
                this.$el.find('.var').each(function() {
                    var fieldName = $(this).data('fieldName');
                    if (fieldName) {
                        $(this).html(userData[fieldName]);
                    }
                });
                /*this.$el.replaceText(/\{\{([^}]+)\}\}/g, function(match, name) {
                    debugger;
                    return userData[name];
                });*/
            },
            getStageRules: function() {
                var rules = this.parentFlow().flowRules();
                return this.parentFlow().flowRules()[this.stageId()];
            },
            getNextStageId: function(context) {
                var rules = this.getStageRules();

                console.log(rules);

                var stageArgs = $.extend({}, this._userData, context);
                for (var i=0; i<rules.length; i++) {
                    var rule = rules[i];
                    this.logger.debug('Testing rule ' + rule.ruleFunc);
                    var func = Function('args', rule.ruleFunc);
                    if (func(stageArgs)) {
                        return rule.targetStageId;
                    }
                }
            },
            populateUserData: function(userData) {
                // populate basic fields just within ourself
                this.findWithinDOM('input:radio,input:checkbox').each(function() {
                    var $this = $(this);
                    var name = $this.attr('name');
                    if (!name) {
                        return;
                    }
                    if (userData[name] && userData[name] === $this.val()) {
                        // toggle any parent labels for button groups
                        $this.attr('checked', true).parent('label.btn').button('toggle');
                        $this.parent('label.btn').button('toggle');
                    }
                });
                this.findWithinDOM('input,select').each(function() {
                    var $this = $(this);
                    if (!$this.attr('name')) {
                        return;
                    }
                    if (userData[$this.attr('name')]) {
                        $this.val(userData[$this.attr('name')]);
                    }
                });
                // find all tails-views inside ourselves and call their populate method
                this.find().forEach(function(ctor) {
                    if (ctor.constructor.type != 'flow' && ctor.populateUserData) {
                        ctor.populateUserData(userData);
                    }
                });
            },
            onNextButtonClick: function(e) {
                this.parentFlow().gotoNextStage({
                    contextType: $(e.target).data('nextContextType')
                });
                e.stopPropagation();
                e.preventDefault();
            },
            onBackButtonClick: function(e) {
                this.parentFlow().gotoPreviousStage();
                e.stopPropagation();
                e.preventDefault();
            },
            _populateFieldData: function() {
                // do not try to populate fields on a stage with a sub flow
                // as these will be handled by the stages in the sub flow themselves
                if (!this._subFlow) {
                    this.findWithinDOM('select').each(function() {
                        var $this = $(this);
                        var canonicalName = $this.attr('name').replace(/\[\d+\]$/, '');
                        if (flowFieldData[canonicalName] && flowFieldData[canonicalName]['choices']) {
                            var $select = $this;
                            flowFieldData[canonicalName]['choices'].forEach(function(item) {
                                $select.append($('<option/>').val(item[0]).text(item[1]));
                            });
                        }
                    });
                    this.find().forEach(function(ctor) {
                        if (ctor.constructor.type != 'flow' && ctor.populateFieldData) {
                            ctor.populateFieldData(flowFieldData);
                        }
                    });
                }
            },
            _enableBackButton: function() {
                this.$back.removeAttr('disabled');
            },
            _disableBackButton: function() {
                this.$back.attr('disabled', 'disabled');
            }
        }
    );

    t.controllers.define(
        'stage.you-and-your-pets',
        {
            onNextButtonClick: function(e) {
                this.parentFlow().gotoNextStage({
                    jsonPath: 'dogs[0]',
                    contextType: $(e.target).data('nextContextType')
                });
                e.stopPropagation();
                e.preventDefault();
            }
        }
    );

    /*
     * Custom controllers
     */

    t.controllers.define(
        'multi-choice',
        {
        },
        function(p) {
            t.util.augment(p, {
                init: t.aspect.after(p.init, function() {
                }),
                populateUserData: function(userData) {
                    var fieldName = this.$el.find('input:checked').attr('name');
                    if (fieldName) {
                        var fieldData = userData[fieldName];
                        if (fieldData) {
                            this.$el.find('input:checked[value='+fieldData+']').parent().button('toggle');
                        }
                    }
                }
            });
        }
    );

    t.controllers.define(
        'flow-field-group',
        function(p) {
            t.util.augment(p, {
                init: t.aspect.after(p.init, function() {
                    this._parentStage = this.closest('stage');
                }),
                parentStage: function() {
                    return this._parentStage;
                }
            });
        }
    );

    t.controllers.define(
        'flow-field-group.pet-gender',
        {
            'change input[type=radio]': 'onButtonClick',
            'change input[type=checkbox]': 'onCheckboxClick'
        },
        {
            'init:after':  function() {
                this.$neutered = this.findWithinDOM('.neutered');
                this.$pregnantNursing = this.findWithinDOM('.pregnant-nursing');
            },
            onButtonClick: function(e) {
                this._valuesChanged();
            },
            onCheckboxClick: function(e) {
                this._valuesChanged();
            },
            populateUserData: function() {
                this._valuesChanged();
            },
            _valuesChanged: function () {
                if (this.findWithinDOM('input[type=radio]:checked').val() === 'male') {
                    this.$neutered.removeAttr('disabled');
                    this.$pregnantNursing.attr('disabled', true).parent();
                } else {
                    this.$pregnantNursing.removeAttr('disabled');
                    if (this.$pregnantNursing.not(':disabled').is(':checked')) {
                        this.$neutered.attr('disabled', true);
                    } else if (this.$neutered.not(':disabled').is(':checked')) {
                        this.$pregnantNursing.attr('disabled', true);
                    } else {
                        this.$pregnantNursing.removeAttr('disabled');
                        this.$neutered.removeAttr('disabled');
                    }
                }
            }
        }
    );

    t.controllers.define(
        'flow-field-group.pet-lifestyle',
        {
            'init:after': function() {
                this.$lifestyles = this.findWithinDOM('.lifestyles');
            },
            populateFieldData: function(flowFieldData) {
                flowFieldData.lifestyles.forEach(t.util.bind(function(lifestyle) {
                    this.$lifestyles.append($('<div class="radio"><label><input type="radio" name="lifestyle" value="' + lifestyle.id + '"><strong>' + lifestyle.name + '</strong> - ' + lifestyle.description + '</label></div>'));
                }, this));
            }
        }
    );

    t.controllers.define(
        'flow-field-group.pet-condition',
        {
            'init:after': function() {
                this.$conditions = this.findWithinDOM('.conditions');
            },
            populateFieldData: function(flowFieldData) {
                flowFieldData.conditions.forEach(t.util.bind(function(condition) {
                    this.$conditions.append($('<div class="radio"><label><input type="radio" name="condition" value="' + condition.id + '"><strong>' + condition.name + '</strong> - ' + condition.description + '</label></div>'));
                }, this));
            }
        }
    );

    t.controllers.define(
        'flow-field-group.pet-name',
        {
            'click a.add-pet': 'onAddPetClick',
            'click a.remove-pet': 'onRemovePetClick'
        },
        {
            'init:after': function() {
                this._nextIndex = 1;
                this.$petNames = this.findWithinDOM('.pet-names');
                this.$newPetTemplate = this.$el.find('.templates .pet-name');

                // add a blank field
                this.addPet();
            },
            addPet: function(pet, index) {
                var $newPet = this.$newPetTemplate.clone();

                // are we adding a new pet
                if (pet) {
                    $newPet.find('input').data('index', index).val(pet.name);
                    $newPet.find('.remove-pet');
                }
                this.$petNames.append($newPet).find('input').focus();

                // enable all remove buttons now we have more than one input
                if (this.$petNames.find('.pet-name').length > 1) {
                    this.$petNames.find('.remove-pet').removeClass('disabled');
                }
            },
            populateUserData: function(userData) {
                // TODO namespacing
                //var fieldName = this.$el.find('input:hidden').attr('name').replace('[]','');
                var fieldName = 'dogs';
                if (fieldName) {
                    var fieldData = userData[fieldName];
                    if (fieldData && fieldData.length > 0) {
                        var index = 0;
                        this.$petNames.html('');
                        fieldData.forEach(t.util.bind(function(pet) {
                            this.addPet(pet, index++);
                        }, this));
                    }
                }
            },
            mergeFormData: function(userData) {
                // TODO namespacing
                var currentData = userData['dogs'];
                var newData = [];
                this.$el.find('input').each(function() {
                    if ($.trim(this.value) === '') {
                        return;
                    }

                    var $this = $(this);
                    if ($this.data('index')) {
                        var pet = currentData[$this.data('index')];
                        pet.name = $this.val();
                        newData.push(pet);
                    } else {
                        newData.push({
                            name: $this.val()
                        });
                    }
                });

                userData['dogs'] = newData;
            },
            onRemovePetClick: function(e) {
                var $target = $(e.target);
                $target.closest('.pet-name').remove();

                if (this.$petNames.find('.pet-name').length === 1) {
                    this.$petNames.find('.remove-pet').addClass('disabled');
                }

                e.preventDefault()
            },
            onAddPetClick: function(e) {
                // clone the first field
                this.addPet();

                this.parentStage().parentFlow().mergeCurrentStageData();

                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'flow-field-group.pet-chooser',
        {
            'click button.add-pet': 'onAddPetButtonClick',
            'click button.remove-pet': 'onRemovePetButtonClick',
            'keyup input.new-pet-name': 'onNewPetNameKeyup',
            'click button.edit-profile': 'onEditProfileButtonClick'
        },
        function(p) {
            t.util.augment(p, {
                init: t.aspect.after(p.init, function() {
                    this._index = 0;
                    t.message.subscribe('flow', 'stage.'+this.closest('stage').stageId()+'.leave', t.util.bind(this._onStageLeave, this));
                    // TODO on stage bypass on way back?? we need a separate flow.....
                }),
                _onStageLeave: function(msg) {
                    var selectedIndex = this.$el.find('button.active').data('index');
                    // TODO namespace
                    //msg.data.flowController.pushStack(msg.data.flowController.userData()['dogs'][selectedIndex]);
                },
                onAddPetButtonClick: function(e) {
                    this.addPet(this.$el.find('input.new-pet-name').val());
                    e.preventDefault();
                },
                addPet: function(pet) {
                    if (!(pet && pet.name)) {
                        return;
                    }
                    var $newPet = this.$el.find('.pet-name-template').clone();

                    $newPet.find('.pet-name').html(pet.name);
                    $newPet.find('input.pet-name-hidden').val(pet.name).removeAttr('disabled');
                    $newPet.find('button.edit-profile').data('index', this._index++);
                    $newPet.removeClass('hide pet-name-template');

                    this.$el.find('.pet-names').append($newPet);

                    this.$el.find('input.new-pet-name').val('').focus();

                    this.parentStage().parentFlow().mergeCurrentStageData();
                },
                onNewPetNameKeyup: function(e) {
                    if (event.keyCode == 13) {
                        this.addPet($(e.target).val());
                    }
                    e.preventDefault();
                },
                onEditProfileButtonClick: function(e) {
                    var index = $(e.target).data('index');
                    this.parentStage().parentFlow().gotoNextStage({
                        jsonPath: 'dogs['+index+']',
                        contextType: 'pet'
                    });
                    e.preventDefault();
                },
                mergeFormData: function(userData) {
                    // custom merge for this view
                    var fieldName = this.$el.find('input:hidden').attr('name').replace('[]','');

                    var names = this.$el.find('input:hidden').not(':disabled').map(function() {
                        return this.value;
                    }).get();

                    // TODO namespacing
                    userData['dogs'] = [];
                    for (var i=0; i<names.length; i++) {
                        userData['dogs'].push({
                            name: names[i],
                            index: i
                        });
                    }
                },
                onPetButtonClick: function(e) {
                    var $target = $(e.target);
                    this.$el.find('button').removeClass('active');
                    $target.addClass('active');
                    // modify the stack
                    e.preventDefault();
                },
                populateUserData: function(userData) {
                    this.$el.find('.pet-names').html('');
                    this._index = 0;
                    // TODO namespacing
                    //var fieldName = this.$el.find('input:hidden').attr('name').replace('[]','');
                    var fieldName = 'dogs';
                    if (fieldName) {
                        var fieldData = userData[fieldName];
                        if (fieldData) {
                            fieldData.forEach(t.util.bind(function(pet) {
                                this.addPet(pet);
                            }, this));
                        }
                    }
                },
                onRemovePetButtonClick: function(e) {
                    var $target = $(e.target);
                    $target.closest('input.hidden');
                    $target.closest('.pet-name-container').remove();

                    e.preventDefault();
                }
            });
        }
    );

})($,window.tails);
