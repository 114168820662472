import $ from "jquery"

(function($, t) {
    t.controllers.define(
        'multi-product-widget',
        {
            'click .btn.add': 'onActionClick',
            'click .btn.remove': 'onActionClick'
        },
        {
            'init:after': function () {
                const widget = this.$el.find(".offer");
                const product_id = widget.attr("data-product-id");
                const pet_id = this.$el.attr("data-pet-id");
                t.message.subscribe('upsell-widget', 'taken', t.util.bind(function(e) {
                    if (e.data.product_type === product_id && e.data.pet_id == pet_id) {
                        widget.attr("data-status", 'taken');
                    }
                }, this));
                t.message.subscribe('upsell-widget', 'not_taken', t.util.bind(function(e) {
                    if (e.data.product_type === product_id && e.data.pet_id == pet_id) {
                        widget.attr("data-status", 'not_taken');
                    }
                }, this));
            },
            onActionClick(e) {
                e.preventDefault();
                const target = $(e.currentTarget);
                const url = target.data("url");
                target.prop("disabled", true);
                const next_delivery_only = target.data("next-delivery-only") === true;
                $.post(
                    url,
                    { 'next_delivery_only': next_delivery_only },
                    t.util.bind(function(response){
                        if(response['success'] === true) {
                            var product_id = response['product_id'];
                            var quantity = response['quantity'];
                            var status = quantity > 0 ? "taken" : "not_taken";
                            t.message.publish(product_id, 'changed');
                            t.message.publish('price-change', 'changed');
                            t.message.publish('upsell-widget', status, {
                                'pet_id': response['pet_id'],
                                'product_type': product_id,
                                'quantity': quantity
                            });
                            target.prop("disabled", false);
                        }
                    }, this)
                )
            }
        }
    );
})($, window.tails);
