import $ from "jquery"
import "slick-carousel"

(function ($, t) {
  if ($("body").hasClass("signup")) {
  $('.section.carousel').slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: true,
    dots: true,
    initialSlide: 0,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 4,
        slidesToScroll: 1
        }
      },
      {
        breakpoint: 570,
        settings: {
          variableWidth: true,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  }

  $('.tails-view-carousel-wrapper').on('swipe', function(event, slick, direction){
    var focusTileId = $(".tile.product-not-in-order.slick-current label").attr('for');
    $("#" + focusTileId).prop("checked", true);
  });

  function formatMoney(money) {
    var out = parseFloat(Math.round(money / 10) / 100).toFixed(2);
    if (out === '0.00') {
      return 'free';
    }
    if(out.indexOf('.00')>=0){
      out = out.replace('.00', '');
    }
    return '£' + out;
  }

    t.controllers.define(
    'feeding-plan-product',
    {
      "init:after": function () {
        this.$el.addClass('has-js');

        var input_field = this.$el.find('.product-quantity');
        if (input_field.length > 0) {
          this.input_field = input_field[0];
        } else {
          this.input_field = null;
        }

        this.update_quantity_url = this.$el.attr('data-update-quantity-url');

        // Used for providing a cooldown on the plus/minus clicking so as not
        // to flood the server, and give the user a more consistant experience.
        this.quantity_timer = null;
        this.cooldown_time = 500; // milliseconds

        this.$el.find('.plusminus.plus').click(this.handlePlusClick.bind(this));
        this.$el.find('.plusminus.minus').click(this.handleMinusClick.bind(this));

        this.$el.find('.feeding-plan-product-adjustment-form').submit(this.handleFormSubmit.bind(this));

        this.$el.find('.js-product-remove-link').click(this.handleRemoveClick.bind(this));
        this.$el[0].update = this.update.bind(this);
        this.$el[0].resetQuantityInput = this.resetQuantityInput.bind(this);
      },
      handlePlusClick: function() {
        if (this.input_field) {
          this.startQuantityLoadingAnimation();
          this.input_field.stepUp();
          clearTimeout(this.quantity_timer);
          this.quantity_timer = setTimeout(this.sendQuantity.bind(this), this.cooldown_time);
        }
      },
      handleMinusClick: function() {
        if (this.input_field) {
          this.startQuantityLoadingAnimation();
          this.input_field.stepDown();
          clearTimeout(this.quantity_timer);
          this.quantity_timer = setTimeout(this.sendQuantity.bind(this), this.cooldown_time);
        }
      },
      handleRemoveClick: function() {
        this.remove();
        return false;
      },
      handleFormSubmit: function() {
        this.startQuantityLoadingAnimation();
        this.sendQuantity();
        return false;
      },
      startQuantityLoadingAnimation: function() {
        this.$el.find('div.description .widget-price-loading').addClass("show");
        this.startPriceLoadingAnimation();
      },
      stopQuantityLoadingAnimation: function() {
        this.$el.find('div.description .widget-price-loading').removeClass("show");
      },
      startPriceLoadingAnimation: function() {
        $('.tails-view-feeding-plan-price-total')[0].startPriceLoadingAnimation();
      },
      update: function() {
        this.$el.find('.js-product-quantity').text(this.getQuantity());
        var total_price = formatMoney(this.calcTotalPrice());
        this.$el.find('.js-product-total-price').text(total_price);
      },
      resetQuantityInput: function() {
        if (this.input_field) {
          this.input_field.value = this.getQuantity();
        } else {
          return;
        }
      },
      remove: function() {
        this.startPriceLoadingAnimation();
        this.sendQuantity(0);
        this.update();
        this.reloadPrice();
        this.$el.removeClass('product-added');
        $('#tile-product-' + this.getProductId()).removeClass('product-in-order').addClass('product-not-in-order');
        $('#tooltip-product-' + this.getProductId()).removeClass('product-in-order').addClass('product-not-in-order');
        var nextNotAddedProduct = $("div.tile.product-not-in-order:first label");
        nextNotAddedProduct.click();
        updateCarouselWrapperPointer();
      },
      getProductId: function() {
        return this.$el.attr('data-product-id');
      },
      getQuantity: function() {
        return this.$el.attr('data-quantity');
      },
      setQuantity: function(quantity) {
        this.$el.attr('data-quantity', quantity);
      },
      sendQuantity: function(quantity) {
        this.startPriceLoadingAnimation();
        if (typeof quantity === 'undefined') {
          if (this.input_field) {
            quantity = this.input_field.value;
          } else {
            return;
          }
        }
        var data = { quantity: quantity }
        $.post(
          this.update_quantity_url, data,
          this.handleSendQuantityResponse.bind(this)
        );
      },
      getUnitPrice: function() {
        return this.$el.attr('data-unit-price');
      },
      calcTotalPrice: function() {
        return this.getUnitPrice() * this.getQuantity();
      },
      handleSendQuantityResponse: function(response) {
        if (response.errors !== null) {
          console.log(response);
          return false;
        }

        this.reloadPrice();
        this.setQuantity(response.quantity);
        this.update();
        this.stopQuantityLoadingAnimation();
      },
      reloadPrice: function() {
        this.startPriceLoadingAnimation();
        var price_view = $('.tails-view-feeding-plan-price-total')
        if (price_view.length > 0) {
          price_view[0].reloadPrice();
        }
      }
    }
  );


  t.controllers.define(
    'feeding-plan-price-total',
    {
      "init:after": function () {
        this.get_price_url = this.$el.attr('data-get-price-url');
        this.$el[0].reloadPrice = this.reloadPrice.bind(this);
        this.$el[0].startPriceLoadingAnimation = this.startPriceLoadingAnimation.bind(this);
        this.$el[0].stopPriceLoadingAnimation = this.stopPriceLoadingAnimation.bind(this);
      },
      reloadPrice: function() {
        this.startPriceLoadingAnimation();
        $.getJSON(this.get_price_url, this.handlePriceResponse.bind(this));
      },
      startPriceLoadingAnimation: function() {
        this.$el.find('.price-loading').addClass("show");
      },
      stopPriceLoadingAnimation: function() {
        this.$el.find('.price-loading').removeClass("show");
      },
      handlePriceResponse: function(response) {
        if (response.success !== true) {
          console.log(response);
          return false;
        }

        this.$el.attr('data-first-shipment-order-days', response.data[1].order_days);
        this.$el.attr('data-first-shipment-total-price-before-discount', response.data[1].price_before_discount);
        this.$el.attr('data-first-shipment-total-price-before-delivery', response.data[1].price_before_delivery);
        this.$el.attr('data-first-shipment-total-price', response.data[1].price);
        this.$el.attr('data-first-shipment-delivery-charge', response.data[1].shipping);

        this.$el.attr('data-second-shipment-order-days', response.data[2].order_days);
        this.$el.attr('data-second-shipment-total-price-before-discount', response.data[2].price_before_discount);
        this.$el.attr('data-second-shipment-total-price-before-delivery', response.data[2].price_before_delivery);
        this.$el.attr('data-second-shipment-total-price', response.data[2].price);
        this.$el.attr('data-second-shipment-delivery-charge', response.data[2].shipping);

        this.update();
        this.stopPriceLoadingAnimation();
      },
      update: function() {
        this.$el
          .find('.js-first-shipment-order-days')
          .text(this.$el.attr('data-first-shipment-order-days'));
        this.$el
          .find('.js-first-shipment-total-price-before-discount')
          .text(formatMoney(this.$el.attr('data-first-shipment-total-price-before-discount')));
        this.$el
          .find('.js-first-shipment-total-price-before-delivery')
          .text(formatMoney(this.$el.attr('data-first-shipment-total-price-before-delivery')));
        this.$el
          .find('.js-first-shipment-delivery-charge')
          .text(formatMoney(this.$el.attr('data-first-shipment-delivery-charge')));
        this.$el
          .find('.js-second-shipment-order-days')
          .text(this.$el.attr('data-second-shipment-order-days'));
        this.$el
          .find('.js-second-shipment-total-price-before-discount')
          .text(formatMoney(this.$el.attr('data-second-shipment-total-price-before-discount')));
        this.$el
          .find('.js-second-shipment-total-price')
          .text(formatMoney(this.$el.attr('data-second-shipment-total-price')));
        this.$el
          .find('.js-second-shipment-delivery-charge')
          .text(formatMoney(this.$el.attr('data-second-shipment-delivery-charge')));
      }
    }
  );


  t.controllers.define(
    'carousel-wrapper',
    {
        'click input[type=radio]': 'onRadioClick'
    },
    {
      "init:after": function () {
          var currentElement = this.$el.find("input:checked").parent().attr('data-index');
          this.selectIndex(currentElement);
          this.$el.find('.js-product-add').click(this.handleAddClick.bind(this));
      },
      onRadioClick: function (e) {
          var index = $(e.target).next().attr("data-index");
          this.selectIndex(index);
      },
      startPriceLoadingAnimation: function() {
        $('.tails-view-feeding-plan-price-total')[0].startPriceLoadingAnimation();
      },

      selectIndex: function(index) {
          $('.tooltip[data-index=' + index + ']').addClass("selected").siblings().removeClass('selected');
          this.$el.find(".widget-tooltip").attr("data-selected", index);
          updateCarouselWrapperPointer();
      },

      handleAddClick: function(e) {
        this.startPriceLoadingAnimation();
        var form = $(e.target).closest('.feeding-plan-add-product-form');
        var quantity = form.attr('data-recommended-quantity');
        var add_product_url = form.attr('data-add-product-url');
        form.find("button").addClass("btn-progress");

        var data = { quantity: quantity }
        $.post(
          add_product_url, data,
          this.handleAddProductResponse.bind(this)
        );

        return false;
      },
      handleAddProductResponse: function(response) {
        if (response.errors !== null) {
          console.log(response);
          return false;
        }

        this.startPriceLoadingAnimation();

        var product_id = response.product_id;
        this.$el
          .find('#tooltip-product-' + product_id)
          .addClass('product-in-order')
          .removeClass('product-not-in-order');
        this.$el
          .find('#tile-product-' + product_id)
          .addClass('product-in-order')
          .removeClass('product-not-in-order');

        this.$el
          .find('#tooltip-product-' + product_id + ' button')
          .removeClass("btn-progress");

        this.reloadPrice();

        var product = $('#product-' + product_id);
        product.addClass('product-added');
        product.attr('data-quantity', response.quantity);
        product[0].update();
        product[0].resetQuantityInput();
        $('html, body').animate({
            scrollTop: (product.offset().top-100)
        },500);
        this.selectNextTile();
        updateCarouselWrapperPointer();
      },
      selectNextTile: function() {
        var nextNotAddedProduct = $("div.tile.product-not-in-order:first label");
        nextNotAddedProduct.click();
      },
      reloadPrice: function() {
        var price_view = $('.tails-view-feeding-plan-price-total')
        if (price_view.length > 0) {
          price_view[0].reloadPrice();
        }
      }
    }
  );

  function updateCarouselWrapperPointer() {
    var wrapper = $('.tails-view-carousel-wrapper');
    var pointer = wrapper.find('.pointer');
    var currentTile = wrapper.find('.slick-current');
    var tileNotInOrder = $('.section.carousel').find('.product-not-in-order');
    var focusTile = $(".tile.product-not-in-order.slick-current label");
    focusTile.click();
    if (pointer.length && tileNotInOrder.length) {
      var tileLeft = currentTile.position().left;
      var tileWidth = currentTile.width() / 2.9;
      pointer.css("display", "block");
      pointer.css("transform", "translate3d(calc(" + tileLeft + "px + " + tileWidth + "px), 0, 0)");
    } else {
      pointer.css("display", "none");
    }
  }

  window.addEventListener('resize', updateCarouselWrapperPointer);
  window.addEventListener('click', updateCarouselWrapperPointer);

})($, window.tails);
