(function(){
    document.getElementsByClassName("icons-info-scope")
        .forEach(scope => {
            let colourCycle = 0;
            scope.getElementsByClassName("icons-info")
                .forEach(e => {
                    const closest_parent_scope = e.closest('.icons-info-scope');
                    if (closest_parent_scope !== scope) {
                        return;
                    }

                    colourCycle++;
                    if (colourCycle >= 5) colourCycle = 1;
                    if (e.nextElementSibling.classList.contains("first-block")) {
                        e.classList.add(`icons-info-colour-1`);
                    } else if (e.nextElementSibling.classList.contains("last-block")) {
                        e.classList.add(`icons-info-colour-3`);
                    } else {
                        e.classList.add(`icons-info-colour-${colourCycle}`);
                    }
                });
        });
})();
