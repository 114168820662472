import $ from "jquery"

(function ($, t) {

    t.controllers.define(
        'pet-weighted-profile-exclusions',
        {
            'change .allergens-exclusions input:checkbox': 'updateMutualExclusions',
            'click #exclusions-no': 'deselectExclusions',
            'change input[type="checkbox"][name="allergen-category"]': 'onHypoallergenicChange',
            'change input[type="radio"][name="exclusions"]': 'onExclusionsChange'
        },
        {
            'init:before': function () {
                this.data = JSON.parse(this.$el.find('.allergen-data-exclusions').html());
                this._allergenMap = this.data.allergen_map;
                this._categories = this.data.categories;
                this.updateMutualExclusions();
                // needed for changing existing allergens/preferences
                let has_exclusions = this.$el.find("#exclusions-yes").prop("checked");
                if (has_exclusions) {
                    document.getElementsByClassName('exclusions-choices')[0].classList.add('shown');
                }
            },
            deselectExclusions() {
                const hypoCheckbox = document.getElementById('hypo-allergen-category')
                const explanation = document.getElementById('incompatible-allergen-explanation');

                hypoCheckbox.checked = false
                explanation.style.display = 'none'

                let availableExclusions = Object.keys(this._allergenMap).map(Number)
                availableExclusions.forEach(id => {
                    const allergen = document.getElementById('exclusions_allergen_id_' + id);
                    if (allergen) {
                        allergen.checked = false;
                        allergen.disabled = false
                    }
                });

                t.message.publish('exclusions', 'changed', {
                    selectedAllergenIds: [],
                    incompatibleAllergenIds: []
                });
            },
            onExclusionsChange(e) {
                let radio = e.target;
                let value = radio.value;

                if(value == 'True') {
                    document.getElementsByClassName('exclusions-choices')[0].classList.add('shown');
                }
                else {
                    document.getElementsByClassName('exclusions-choices')[0].classList.remove('shown');
                }
            },
            onHypoallergenicChange(e) {
                let checkbox = e.target;
                let category = this._categories[checkbox.value]
                let selectAllergens = checkbox.checked

                this.toggleHypoallergenic(category, selectAllergens);
            },
            toggleHypoallergenic: function (category, selectAllergens) {
                const exclusionsYes = this.$el.find("#exclusions-yes")
                exclusionsYes.checked = selectAllergens

                category.allergen_ids.forEach(allergenId => {
                    const allergen = document.getElementById('exclusions_allergen_id_' + allergenId);
                    allergen.checked = selectAllergens;
                })

                this.updateMutualExclusions();
            },

            updateMutualExclusions() {
                const that = this;
                const allergenCheckMap = [];
                const selectedAllergenIds = [];

                // All IDs we expect to get a status for
                let remainingIDs = []
                if (that && that._allergenMap) {
                    remainingIDs = Object.keys(that._allergenMap)
                }

                // Get incompatible allergens (mutually exclusive allergens)
                // for the selected allergen using the allergen_data map
                this.findWithinDOM('.allergens-exclusions input:checkbox').each(function () {
                    const $this = $(this);
                    allergenCheckMap.push(
                        [$this.val(), $this.prop('checked')]
                    );
                    if ($this.prop('checked')) {
                        selectedAllergenIds.push(parseInt($this.val(), 10))
                    }
                    remainingIDs.splice(remainingIDs.indexOf($this.val()), 1)
                }).get();

                remainingIDs.forEach(function name(id) {
                    // Add an entry indicating unchecked state for any expected allergen IDs not found in DOM
                    allergenCheckMap.push([id, 0]);
                })

                allergenCheckMap.sort(function (a, b) {
                    return a[0] - b[0]
                });

                let lookupInt = 0;
                for (let i = 0; i < allergenCheckMap.length; i++) {
                    lookupInt = lookupInt | ((allergenCheckMap[i][1] ? 1 : 0) << i);
                }

                const incompatibleAllergenIds = [];
                const incompatibleAllergenNames = new Set();

                this.findWithinDOM('.allergens-exclusions input:checkbox').each(function () {
                    const explanation = document.getElementById('incompatible-allergen-explanation');
                    explanation.style.display = 'none';

                    const allergen = $(this);
                    if (that._allergenMap[allergen.val()].indexOf('|' + lookupInt + '|') >= 0) {
                        allergen.attr('disabled', 'disabled');
                        incompatibleAllergenIds.push(parseInt(allergen.val(), 10));
                        incompatibleAllergenNames.add(allergen.data('allergenName'));
                    } else {
                        allergen.removeAttr('disabled');
                    }
                });

                const error = document.getElementById('error');
                const button = document.getElementsByClassName('btn-primary')[0]
                const explanation = document.getElementById('incompatible-allergen-explanation');
                const incompatibleAllergens = document.getElementById('incompatible-allergens');

                explanation.style.display = 'none';
                if (incompatibleAllergenIds.length > 0) {
                    // Show explanation for incompatible allergens
                    explanation.style.display = 'block';
                    incompatibleAllergens.style.display = 'inline';
                    incompatibleAllergens.innerText = Array.from(incompatibleAllergenNames).join(" , ").replace(
                        /, ((?:.(?!, ))+)$/, ' and $1');

                    // Check if there are conflicting allergens selected -> display error & disable submit
                    let conflictingAllergenError = false;
                    selectedAllergenIds.forEach(function (selectedAllergenId) {
                        if (incompatibleAllergenIds.indexOf(selectedAllergenId) >= 0) {
                            conflictingAllergenError = true;
                            button.disabled = true
                        }
                    });
                    if (conflictingAllergenError === true) {
                        error.style.display = 'block'
                    } else {
                        error.style.display = 'none'
                        button.disabled = false
                    }
                } else {
                    error.style.display = 'none'
                    button.disabled = false
                }

                // Update hypo checkbox based on whether all hypo allergens are selected or not
                const hypoallergenic_allergens = this._categories[1].allergen_ids
                let hypoallergenicSelected = hypoallergenic_allergens.every(id => selectedAllergenIds.includes(id));

                const hypoCheckbox = document.getElementById('hypo-allergen-category')
                hypoCheckbox.checked = hypoallergenicSelected

                //Publish which exclusion-allergens have been selected and which are incompatible
                t.message.publish('exclusions', 'changed', {
                    selectedAllergenIds: selectedAllergenIds,
                    incompatibleAllergenIds: incompatibleAllergenIds
                });
            },
        }
    );

    t.controllers.define(
        'pet-weighted-profile-preferences',
        {
            'click #preferences-no': 'deselectPreferences',
            'change input[type="radio"][name="preferences"]': 'onPreferencesChange'
        },
        {
            'init:before': function () {
                this.data = JSON.parse(this.$el.find('.allergen-data-preferences').html());
                this._allergenMap = this.data.allergen_map;
                // needed for changing existing preferences
                let has_exclusions = this.$el.find("#preferences-yes").prop("checked");
                if (has_exclusions) {
                    document.getElementsByClassName('preferences-choices')[0].classList.add('shown');
                }

                t.message.subscribe('exclusions', 'changed', e => {
                    const selectedAllergenIds = e.data.selectedAllergenIds;
                    const incompatibleAllergenIds = e.data.incompatibleAllergenIds;
                    this.updatePreferences(selectedAllergenIds, incompatibleAllergenIds);
                }, this);
            },

            deselectPreferences() {
                let availablePreferences = Object.keys(this._allergenMap).map(Number)
                availablePreferences.forEach(id => {
                    const allergen = document.getElementById('preferences_allergen_id_' + id);
                    if (allergen) {
                        allergen.checked = false;
                        allergen.disabled = false
                    }
                });
            },
            onPreferencesChange(e) {
                let radio = e.target;
                let value = radio.value

                if(value == 'True') {
                    document.getElementsByClassName('preferences-choices')[0].classList.add('shown');
                }
                else {
                    document.getElementsByClassName('preferences-choices')[0].classList.remove('shown');
                }
            },
            updatePreferences(selectedAllergenIds, incompatibleAllergenIds) {
                let availablePrefs = Object.keys(this._allergenMap).map(Number)
                let preferencesToHide = [].concat(...selectedAllergenIds, ...incompatibleAllergenIds);
                let preferencesToShow = availablePrefs.filter(val => !preferencesToHide.includes(val));

                preferencesToHide.forEach(id => {
                    const preference = document.getElementById('preferences_allergen_id_' + id);
                    const preference_box = document.getElementById('preferences_allergen_id_' + id + '_box');
                    if (preference && preference_box) {
                        preference.checked = false;
                        preference_box.style.display = 'none';
                    }
                });
                preferencesToShow.forEach(id => {
                    const preference_box = document.getElementById('preferences_allergen_id_' + id + '_box');
                    if (preference_box) {
                        preference_box.style.display = 'block';
                    }
                });

            },
        }
    );

})($, window.tails);
