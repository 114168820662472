import $ from "jquery"

if(!window.console) {
    var noop = function(){};

    window.console = {
        debug: noop,error: noop,info: noop,log: noop,warn: noop,dir: noop,dirxml: noop,table: noop,trace: noop,
        group: noop,groupCollapsed: noop,groupEnd: noop,clear: noop,count: noop,assert: noop,markTimeline: noop,
        profile: noop,profileEnd: noop,timeline: noop,timelineEnd: noop,time: noop,timeEnd: noop,timeStamp: noop,
        memory: noop
    };
}

/*
 * jQuery extensions!
 */
(function($) {
    $.fn.sortByDepth = function() {
        var ar = this.map(function() {
                return {length: $(this).parents().length, elt: this}
            }).get(),
            result = [],
            i = ar.length;

        ar.sort(function(a, b) {
            return a.length - b.length;
        });

        while (i--) {
            result.push(ar[i].elt);
        }
        return $(result);
    };
})($);

/**
 * tails.core.js
*/
(function(w) {
    w.tails = w.tails || {};
    w.tails.ns = function(name) {
        var obj = w.tails,
            parts = name.split('.').reverse(),
            part;

        while ( (part = parts.pop()) ) {
            obj[part] = obj[part] || {};
            obj = obj[part];
        }

        return obj;
    };

    w.tails.util = {
        bind: function (func, scope) {
            if (!func) {
                throw new TypeError('argument func not defined')
            }
            var curriedArgs = Array.prototype.slice.call(arguments, 2);
            return function () {
                return func.apply(
                    scope,
                    curriedArgs.concat(Array.prototype.slice.apply(arguments))
                );
            };
        },
        curry: function (func) {
            var curriedArgs = Array.prototype.slice.call(arguments, 1);
            return function () {
                return func.apply(
                    this,
                    curriedArgs.concat(Array.prototype.slice.apply(arguments))
                );
            };
        },
        augment: function (target, giver, noOverwrite) {
            for (var property in giver) {
                if (giver.hasOwnProperty(property) && !(noOverwrite && target.hasOwnProperty(property))) {
                    target[property] = giver[property];
                }
            }

            return target;
        },
        htmlEscape: function(str) {
            return str.replace('&', '&amp;')
                      .replace('<', '&lt;')
                      .replace('>', '&gt;')
                      .replace('"', '&quot;')
                      .replace("'", '&apos;');
        }
    };

})(window);

/**
 * tails.log.js
*/
(function(tails){
    var LOG_LEVELS = {
        INFO: 1,
        DEBUG: 2,
        WARN: 3,
        ERROR: 4,
        CRITICAL: 5
    };

    var DEFAULT_LOG_LEVEL = LOG_LEVELS.WARN;
    var DEFAULT_LOGGER = new Logger('_default');

    var loggers = {};

    function Logger(name, level) {
        this.name = name;
        this.level = level;
    }

    Logger.prototype = {
        getLogLevel: function() {
            if (this.level !== undefined) {
                return this.level;
            } else {
                var parent = this.getParentLogger();
                if (parent && parent.getLogLevel) {
                    return parent.getLogLevel();
                }
            }

            return DEFAULT_LOG_LEVEL;
        },
        getParentLogger: function() {
            var parts = this.name.split('.'),
                parent;

            parts.pop();
            while (parent === undefined && parts.length) {
                parent = loggers[parts.join('.')];
                parts.pop();
            }

            if (parent === undefined) {
                parent = DEFAULT_LOGGER;
            }

            return parent;
        },
        log: function(level, msg) {
            try {
                if ( LOG_LEVELS[level.toUpperCase()] >= this.getLogLevel() ) {
                    if (console) {
                        var args = Array.prototype.slice.call(arguments, 2);
                        console.log(this.name + ' [' + level + ']: ' + msg, args);
                    }
                }
            } catch(e) {
                // This should never happen, but in case it does we shouldn't have logging events breaking
                // javascript everywhere on the website. console.log is notoriously inconsistent across
                // browsers.
            }
        },
        info: function() {
            return this.log.apply(this, ['info'].concat(Array.prototype.slice.call(arguments)));
        },
        debug: function() {
            return this.log.apply(this, ['debug'].concat(Array.prototype.slice.call(arguments)));
        },
        warn: function() {
            return this.log.apply(this, ['warn'].concat(Array.prototype.slice.call(arguments)));
        },
        error: function() {
            return this.log.apply(this, ['error'].concat(Array.prototype.slice.call(arguments)));
        },
        critical: function() {
            return this.log.apply(this, ['critical'].concat(Array.prototype.slice.call(arguments)));
        }
    };

    // Namespaced logger API
    var loggerNS = tails.ns('logger');
    loggerNS.getLogger = function(name) {
        if ( loggers[name] === undefined ) {
            loggers[name] = new Logger(name);
        }

        return loggers[name];
    };
})(window.tails);


/**
 * tails.message.js
 *
 * Message bus implementation for loosely coupled communication between controllers, etc.
*/
(function(tails) {

    function Channel(name) {
        this.name = name;
        this._subscriptions = [];
    }

    Channel.prototype = {
        subscribe: function(topicName, callback) {
            var subscription = new Subscription(this.name, topicName, callback);
            this._subscriptions.push(subscription);
            return subscription;
        },
        unsubscribe: function(subscription) {
            var i;
            for (i = 0, l = this._subscriptions.length; i < l; i++) {
                if (this._subscriptions[i] === subscription) {
                    this._subscriptions.splice(i, 1);
                    break;
                }
            }
        },
        publish: function(topicName, data) {
            // Take a copy of subscribers, in case it changes
            var subscriptions = this._subscriptions.slice(),
                i, l;

            for (i = 0, l = subscriptions.length; i < l; i++) {
                if (subscriptions[i].regex.test(topicName)) {
                    subscriptions[i].callback.call(null, {
                        channel: this.name,
                        topic: topicName,
                        timestamp: Date.now(),
                        data: data
                    });
                }
            }
        }
    };


    function Subscription(channel, topicName, callback) {
        this._channel = channel;
        this._topicName = topicName;
        this.callback = callback;

        var pattern = topicName.replace('.', '\\.')
                               .replace('**', '!!MATCHALL!!')
                               .replace('*', '[^.]*')
                               .replace('!!MATCHALL!!', '.*');
        this.regex = new RegExp('^' + pattern + '$');
    }

    Subscription.prototype = {
        unsubscribe: function() {
            this._channel.unsubscribe(this);
        }
    };


    var _channels = {};
    tails.util.augment(tails.ns('message'), {
        channel: function(channelName) {
            channelName = channelName || '_default';
            if (_channels[channelName] === undefined) {
                _channels[channelName] = new Channel(channelName);
            }
            return _channels[channelName];
        },
        subscribe: function(channelName, topicName, callback) {
            var channel = this.channel(channelName);
            return channel.subscribe(topicName, callback);
        },
        unsubscribe: function(channelName, subscription) {
            var channel = this.channel(channelName);
            return channel.unsubscribe(subscription);
        },
        publish: function(channelName, topicName, data) {
            var channel = this.channel(channelName);
            return channel.publish(topicName, data);
        }
    });
})(window.tails);

/**
 * Alert
 */
(function(t) {

    var _$el,
        _$title,
        _$hd,
        _$bd,
        _$ft,
        _$window;

    var BUTTON_ACCEPT = 'accept',
        BUTTON_OK = 'ok',
        BUTTON_CANCEL = 'cancel',
        BUTTON_CLOSE = 'close',
        BUTTON_YES = 'yes',
        BUTTON_NO = 'no';

    var _buttonClasses = {
        accept: 'primary',
        ok: 'primary',
        yes: 'primary',
        no: 'secondary',
        cancel: 'secondary',
        close: 'secondary'
    };

    var LEVEL_ERROR = 'error',
        LEVEL_WARNING = 'warning',
        LEVEL_INFO = 'info';

    t.util.augment(t.ns('ui.alert'), {
        BUTTON_ACCEPT: BUTTON_ACCEPT,
        BUTTON_OK: BUTTON_OK,
        BUTTON_CANCEL: BUTTON_CANCEL,
        BUTTON_YES: BUTTON_YES,
        BUTTON_NO: BUTTON_NO,
        BUTTON_CLOSE: BUTTON_CLOSE,

        LEVEL_ERROR:LEVEL_ERROR,
        LEVEL_WARNING:LEVEL_WARNING,
        LEVEL_INFO:LEVEL_INFO,

        window: function(new_content) {
            if(new_content) {
                _$window.html(new_content);
                _$hd = _$el.find('.hd');
                _$bd = _$el.find('.bd');
                _$ft = _$el.find('.ft');
            }
            return _$window;
        },

        header: function(new_content) {
            if(new_content) {
                _$hd.html(new_content);
            }
            return _$hd;
        },

        content: function(new_content) {
            if(new_content) {
                _$bd.html(new_content);
            }
            return _$bd;
        },

        buttons: function(new_buttons) {
            if(new_buttons) {
                /*
                 * Allow the `buttons` variable to be a function, so the implementor can use their own rendering function,
                 * which should return a set of elements, which we can then use to append to the alert box
                 */
                _$ft.empty();
                if(typeof new_buttons === 'function') {
                    _$ft.append(new_buttons());
                }
                else {
                    new_buttons.forEach(function (button) {
                        var button_class = _buttonClasses[button] || 'primary';
                        const trans_string = window.tails.getCTAText(button);
                        _$ft.append(
                            $('<button type="button" class="btn"></button>')
                                .addClass('btn-' + button_class)
                                .attr('name', button)
                                .text(trans_string)
                        );
                    });
                }
            }
        },

        hide: function() {
            $('body').removeClass('alert-window-visible');
            if(_$el) _$el.remove();
        },

        show: function(level, title, body, buttons, callback) {
            buttons = buttons || [BUTTON_CANCEL, BUTTON_ACCEPT];
            if (!_$el) {
                _$el = $(
                    '<div class="alert-shade">' +
                        '<div class="alert-window">' +
                            '<div class="hd"><span class="icon"></span><h2 class="title"></h2></div>' +
                            '<div class="bd"></div>' +
                            '<div class="ft"></div>' +
                        '</div>' +
                    '</div>'
                );

                _$hd = _$el.find('.hd');
                _$bd = _$el.find('.bd');
                _$ft = _$el.find('.ft');
                _$title = _$hd.find('.title');
                _$window = _$el.find('.alert-window');
            }

            _$el.on('click.shade', t.util.bind(function(e) {
                // Clicked shade?
                if (e.target == _$el.get(0)) {
                    this.hide();
                    callback();
                }
            }, this));

            _$el.on('click', 'button', t.util.bind(function(e) {
                var result = callback($(e.target).attr('name'));
                if(result !== false) {
                    this.hide();
                }
            }, this));

            if (title) {
                _$hd.removeClass("hide");
                _$title.text(title);
            } else {
                _$hd.addClass("hide");
            }
            _$bd.html(body);

            _$ft.html('');

            this.buttons(buttons);

            _$window.attr("data-level", level);

            $('body').addClass('alert-window-visible').append(_$el);

            return _$el;
        }
    });

})(window.tails);


/**
 * tails.url.js
*/
(function(tails) {
    var a = document.createElement('a');

    function parseQueryString(qs) {
        var params = {},
            pairs = qs.split('&'),
            i, l;

        for (i = 0, l = pairs.length; i < l; i++) {
            if (pairs[i]) {
                var parts = pairs[i].split('=');
                params[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
            }
        }

        return params;
    }

    function buildQueryString(params) {
        var pairs = [],
            name;

        for (name in params) {
            pairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(params[name]));
        }

        return pairs.join('&');
    }


    function URL(url) {
        this.parse(url);
    }

    URL.prototype = {
        parse: function(url) {
            a.href = url;
            this.scheme(a.protocol.substr(0, a.protocol.length - 1));
            this.host(a.hostname);
            this.port(a.port);
            this.path(a.pathname);
            this.queryString(a.search.substr(1));
            this.fragment(a.hash.substr(1));
        },
        scheme: function(newScheme) {
            if (arguments.length) {
                this._sceme = newScheme;
            }
            return this._sceme;
        },
        host: function(newHost) {
            if (arguments.length) {
                this._host = newHost;
            }
            return this._host;
        },
        port: function(newPort) {
            if (arguments.length) {
                this._port = newPort;
            }
            return this._port;
        },
        path: function(newPath) {
            if (arguments.length) {
                this._path = newPath;
            }
            return this._path || '/';
        },
        queryString: function(newQueryString) {
            if (arguments.length) {
                this._params = parseQueryString(newQueryString);
            }
            return buildQueryString(this._params);
        },
        fragment: function(newFragment) {
            if (arguments.length) {
                this._fragment = newFragment;
            }
            return this._fragment;
        },
        params: function(params) {
            if (arguments.length) {
                this._params = params || {};
            }
            return this._params;
        },
        param: function(name, value) {
            if (arguments.length > 1) {
                this._params[name] = value;
            }
            return this._params[name];
        },
        toString: function() {
            var parts = [];
            parts.push(this.scheme() + "://");

            parts.push(this.host());
            if (this.port() !== undefined) {
                parts.push(':' + this.port());
            }

            parts.push(this.path());

            var qs = this.queryString();
            if (qs) {
                parts.push('?' + qs);
            }

            if (this.fragment()) {
                parts.push('#' + this.fragment());
            }

            return parts.join('');
        }
    };

    tails.util.augment(tails.ns('url'), {
        URL: URL
    });

})(window.tails);

/**
 * tails.aspect.js
*/
(function(tails) {
    tails.util.augment(tails.ns('aspect'), {
        before: function(original, func) {
            return function () {
                func.apply(this, Array.prototype.slice.call(arguments, 0));
                return original.apply(this, arguments);
            };
        },
        after: function(original, func) {
            return function () {
                var returnVal = original.apply(this, arguments);
                func.apply(this, arguments);
                return returnVal;
            };
        },
        around: function(original, func) {
            return function () {
                var args = Array.prototype.slice.call(arguments, 0);
                var boundOriginal = tails.util.bind.apply(tails.util, [original, this].concat(args));
                return func.apply(this, [boundOriginal].concat(args));
            };
        }
    });
})(window.tails);


/**
 * tails.controllers.js
*/
(function($, tails) {
    function _splitEventSpec(eventSpec) {
        var specParts = eventSpec.split(' ');
        return {
            'eventName': specParts.shift(),
            'selector': specParts.join(' ')
        }
    }

    function BaseViewController() {}

    BaseViewController.prototype = {
        init: function() {
            this.logger = tails.logger.getLogger('controller.' + this.constructor.type);
            this.logger.debug('base.init');
            this.register_events();
            this.initialised = true;
        },
        register_events: function() {
            this.logger.debug('base.register_events');
            this._eventMapHandlers = {};
            for ( var eventSpec in this._eventMap ) {
                if (this._eventMap.hasOwnProperty(eventSpec) === false) {
                    continue;
                }

                var specParts = _splitEventSpec(eventSpec);

                for ( var i = 0; i < this._eventMap[eventSpec].length; i++) {
                    var handler = tails.util.bind(this[this._eventMap[eventSpec][i]], this);
                    this.$el.on(specParts.eventName, specParts.selector, handler);
                    this._eventMapHandlers[eventSpec] = handler
                }
            }
        },
        destroy: function() {
            this.deregister_events();
        },
        deregister_events: function() {
            this.logger.debug('base.deregister_events');

            for ( var eventSpec in this._eventMapHandlers ) {
                if (this._eventMapHandlers.hasOwnProperty(eventSpec) === false) {
                    continue;
                }

                var specParts = _splitEventSpec(eventSpec);

                for ( var i = 0; i < this._eventMap[eventSpec].length; i++) {
                    this.$el.off(
                        specParts.eventName,
                        specParts.selector,
                        this._eventMapHandlers[eventSpec]
                    );
                }
            }
        },
        closest: function(type) {
            var $ctor = this.$el.parent().closest('.tails-view-' + type);
            if (controllers[$ctor.attr('id')]) {
                return controllers[$ctor.attr('id')];
            }
        },
        children: function(type) {
            var matches = [];
            var $startEl = this.$el;
            $startEl.children('.tails-view' + (type ? '-'+type : '')).each(function() {
                if (controllers[this.id]) {
                    matches.push(controllers[this.id]);
                }
            });

            return matches;
        },
        // helper method to find child controllers of the current controller
        find: function(type) {
            var matches = [];
            var $startEl = this.$el;
            $startEl.find('.tails-view' + (type ? '-'+type : '')).each(function() {
                if (controllers[this.id]) {
                    matches.push(controllers[this.id]);
                }
            });

            return matches;
        },
        // helper method to find elements within the current controller whilst
        // excluding elements within child controllers
        findWithinDOM: function(expr) {
            return this.$el.find(expr).not('#' + this.$el.attr('id') + ' .tails-view *');
        }
    };
    ['appendTo', 'insertAfter', 'insertBefore', 'prependTo'].forEach(function(methodName) {
        BaseViewController.prototype[methodName] = tails.util.curry(
            function (fn, $target) {
                if ($.contains(document, this.$el)) {
                    this.$el.detach();
                }
                return fn.apply(this, $target);
            },
            function($target) {
                return this.$el[methodName]($target);
            }
        );
    });

    // Registries for controller classes and instances
    var controllerDefinitions = {};
    var controllerClasses = {};
    var controllers = {};

    var generateControllerId = (function() {
        var nextId = 0;
        return function() {
            return 'tails-view-' + (++nextId);
        };
    })();

    function hyphenatedToCamelCase(str, upperFirst) {
        var re = upperFirst ? /(^|-)([a-z])/g : /-([a-z])/g ;
        return str.replace(re, function(substr, letter) {
            return letter.toUpperCase();
        });
    }

    var typeClassRegex = /\btails-view-([a-z0-9-]+)\b/g;
    function getViewTypesForElement(el) {
        var matches = [],
            match;
        while (match = typeClassRegex.exec(el.className)) {
            matches.push(match[1]);
        }

        return matches.reverse();
    }

    // registry for view templates
    var viewTypeTemplates = {};
    function getTemplateForViewType(viewType) {
        if (!viewTypeTemplates[viewType]) {
            var templateId = 'tmpl_' + viewType.replace('-','_');
            var templateEl = $('#'+templateId);
            if (templateEl) {
                viewTypeTemplates[viewType] = Handlebars.compile(templateEl.html());
            }
        }
        return viewTypeTemplates[viewType];
    }

    function getControllerClass(types) {

        for (var i=0; i<types.length; i++) {
            var type = types[i];
            if (controllerClasses[type] === undefined) {
                var definition = controllerDefinitions[type];

                if (!definition) {
                    // keep going till we find a defined controller
                    continue;
                }
                var baseControllerClass = BaseViewController;
                if (definition.base) {
                    baseControllerClass = getControllerClass([definition.base]);
                }

                if (baseControllerClass) {
                    // Create constructor
                    function ctor($el, options, data) {
                        this.$el = $el;
                        this.options = options;
                        this.data = data || {};
                    }
                    ctor.type = definition.type;
                    ctor.displayName = hyphenatedToCamelCase(definition.type);

                    // Add base methods to prototype
                    tails.util.augment(ctor.prototype, baseControllerClass.prototype);

                    // Take a copy of the event map and add our bits to it
                    ctor.prototype._eventMap = tails.util.augment({}, ctor.prototype._eventMap || {});
                    for (var eventSpec in definition.eventMap) {
                        if (ctor.prototype._eventMap[eventSpec] == undefined) {
                            ctor.prototype._eventMap[eventSpec] = [];
                        }
                        ctor.prototype._eventMap[eventSpec].unshift(definition.eventMap[eventSpec]);
                    }

                    // Let definer make any modifications it needs
                    definition.definerFunc(ctor.prototype);

                    controllerClasses[definition.type] = ctor;
                } else {
                    logger.warn('Cannot find base controller "' + definition.base + '" for "' + type + '"');
                    continue;
                }
            }

            return controllerClasses[type];
        }

        logger.warn('Cannot find definition for "' + types.join(', ') + '"');
    }

    var logger = tails.logger.getLogger('controller');

    var definerFuncRegexp = /^([^:]+)(?::(after|before|around))?$/;

    tails.util.augment(tails.ns('controllers'), {
        define: function(type, eventMap, definer) {
            logger.debug('define(' + type + ')');

            // Support calls with no eventMap
            if (arguments.length < 3) {
                definer = eventMap;
                eventMap = {};
            }

            // Determine base controller name
            var base = undefined;
            if (type.indexOf('.') !== -1 ) {
                var parts = type.split('.', 2);
                base = parts[0];
                type = parts[1];
            }


            /*
                definer - can be a function or object, which is parsed into a function

                Definer objects take the following form:

                definer = {
                    // aspects are specified with the :<keyword> form
                    'init:after': function(arg, ...) {
                        ...
                    },
                    onButtonClick: function(e) {
                        ....
                    }
                }
            */
            var definerFunc;
            if (typeof(definer) === 'function') {
                definerFunc = definer;
            } else {
                definerFunc = function(p) {
                    var definerSpec = {};
                    for (var funcName in definer) {
                        var res = definerFuncRegexp.exec(funcName);
                        if (res[2]) {
                            var targetFunc = definerSpec[res[1]] || p[res[1]];
                            definerSpec[res[1]] = tails.aspect[res[2]](targetFunc, definer[funcName]);
                        } else {
                            definerSpec[res[1]] = definer[funcName];
                        }
                    }
                    tails.util.augment(p, definerSpec);
                }
            }

            // Stash definition for lazy instantiation
            controllerDefinitions[type] = {
                base: base,
                type: type,
                eventMap: eventMap,
                definerFunc: definerFunc
            };
        },
        create: function(arg, options) {
            var viewTypes;
            var $el;

            if (arg instanceof $) {
                $el = arg;
                viewTypes = getViewTypesForElement(arg[0]);
            } else {
                viewTypes = [arg];
                var template = getTemplateForViewType(viewTypes[0]);
                $el = $(template(options));
            }

            var id = $el.attr('id') || generateControllerId();
            $el.attr('id', id);

            options = $.extend({}, options, $el.data());

            var data = {};
            var $dataEl = $el.children('script[type="application/json+tails-view-data"]');
            if ($dataEl.length) {
                data = $.parseJSON($dataEl.text());
            }

            var controllerClass = viewTypes.length > 0 && getControllerClass(viewTypes),
                controller;

            if (controllerClass) {
                controller = new controllerClass($el, options, data);
                controllers[id] = controller;
            }

            return controller;
        },
        init: function(startEl) {
            logger.debug('init');

            var instance = this;
            var $startEl = $(startEl || document.documentElement);
            var controllersToInit = [];
            $startEl.find('.tails-view').addBack().sortByDepth().each(function() {
                var $this = $(this);
                logger.debug('el', $this);
                // If we've not instantiated for this ID before
                if ( !($this.attr('id') && controllers[$this.attr('id')]) ) {
                    instance.create($this);
                }

                var controller = controllers[$this.attr('id')];
                if (controller && !controller.initialised) {
                    controllersToInit.push(controller);
                }
            });

            controllersToInit.forEach(function(controller) {
                try {
                    controller.init();
                } catch (e) {
                    logger.error('could not initialise controller ' + controller.displayName + ': ' + e.message);
                    window.Raven && window.Raven.captureException && window.Raven.captureException(e)
                }
            });

            tails.message.publish('tails', 'init.complete');
        },
        find: function(startEl, type) {
            var matches = [];
            var $startEl = $(startEl || document.documentElement);
            var selector = '.tails-view';
            if (type) {
                selector += '-' + type
            }
            $startEl.find(selector).sortByDepth().each(function() {
                if (controllers[this.id]) {
                    matches.push(controllers[this.id]);
                }
            });

            return matches;
        },

        purge: function(startEl, includeSelf) {
            var $selector = $(startEl);

            if (includeSelf === true) {
                $selector = $selector.addBack();
            }

            this.find($selector).forEach(function (controller) {
                delete controllers[controller.id];
                controller.destroy();
            });
        },

        purgeElement: function(elem) {
            var elem_id = $(elem).attr("id");
            if(elem_id) {
                var controller = controllers[elem_id];
                if(controller) {
                    controller.destroy();
                    delete controllers[elem_id];
                }

            }
        }
    });

    // Find DOM elements with tails-view class
    $(function() {
        tails.controllers.init();
    });

})($, window.tails);


/**
 * tails.controllers.main.js
*/
(function($, t){
    var _templates = {
        alert: '<li class="alert alert-{{severity}}">{{text}}</li>',
        closeButton: '<a href="#" type="button" class="close"><span>&times;</span></a>'
    };

    function _parseTemplate(template, params) {
        return template.replace(/\{\{([^}]+)\}\}/g, function(match, name) {
            return params[name];
        });
    }

    t.controllers.define(
        'alerts',
        {
            'click a.close': 'onNotificationCloseClick'
        },
        function (p) {
            t.util.augment(p, {
                init: t.aspect.after(p.init, function() {
                    this.logger.log('init');

                    // Listen out for all messages on the alert channel so we can dynamically add
                    // new alerts
                    t.message.subscribe('alert', '*', t.util.bind(this.onAlertMessageReceived, this));

                    var $alerts = this.$el.find('.alert');
                    $alerts.each(function() {
                        $(this).prepend(_parseTemplate(_templates.closeButton));
                    });
                }),
                onNotificationCloseClick: function(e) {
                    this.logger.log('onNotificationCloseClick');

                    var $alert = $(e.target).closest('.alert');
                    $alert.fadeOut(300, function() {
                        $alert.remove();
                    });

                    e.preventDefault();
                },
                onAlertMessageReceived: function(msg) {
                    this.logger.log('onAlertMessage');

                    var alertMarkup = _parseTemplate(_templates.alert, {
                        severity: msg.topic,
                        text: t.util.htmlEscape(msg.data)
                    });
                    var $alert = $(alertMarkup);
                    $alert.append(_parseTemplate(_templates.closeButton));

                    this.$el.append($alert);
                }
            });
        }
    );
})($, window.tails);

(function($, t) {
    var stickyCta = $('.sticky-cta');
    t.animatedScrollTop = function(target, offset) {
        if (!offset) {
            offset = 0;
        }

        offset -= stickyCta.outerHeight();

        var scrollOffset = $('.top-nav').height() + parseInt(target.css('marginTop').replace(/[a-z]+$/i, ''));
        var targetScrollTop = (target.offset().top - scrollOffset) + offset;
        var currentScrollTop = $(document.body).scrollTop();

        var absDelta = Math.max(targetScrollTop, currentScrollTop) - Math.min(targetScrollTop, currentScrollTop);

        var duration = absDelta * .5;

        duration = Math.min(duration, 800);

        var easing = 'easeOutCubic';

        if(!(easing in jQuery.easing)) {
            easing = null;
        }

        $("html, body").stop().animate({ scrollTop: targetScrollTop }, duration, easing);
    }
})($, window.tails);

(function(){
    Object.byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        while (a.length) {
            var n = a.shift();
            if (n in o) {
                o = o[n];
            } else {
                return;
            }
        }
        return o;
    }
}());
