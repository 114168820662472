import $ from "jquery"
import "slick-carousel"

(function($, t){
    t.controllers.define(
        'multi-carousel',
        {

        },
        {
            'init:after': function () {
                this.$el.removeClass('nojs');
                this.$carouselWrappers = this.$el.find('.carousel-wrapper');
                this.$scrollBoxes = this.$el.find('.carousel-scrollbox');
                this.$scrollArrows = this.$el.find('.margin');
                this.$carouselItem = this.$el.find('.carousel-item');

                 // Remove NO JS Classes //
                this.$scrollArrows.removeClass('margin-no-js');
                this.$carouselItem.removeClass('no-js-carousel-item');
                this.$scrollBoxes.removeClass('carousel-scrollbox-no-js');
                this.$carouselWrappers.removeClass('nojs');
                // Single Carousel Controller classes
                this.$carouselWrappers.removeClass('tails-view');
                this.$carouselWrappers.removeClass('tails-view-slice-carousel');
                this.$carouselWrappers.removeAttr('id');

                this.createMultiCarousel();

            },
            'createMultiCarousel': function() {

                for (let i = 0; i < this.$scrollBoxes.length; i++) {

                    let $nextButton = $(this.$carouselWrappers[i]).find('.button.next');
                    let $prevButton = $(this.$carouselWrappers[i]).find('.button.previous');

                    $(this.$scrollBoxes[i]).slick({
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        nextArrow: $nextButton,
                        prevArrow: $prevButton,
                        dots: true,
                        swipeToSlide: true,

                    }).on({
                        'mousedown': function() {
                            $(this).addClass("grabbing");
                        },
                        'mouseup': function () {
                            $(this).removeClass("grabbing");
                        }
                    });
                }

                this.setMultiDots();

            },
            'setMultiDots': function() {
                this.$carouselWrappers.each((index, elem) => {
                    $(elem).find('.slick-dots').insertAfter(elem);
                })
            }
        }
    );

    t.controllers.define(
        'slice-carousel',
        {
        },
        {
            'init:after': function () {
                this.$multiCarousel = $('.multi-carousel');

                // Check if multi-carousel class exists and only run this if it doesn't
                if (this.$multiCarousel.length == 0) {

                    this.$el.removeClass('nojs');
                    this.$scrollBox = this.$el.find('.carousel-scrollbox');
                    this.$scrollArrows = this.$el.find('.margin');
                    this.$carouselItem = this.$el.find('.carousel-item');

                    // Remove NO JS Classes //
                    this.$scrollArrows.removeClass('margin-no-js');
                    this.$carouselItem.removeClass('no-js-carousel-item');
                    this.$scrollBox.removeClass('carousel-scrollbox-no-js');

                    this.createSingleCarousel();
                }
            },
            'createSingleCarousel': function() {
               this.$scrollBox.slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: this.$el.find('.button.next'),
                    prevArrow: this.$el.find('.button.previous'),
                    dots: true,
                    swipeToSlide: true,

                }).on({
                    'mousedown': function() {
                        $(this).addClass("grabbing");
                    },
                    'mouseup': function () {
                        $(this).removeClass("grabbing");
                    }
                });

                this.setDots()
            },
            'setDots': function() {

                this.$el.find('.slick-dots').insertAfter('.carousel-wrapper');

            }
        }
    );

})($, window.tails);
