import $ from "jquery"

(function ($, t) {
    // Disable bfcache for signup pages
    $(window).bind("pageshow", function (event) {
        if (event.originalEvent.persisted) {
            window.location.reload()
        }
    });

    $(document).ready(function () {
        $(':input').blur(function () {
            var $form = $(this).closest('form');

            if ($form.attr('name')) {
                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: $form.attr('name'),
                    eventAction: $(this).val().length > 0 ? 'completed' : 'skipped',
                    eventLabel: $(this).attr('name')
                });
            }
        });
    });

    t.controllers.define(
        'email',
        {
            'focusout input': 'onEmailInputChange'
        },
        {
            'init:after': function () {
                this.$input = this.findWithinDOM('input');
                this.$known = this.findWithinDOM('.known');
            },
            onEmailInputChange: function (e) {
                if (this.$input.val()) {
                    $.ajax({
                        url: '/api/signup/customer',
                        data: {
                            email: this.$input.val()
                        },
                        success: t.util.bind(this._handleResponse, this)
                    })
                } else {
                    this.$input.closest('.form-group').removeAttr('autocomplete').removeClass('has-error');
                    this.$known.slideUp('fast');
                }
            },
            _handleResponse: function (data) {
                if (data.c) {
                    this.$input.closest('.form-group').attr('autocomplete', 'off').addClass('has-error');
                    this.$known.removeClass('hide').hide().slideDown('fast');
                } else {
                    this.$input.closest('.form-group').removeAttr('autocomplete').removeClass('has-error');
                    this.$known.slideUp('fast');
                }
            }
        }
    );

    t.controllers.define(
        'fieldset.pet-gender',
        {
            'change input:radio': 'onButtonClick',
            'change input[type=checkbox]': 'onCheckboxClick'
        },
        {
            'init:after': function () {
                this.$neutered = this.findWithinDOM('.neutered');
                this.$pregnantNursing = this.findWithinDOM('.pregnant-nursing');
                this.$pregnantNursingWarning = this.findWithinDOM('.pregnant-nursing-warning');
            },
            onButtonClick: function (e) {
                this._valuesChanged();
            },
            onCheckboxClick: function (e) {
                this._valuesChanged();
            },
            _valuesChanged: function () {
                this.$el.closest('form').find('button[type=submit]').removeAttr('disabled');
                this.$pregnantNursingWarning.slideUp('fast');
                if (this.findWithinDOM('input[type=radio]:checked').val() === 'male') {
                    this.$neutered.removeAttr('disabled');
                    this.$pregnantNursing.attr('disabled', true).parent();
                } else {
                    this.$pregnantNursing.removeAttr('disabled');
                    if (this.$pregnantNursing.not(':disabled').is(':checked')) {
                        this.$neutered.attr('disabled', true);
                        this.$el.closest('form').find('button[type=submit]').attr('disabled', true);
                        this.$pregnantNursingWarning.removeClass('hide').hide().slideDown('fast');
                    } else if (this.$neutered.not(':disabled').is(':checked')) {
                        this.$pregnantNursing.attr('disabled', true);
                    } else {
                        this.$pregnantNursing.removeAttr('disabled');
                        this.$neutered.removeAttr('disabled');
                    }
                }
            }
        }
    );

    t.controllers.define(
        'pet-names',
        {
            'click a.add-pet': 'onAddPetClick',
            'click button.remove-pet': 'onRemovePetClick',
            'click a.remove-existing-pet': 'onRemoveExistingPetClick',
            'click a.edit-name': 'onEditPetNameClick'
        },
        {
            'init:after': function () {
                this._index = 0;
                this.$petNames = this.findWithinDOM('.new-pet-names');
                this.$newPetTemplate = this.$el.find('.templates .pet-name');

                // add a blank field
                var x = this.$petNames.find('.pet-name');
                if (!this.findWithinDOM('.existing-pets .pet').length) {
                    this.addNewPet(this._index++);
                }
            },
            addNewPet: function (index) {
                var $newPet = this.$newPetTemplate.clone();

                $newPet.appendTo(this.$petNames).hide().slideDown('fast');
                $newPet.find('input').attr('name', $newPet.find('input').attr('name') + '-' + index).removeAttr('disabled').focus();

                if (this.findWithinDOM('.existing-pets .pet').length || this.$petNames.find('.pet-name').length > 1) {
                    this.$petNames.find('.remove-pet').removeClass('disabled');
                }
            },
            addExistingPet: function (pet) {
                // are we adding a new pet
                if (pet) {
                    $newPet.find('input').data('index', index).val(pet.name);
                    $newPet.find('.remove-pet');
                }
            },
            onEditPetNameClick: function (e) {
                var $target = $(e.target);

                e.preventDefault();

                $target.closest('.pet').find('input').removeAttr('disabled').focus();
            },
            onRemoveExistingPetClick: function (e) {
                var $target = $(e.target);

                e.preventDefault();

                // Hide field but enable it and nullify its value, so we know to delete it on the backend
                $target.closest('.pet').hide().find('input').removeAttr('disabled').attr("value", "");

                if (!this.findWithinDOM('.existing-pets .pet').length && !this.findWithinDOM('.existing-pets .pet').length) {
                    this.addNewPet(this._index++);
                }
            },
            onRemovePetClick: function (e) {
                var $target = $(e.target);
                e.preventDefault()

                if (!this.findWithinDOM('.existing-pets .pet').length && this.$petNames.find('.pet-name').length <= 1) {
                    return;
                }

                $target.closest('.pet-name').remove();

                if (!this.findWithinDOM('.existing-pets .pet').length && this.$petNames.find('.pet-name').length === 1) {
                    this.$petNames.find('.remove-pet').addClass('disabled');
                }
            },
            onAddPetClick: function (e) {
                // clone the first field
                this.addNewPet(this._index++);
                e.preventDefault();
            }
        }
    );

    t.controllers.define(
        'customer-details',
        {
            'click a.change-email': 'onChangeEmailClick',
            'click a.change-password': 'onChangePasswordClick',
        },
        {
            'init:after': function () {
                this.$passwordPlaceholder = this.findWithinDOM('.password-placeholder');
                this.$emailPlaceholder = this.findWithinDOM('.email-placeholder');
                this.$emailField = this.findWithinDOM('input.email');

            },
            onChangePasswordClick: function (e) {
                this.$passwordPlaceholder.hide();
                const passwordInput = this.findWithinDOM('.password-entry').children();
                passwordInput.map((node)=> {
                    $(passwordInput[node]).removeClass('hide');
                    $(passwordInput[node]).removeAttr('disabled');
                });
                e.preventDefault();
            },

            onChangeEmailClick: function (e) {
                e.preventDefault();

                this.$emailPlaceholder.hide();
                this.$emailField.removeAttr('disabled').removeClass('hide').focus();
            }
        }
    );

    t.controllers.define(
        'voucher-code',
        {
            'click .show-voucher-code a': 'onClickShowVoucher'
        },
        {
            'init:after': function () {
                this.$voucherEntry = this.findWithinDOM('.voucher-code-entry');
                this.$showVoucherCode = this.findWithinDOM('.show-voucher-code');
            },
            onClickShowVoucher: function (e) {
                e.preventDefault();

                this.$showVoucherCode.slideUp('fast');
                this.$voucherEntry.removeClass('hide').hide().slideDown('fast').find('input').focus();
            }
        }
    );

    t.controllers.define(
        'delivery-date',
        {
            'click a.change-delivery': 'onClickChangeDelivery'
        },
        {
            onClickChangeDelivery: function (e) {
                this.findWithinDOM('.delivery-date-range').slideUp('fast', t.util.bind(function () {
                    this.findWithinDOM('.delivery-date-selector').removeClass('hide').hide().slideDown('fast');
                }, this));
                this.findWithinDOM('.homepage-button-container').slideUp('fast');
            }
        }
    );

    t.controllers.define(
        'first-delivery',
        {
            'change #first_delivery_details-delivery_date': 'onFirstDeliveryChanged'
        },
        {
            onFirstDeliveryChanged: function () {
                window.dataLayer.push({
                    event: 'ga',
                    eventCategory: 'input',
                    eventAction: 'changed',
                    eventLabel: "first delivery date changed"
                });
            }
        }
    );


    t.controllers.define(
        'third-party-popup',
        {},
        {
            'init:after': function () {
                var html = this.$el.html();
                t.ui.alert.show(
                    '',
                    '',
                    html,
                    [],
                    t.util.bind(function (button) {

                    }, this)
                );
                t.ui.alert.content().find(
                    '.btn-close'
                ).on('click', this.closeButtonClicked)
            },
            closeButtonClicked: function (e) {
                e.preventDefault();
                t.ui.alert.hide();
            }
        }
    );

    t.controllers.define(
        'accordion-animation',
        {
            'click details': 'onDetailsClick'
        },
        {
            onDetailsClick: function(e) {
                if(e.target.tagName == 'SUMMARY' || e.target.classList.contains('title')){
                    e.preventDefault();
                    let isClosing = false,
                        isExpanding = false,
                        animation = null,
                        detailsElement = this.$el[0].firstElementChild,
                        summaryElement = detailsElement.querySelector('summary'),
                        contentElement = detailsElement.querySelector('.accordion-content');

                    detailsElement.style.overflow = 'hidden';

                    if(isClosing || !detailsElement.open) {
                        open();
                    }
                    else if (isExpanding || detailsElement.open) {
                        shrink();
                    }

                    function shrink() {
                        isClosing = true;
                        detailsElement.classList.add('closing');
                        const startHeight = `${detailsElement.offsetHeight}px`,
                              endHeight = `${summaryElement.offsetHeight}px`;

                        if (animation) {
                            animation.cancel();
                        }

                        animation = detailsElement.animate({
                            height: [startHeight, endHeight]
                        }, {
                            duration: 400,
                            easing: 'ease-out'
                        });

                        animation.onfinish = () => onAnimationFinish(false);
                        animation.oncancel = () => isClosing = false;
                    }

                    function open() {
                        let startHeight = `${detailsElement.offsetHeight}px`
                        detailsElement.height = `${detailsElement.offsetHeight}px`;
                        detailsElement.open = true;
                        window.requestAnimationFrame(() => expand(startHeight));
                    }

                    function expand(startHeight) {
                        isExpanding = true;
                        detailsElement.classList.add('opening');
                        const endHeight = `${summaryElement.offsetHeight + contentElement.offsetHeight}px`;

                        if (animation) {
                        animation.cancel();
                        }

                        animation = detailsElement.animate({
                        height: [startHeight, endHeight]
                        }, {
                        duration: 400,
                        easing: 'ease-out'
                        });

                        animation.onfinish = () => onAnimationFinish(true);
                        animation.oncancel = () => isExpanding = false;
                    }

                    function onAnimationFinish(open) {
                        detailsElement.open = open;
                        detailsElement.classList.remove('closing', 'opening');
                        animation = null;
                        isClosing = false;
                        isExpanding = false;
                        detailsElement.style.height = detailsElement.style.overflow = '';
                    }
                }
            }
        }
    )

    t.controllers.define(
        'delivery-address',
        {
            'change input[name="address-use_delivery_address"]' : 'onRadioChange'
        },
        {
            'init:after': function() {
                const addressLookup = document.querySelector('.address-lookup');
                addressLookup.classList.add('hide');
            },
            onRadioChange: function(e) {
                const addressLookup = document.querySelector('.address-lookup');
                let value = e.target.value;

                if(value === '1'){
                    addressLookup.classList.add('hide');
                }
                else{
                    addressLookup.classList.remove('hide');
                }
            }
        }
    );

    t.controllers.define(
        'checkout-steps',
        {
            'submit form': 'onFormSubmit',
            'click .step-link': 'onStepLinkClick',
            'submit form.postcode-search': 'onPostcodeSearchFormSubmit',
        },
        {
            'init:after': function () {
                this.refresh_price = false;
                this.$phoneCodeField = this.$el.find('input.calling-code');
                this.originalPhoneCallField = this.$phoneCodeField.attr('name');
                this.$phoneCodeField.attr('name', this.getRandomString(25));
                this.showHideNotification();
            },
            onStepLinkClick: function (e) {
                e.preventDefault();
                var link = $(e.currentTarget);
                var step = link.closest('.step');
                // if customer is in checkout experiment, the value for the step above will not be found, so its length will be 0
                if (step.length === 0) {
                    step = $(`.step[data-target = '${link[0].dataset.target}']`)
                }

                if (step.attr("data-status") === "disabled") {
                    return;
                }

                var target_url = step.attr("data-target");

                $.when(
                    $.get(target_url)
                ).then(
                    t.util.bind(this.updateSteps, this)
                );
            },
            showHideNotification: function () {
                const notificationContainer = $('.notification-redesign');
                const notificationTitle = $('.notification-title-redesign');
                const notificationMessage = $('.notification-message-redesign');

                notificationContainer.show();

                if (notificationTitle && notificationTitle.children().length === 0 &&
                  notificationMessage && notificationMessage.children().length === 0) {
                    notificationContainer.hide();
                }
            },
            getRandomString(size) {
                function getRandomArbitrary(min, max) {
                    return Math.random() * (max - min) + min;
                }
                const ar = new Array(size).fill(null);
                return ar.map(_ => String.fromCharCode(getRandomArbitrary(65, 90))).join('');
            },
            onFormSubmit: function (e) {
                var form = $(e.currentTarget);
                const skipStepLogic = form.attr('skip-step-logic') === 'true';
                if (skipStepLogic) {
                    const nativeForm = $(e.currentTarget)[0];
                    nativeForm.submit();
                }
                if (form.data("submit") === true) {
                    return;
                }

                e.preventDefault();
                this.$phoneCodeField.attr('name', this.originalPhoneCallField);
                var step = form.closest('.step');
                var loader = step.children('.loader-shroud')
                var target_url = step.attr("data-target");
                this.$el.find("button[type=submit]").addClass("btn-progress");
                form.find("a, button").prop("disabled", true);

                this.refresh_price = step.data("refresh-basket");

                loader.addClass("show");
                if (skipStepLogic) {
                    return;
                }
                $.when(
                    $.post(target_url, form.serialize())
                ).then(
                    t.util.bind(this.updateSteps, this)
                );
            },
            updateSteps: function (data) {
                var new_html = $($.parseHTML(data, document, true));
                this.$el.find(".step .loader-shroud").removeClass("show");

                this.$el.find(".step").each(t.util.bind(function (i, old_step) {
                    old_step = $(old_step);

                    var step_id = old_step.data("step");
                    var new_step = new_html.find('.step[data-step=' + step_id + ']');
                    var old_step_status = old_step.data("status");
                    var new_step_status = new_step.data("status");

                    var active_url = '';
                    if (new_step_status === 'active') {
                        active_url = new_step.data('target');
                    }

                    t.controllers.purge(old_step.find(".step-content"));

                    old_step.attr("data-status", new_step_status);

                    if (old_step_status === 'active' && new_step_status !== 'active') {
                        // Possible transition for collapsing sections
                    }

                    old_step.find(".step-content").empty().append(
                        new_step.find(".step-content").html()
                    );

                    t.controllers.init(old_step.find(".step-content"));

                    if (active_url) {
                        $("html, body").animate({
                            "scrollTop": old_step.offset().top - $('.top-nav').outerHeight() - 120
                        }, 300)
                    }

                    if (this.refresh_price === true) {
                        t.message.publish('price-change', 'changed');
                        this.refresh_price = false;
                    }

                    if (active_url) {
                        window.history.pushState(step_id, step_id, active_url);

                        window.dataLayer.push({
                            event: 'ga_pageview',
                            page: active_url
                        })

                        if (window.consenTag) {
                            window.consenTag.trigger({
                              event: "nextstep"
                            });
                        }
                    }
                }, this));
                if ($('.step-links').length > 0) {
                    this.updateNavBar(data);
                }
            },
            updateNavBar: function(data) {
                var new_html = $($.parseHTML(data, document, true));

                this.$el.find(".step-link").each(t.util.bind(function (i, old_step) {
                    old_step = $(old_step);

                    var step_id = old_step.data("step");
                    var new_step = new_html.find('.step-link[data-step=' + step_id + ']');
                    var new_step_status = new_step.data("status");

                    old_step.attr("data-status", new_step_status);
                }, this));
            },
            onPostcodeSearchFormSubmit: function () {
                sessionStorage.setItem('postcodeSearchForm', 'submitted');
            },
        }
    );
    t.controllers.define(
        'delivery-options',
        {
            'onload': 'onLoad'
        },
        {
            'init:after': function () {
                this.onLoad()
            },
            onLoad: function () {
                let postcodeSearchForm = sessionStorage.getItem('postcodeSearchForm');
                if (postcodeSearchForm === 'submitted') {
                    $("#ship-to-shop").attr('checked', true);
                }
                sessionStorage.removeItem('postcodeSearchForm');
            }
        }
    );
})($, window.tails);
