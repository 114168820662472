import $ from "jquery"

(function ($, t) {

    t.controllers.define(
        'build-recipe-exclusions',
        {
            'change .allergens input:checkbox': 'updateMutualExclusions',
            'click #exclusions-no': 'deselectExclusions',
            'click #exclusion-reset': 'onAllergenResetClick'
        },
        {
            'init:before': function () {
                t.message.subscribe('hypoallergenic', 'changed', e => {
                    const hasSelected = e.data.hasSelected
                    const category = e.data.category
                    this.hypoallergenicChange(hasSelected, category);
                }, this);
            },
            'init:after': function () {
                this.data = JSON.parse(this.$el.find('.allergen-data').html());
                this._allergenMap = this.data.allergen_map;
                this._categories = this.data.categories;
                this.updateMutualExclusions();
            },
            onAllergenResetClick() {
                document.querySelectorAll('input[name=allergen_id]')
                    .forEach(e => {
                        e.disabled = false;
                        e.checked = false;
                    })
                this.updateMutualExclusions();
            },
            deselectExclusions() {
                const hypoNo = document.getElementById('no');
                hypoNo.checked = true;

                let availableExclusions = Object.keys(this._allergenMap).map(Number)
                availableExclusions.forEach(id => {
                    const allergen = document.getElementById('allergen_id_' + id);
                    allergen.checked = false;
                    allergen.removeAttribute('disabled');
                });

                t.message.publish('exclusions', 'changed', {
                    selectedAllergenIds: [],
                    incompatibleAllergenIds: []
                });
            },
            hypoallergenicChange(hasSelected, category) {
                category = this._categories[category]
                this.$el.find('#exclusions-yes').prop('checked');
                $.each(category.allergen_ids, t.util.bind(function (index, allergenId) {
                    this.$el.find('#allergen_id_' + allergenId).prop('checked', hasSelected);
                }, this));

                this.updateMutualExclusions();
            },
            updateMutualExclusions() {
                const that = this;
                const allergenCheckMap = [];
                const selectedAllergenIds = [];

                // All IDs we expect to get a status for
                let remainingIDs = []
                if (that && that._allergenMap) {
                    remainingIDs = Object.keys(that._allergenMap)
                }

                this.findWithinDOM('.allergens input:checkbox').each(function () {
                    const $this = $(this);
                    allergenCheckMap.push(
                        [$this.val(), $this.prop('checked')]
                    );
                    if ($this.prop('checked')) {
                        selectedAllergenIds.push(parseInt($this.val(), 10))
                    }
                    remainingIDs.splice(remainingIDs.indexOf($this.val()), 1)
                }).get();

                remainingIDs.forEach(function name(id) {
                    // Add an entry indicating unchecked state for any expected allergen IDs not found in DOM
                    allergenCheckMap.push([id, 0]);
                })

                let lookupInt = 0;
                allergenCheckMap.sort(function (a, b) {
                    return a[0] - b[0]
                });

                for (let i = 0; i < allergenCheckMap.length; i++) {
                    lookupInt = lookupInt | ((allergenCheckMap[i][1] ? 1 : 0) << i);
                }

                const incompatibleAllergenIds = [];
                const incompatibleAllergenNames = new Set();
                this.findWithinDOM('.allergens input:checkbox').each(function () {
                    const explanation = document.getElementById('allergen-explanation');
                    explanation.style.display = 'none';

                    const $this = $(this);
                    if (that._allergenMap[$this.val()].indexOf('|' + lookupInt + '|') >= 0) {
                        $this.attr('disabled', 'disabled');
                        incompatibleAllergenIds.push(parseInt($this.val(), 10));
                        incompatibleAllergenNames.add($this.data('allergenName'));
                    } else {
                        $this.removeAttr('disabled');
                    }
                });

                //Check if there are conflicting allergens selected, show a message, disable submit & display error if conflicting
                if (incompatibleAllergenIds.length > 0) {
                    const explanation = document.getElementById('allergen-explanation');
                    const ingredients = document.getElementById('allergen-incompatible-ingredients');
                    explanation.style.display = 'block';
                    ingredients.style.display = 'inline';
                    ingredients.innerText = Array.from(incompatibleAllergenNames).join(" , ").replace(/, ((?:.(?!, ))+)$/, ' and $1');

                    let conflictingAllergenError = false;
                    selectedAllergenIds.forEach(function (selectedAllergenId) {
                        if (incompatibleAllergenIds.indexOf(selectedAllergenId) >= 0) {
                            conflictingAllergenError = true;
                            $('button[type=submit]').attr('disabled', '');
                        }
                    });
                    if (conflictingAllergenError === true) {
                        $('#exclusion-error').removeClass('hidden');
                    } else {
                        $('#exclusion-error').addClass('hidden');
                        $('button[type=submit]').removeAttr('disabled', '');
                    }
                } else {
                    $('#exclusion-error').addClass('hidden');
                    $('button[type=submit]').removeAttr('disabled', '');
                }

                //If all the hypoallergenic preferences are selected, let the world know
                const el = document.querySelector('#allergens');
                const hypoallergenic_category = this._categories[el.dataset.hypoCategory]
                const hypoallergenic_allergens = hypoallergenic_category.allergen_ids

                let hypoallergenicSelected = hypoallergenic_allergens.every(id => selectedAllergenIds.includes(id));
                t.message.publish('hypoallergenic-selected', 'changed', {
                    hypoallergenicSelected: hypoallergenicSelected
                });

                //Let the world know which exclusions have been selected and which are incompatible (can't be selected)
                t.message.publish('exclusions', 'changed', {
                    selectedAllergenIds: selectedAllergenIds,
                    incompatibleAllergenIds: incompatibleAllergenIds
                });
            },
        }
    );

    t.controllers.define(
        'build-recipe-preferences',
        {
            'change .preferences input:checkbox': 'updateMutualPreferences',
            'click #preferences-no': 'deselectPreferences'
        },
        {
            'init:before': function () {
                t.message.subscribe('exclusions', 'changed', e => {
                    const selectedAllergenIds = e.data.selectedAllergenIds;
                    const incompatibleAllergenIds = e.data.incompatibleAllergenIds;
                    this.updatePreferences(selectedAllergenIds, incompatibleAllergenIds);
                }, this);
            },
            'init:after': function () {
                this.data = JSON.parse(this.$el.find('.preference-data').html());
                this._allergenMap = this.data.allergen_map;

                this.updateMutualPreferences();
            },
            deselectPreferences() {
                const hypoNo = document.getElementById('no');
                hypoNo.checked = false;

                let availablePreferences = Object.keys(this._allergenMap).map(Number);
                availablePreferences.forEach(id => {
                    const preference = document.getElementById('preference_id_' + id);
                    preference.checked = false;
                    preference.removeAttribute('disabled');
                });
            },
            updatePreferences(selectedAllergenIds, incompatibleAllergenIds) {
                let availablePrefs = Object.keys(this._allergenMap).map(Number)
                let preferencesToHide = [].concat(...selectedAllergenIds, ...incompatibleAllergenIds);
                let preferencesToShow = availablePrefs.filter(val => !preferencesToHide.includes(val));

                preferencesToHide.forEach(id => {
                    const preference = document.getElementById('preference_id_' + id);
                    const preference_box = document.getElementById('preference_id_' + id + '_box');
                    preference.checked = false;
                    preference_box.style.display = 'none';
                });
                preferencesToShow.forEach(id => {
                    const preference_box = document.getElementById('preference_id_' + id + '_box');
                    preference_box.style.display = 'block';
                });

                //Show explanation for when no preferences are available and disable radio button
                const noPreferences = document.getElementById('no-preferences');
                noPreferences.style.display = 'none';

                const preferenceYesLabel = document.getElementById('preferences-yes-label');
                preferenceYesLabel.classList.remove('disabled');

                if (preferencesToShow.length == 0) {
                    noPreferences.style.display = 'block';
                    preferenceYesLabel.classList.add('disabled');
                }
            },
            updateMutualPreferences() {
                const that = this;
                const preferenceCheckMap = [];
                const selectedPreferenceIds = [];
                this.findWithinDOM('.preferences input:checkbox').each(function () {
                    const $this = $(this);
                    preferenceCheckMap.push(
                        [$this.val(), $this.prop('checked')]
                    );
                    if ($this.prop('checked')) {
                        selectedPreferenceIds.push(parseInt($this.val(), 10));
                    }
                }).get();

                let lookupInt = 0;
                preferenceCheckMap.sort(function (a, b) {
                    return a[0] - b[0]
                });
                for (let i = 0; i < preferenceCheckMap.length; i++) {
                    lookupInt = lookupInt | ((preferenceCheckMap[i][1] ? 1 : 0) << i);
                }
                const incompatiblePreferenceIds = [];
                const incompatiblePreferenceNames = new Set();
                this.findWithinDOM('.preferences input:checkbox').each(function () {
                    const explanation = document.getElementById('preference-explanation');
                    explanation.style.display = 'none';

                    const $this = $(this);
                    if (that._allergenMap[$this.val()].indexOf('|' + lookupInt + '|') >= 0) {
                        $this.attr('disabled', 'disabled');
                        incompatiblePreferenceIds.push(parseInt($this.val(), 10));
                        incompatiblePreferenceNames.add($this.data('preferenceName'));
                    } else {
                        $this.removeAttr('disabled');
                    }
                });

                // Check if there are conflicting allergens selected, disable submit & display error if conflicting
                if (incompatiblePreferenceIds.length > 0) {
                    const explanation = document.getElementById('preference-explanation');
                    const ingredients = document.getElementById('preference-incompatible-ingredients');
                    explanation.style.display = 'block';
                    ingredients.style.display = 'inline';
                    ingredients.innerText = Array.from(incompatiblePreferenceNames).join(" , ").replace(/, ((?:.(?!, ))+)$/, ' and $1');

                    let conflictingPreferenceError = false;
                    selectedPreferenceIds.forEach(function (selectedPreferenceId) {
                        if (incompatiblePreferenceIds.indexOf(selectedPreferenceId) >= 0) {
                            conflictingPreferenceError = true;
                            $('button[type=submit]').attr('disabled', '');
                        }
                    });
                    if (conflictingPreferenceError === true) {
                        $('#preference-error').removeClass('hidden');
                    } else {
                        $('#preference-error').addClass('hidden');
                        $('button[type=submit]').removeAttr('disabled', '');
                    }
                } else {
                    $('#preference-error').addClass('hidden');
                    $('button[type=submit]').removeAttr('disabled', '');
                }
                //Let the world know which preferences have been selected
                t.message.publish('preferences', 'changed', {
                    selectedPreferenceIds: selectedPreferenceIds
                });
            }
        }
    );

    t.controllers.define(
        'build-recipe-hypoallergenic',
        {
            'change input:radio': 'onHypoallergenicChange',
        },
        {
            'init:before': function () {
                t.message.subscribe('hypoallergenic-selected', 'changed', e => {
                    const hypoallergenicSelected = e.data.hypoallergenicSelected;
                    this.onHypoallergenicSelected(hypoallergenicSelected);
                }, this);
            },
            'init:after': function () {
            },
            onHypoallergenicChange(e) {
                const radio = e.target
                t.message.publish('hypoallergenic', 'changed', {
                    hasSelected: radio.id == 'yes' ? true : false,
                    category: radio.value
                });
            },
            onHypoallergenicSelected(hypoallergenicSelected) {
                const yes = document.getElementById('yes');
                yes.checked = hypoallergenicSelected;

                const no = document.getElementById('no');
                no.checked = hypoallergenicSelected == true ? false : true;
            },
        },
    );

    t.controllers.define(
        'build-recipe-flavours',
        {
            'change .flavour input:checkbox': 'flavourChanged',
            'change #all_flavours': 'allFlavoursChanged',
            'change input:checkbox': 'onCheckboxChange'
        },
        {
            'init:before': function () {
                t.message.subscribe('exclusions', 'changed', e => {
                    const selectedAllergenIds = e.data.selectedAllergenIds
                    this.updateFlavours(selectedAllergenIds);
                }, this);
                t.message.subscribe('preferences', 'changed', e => {
                    const selectedPreferenceIds = e.data.selectedPreferenceIds
                    this.updateFlavours(selectedPreferenceIds);
                }, this);
            },
            'init:after': function () {
                this.all = this.$el.find("#all_flavours");
                this.flavours = this.$el.find(".flavour input:checkbox");
            },
            flavourChanged(e) {
                let all_selected = true;

                this.flavours.each(function (i, item) {
                    if (!$(item).prop("checked")) {
                        all_selected = false;
                    }
                });

                if (this.all.is(":checked")) {
                    this.all.prop("checked", false);
                    this.flavours.prop("checked", false);
                    $(e.target).prop("checked", true);
                } else {
                    this.all.prop("checked", all_selected);
                }
            },
            allFlavoursChanged(e) {
                this.flavours.prop("checked", $(e.target).prop("checked"));
            },
            onCheckboxChange(e) {
                const target = $(e.currentTarget);
                if (target.val() === "0") {
                    this.$el.find("input:checkbox").prop("checked", false);
                    target.prop("checked", true);
                } else {
                    this.$el.find('input:checkbox[value=0]').prop("checked", false);
                }
            },
            updateFlavours(selectedAllergenIds) {
                //Work out which flavours to show & hide based on exclusions selected
                let flavoursToShow = []
                const availableFlavours = document.querySelectorAll('.flavour');
                availableFlavours.forEach(flavour => {
                    const id = flavour.id.substr(flavour.id.length - 1);
                    if (id != "_") {
                        flavoursToShow.push(Number(id));
                    }
                });

                let flavoursToHide = flavoursToShow.filter(val => selectedAllergenIds.includes(val));

                flavoursToShow.forEach(id => {
                    const flavour = document.getElementById('flavour_allergen_id_' + id);
                    flavour.style.display = 'block';
                });

                flavoursToHide.forEach(id => {
                    const flavour = document.getElementById('flavour_allergen_id_' + id);
                    flavour.style.display = 'none';
                });
            },
        }
    );

})($, window.tails);
